import { cn } from "$/lib/utils/functions/misc.functions";
import { valueOrNothing } from "$/lib/utils/functions/util.functions";

import ChevronIcon from "../../icons/ChevronIcon";
import ChevronLineIcon from "../../icons/ChevronLineIcon";
import RecordsOptionsDropdown from "./RecordsOptionsDropdown";

type Props = {
  totalNumberOfPages: number;
  currentPage: number;
  pageLimit: number;
  isPendingData: boolean;
  onNextClick: () => void;
  onPreviousClick: () => void;
  onSetPageNumber: (pageNb: number) => void;
  onSetPageLimit: (limit: number) => void;
};

function generatePageNumbers(currentPage: number, totalNumberOfPages: number) {
  if (totalNumberOfPages <= 3) {
    return Array.from({ length: totalNumberOfPages }, (_, index) => index + 1);
  }
  if (currentPage === totalNumberOfPages) {
    return [totalNumberOfPages - 2, totalNumberOfPages - 1, totalNumberOfPages];
  }

  if (currentPage === 1) {
    return [currentPage, currentPage + 1, currentPage + 2];
  }

  return [currentPage - 1, currentPage, currentPage + 1];
}

const basePaginationButtonClassName =
  "grid aspect-square w-7 h-7 cursor-pointer place-items-center rounded-md border border-black-light text-sm font-bold";

export default function Pagination({
  totalNumberOfPages,
  currentPage,
  onNextClick,
  onPreviousClick,
  onSetPageNumber,
  onSetPageLimit,
  pageLimit,
  isPendingData,
}: Props) {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalNumberOfPages;

  return (
    <div className="flex h-10 items-center justify-center gap-12 px-4 md:justify-end">
      <div className="flex items-center justify-end gap-1">
        {/* prev arrow */}
        <button
          type="button"
          className={cn(
            basePaginationButtonClassName,
            "disabled:bg-disable disabled:cursor-not-allowed disabled:opacity-30",
          )}
          disabled={currentPage <= 2 || isPendingData}
          onClick={valueOrNothing(!isFirstPage, () => onSetPageNumber(1))}
        >
          <ChevronLineIcon width={18} height={18} className="rotate-180" />
        </button>
        <button
          type="button"
          className={cn(
            basePaginationButtonClassName,
            "disabled:bg-disable disabled:cursor-not-allowed disabled:opacity-30",
          )}
          disabled={isFirstPage || isPendingData}
          onClick={valueOrNothing(!isFirstPage, onPreviousClick)}
        >
          <ChevronIcon width={18} height={18} className="rotate-180" />
        </button>

        {/* number */}
        {generatePageNumbers(currentPage, totalNumberOfPages).map(
          (pageNumber) => {
            const isCurrentPage = currentPage === pageNumber;

            return (
              <button
                key={pageNumber}
                type="button"
                disabled={isPendingData}
                onClick={valueOrNothing(!isCurrentPage, () =>
                  onSetPageNumber(pageNumber),
                )}
                className={cn(
                  basePaginationButtonClassName,
                  isCurrentPage && "cursor-default bg-black text-snow",
                  "disabled:bg-disable disabled:cursor-not-allowed disabled:opacity-30",
                )}
              >
                {pageNumber}
              </button>
            );
          },
        )}

        {/* next arrow */}
        <button
          type="button"
          className={cn(
            basePaginationButtonClassName,
            "disabled:bg-disable disabled:cursor-not-allowed disabled:opacity-30",
          )}
          disabled={currentPage === totalNumberOfPages || isPendingData}
          onClick={isLastPage ? undefined : onNextClick}
        >
          <ChevronIcon width={18} height={18} />
        </button>
        <button
          type="button"
          disabled={isLastPage || isPendingData}
          className={cn(
            basePaginationButtonClassName,
            "disabled:bg-disable disabled:cursor-not-allowed disabled:opacity-30",
          )}
          onClick={() => onSetPageNumber(totalNumberOfPages)}
        >
          <ChevronLineIcon width={18} height={18} />
        </button>
      </div>

      <RecordsOptionsDropdown
        isDisabled={isPendingData}
        onSetPageLimit={onSetPageLimit}
        pageLimit={pageLimit}
      />
    </div>
  );
}
