import { type ComponentProps, useId } from "react";

export default function PoolsIcon(svgProps: ComponentProps<"svg">) {
  const pathId = useId();
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      aria-label="Pools"
      {...svgProps}
    >
      <g clipPath={`url(${pathId})`}>
        <path d="M10.8884 14.4297H7.11182C7.13411 14.6024 7.14564 14.7784 7.14564 14.957C7.14564 15.1357 7.13407 15.3117 7.11182 15.4844H10.8884C10.8662 15.3117 10.8546 15.1357 10.8546 14.957C10.8546 14.7784 10.8662 14.6024 10.8884 14.4297Z" />
        <path d="M14.4272 7.10938V10.886C14.5999 10.8637 14.7759 10.8522 14.9546 10.8522C15.1333 10.8522 15.3092 10.8637 15.4819 10.886V7.10938C15.3092 7.13163 15.1333 7.1432 14.9546 7.1432C14.7759 7.1432 14.5999 7.13163 14.4272 7.10938Z" />
        <path d="M7.14564 3.04297C7.14564 3.22163 7.13407 3.39762 7.11182 3.57031H10.8884C10.8662 3.39762 10.8546 3.22163 10.8546 3.04297C10.8546 2.8643 10.8662 2.68831 10.8884 2.51562H7.11182C7.13407 2.68831 7.14564 2.8643 7.14564 3.04297Z" />
        <path d="M3.57275 10.886V7.10938C3.40007 7.13163 3.22407 7.1432 3.04541 7.1432C2.86675 7.1432 2.69075 7.13163 2.51807 7.10938V10.886C2.69075 10.8637 2.86675 10.8522 3.04541 10.8522C3.22407 10.8522 3.40007 10.8637 3.57275 10.886Z" />
        <path d="M3.04541 6.09082C4.72468 6.09082 6.09082 4.72465 6.09082 3.04541C6.09082 1.36617 4.72468 0 3.04541 0C1.36614 0 0 1.36617 0 3.04541C0 4.72465 1.36614 6.09082 3.04541 6.09082ZM1.83041 3.51461C2.03636 3.3087 2.37027 3.3087 2.57618 3.51461C2.70151 3.63994 2.86815 3.70895 3.04541 3.70895C3.22267 3.70895 3.38927 3.63994 3.51464 3.51461C3.72055 3.30866 4.05446 3.30866 4.26041 3.51461C4.46636 3.72055 4.46636 4.05443 4.26041 4.26038C3.93592 4.5849 3.50441 4.76364 3.04541 4.76364C2.58641 4.76364 2.15494 4.5849 1.83041 4.26038C1.62446 4.05446 1.62446 3.72055 1.83041 3.51461Z" />
        <path d="M14.9546 6.09082C16.6339 6.09082 18 4.72465 18 3.04541C18 1.36617 16.6339 0 14.9546 0C13.2753 0 11.9092 1.36617 11.9092 3.04541C11.9092 4.72465 13.2753 6.09082 14.9546 6.09082ZM13.7396 3.51461C13.9455 3.3087 14.2794 3.3087 14.4854 3.51461C14.6107 3.63994 14.7773 3.70895 14.9546 3.70895C15.1318 3.70895 15.2985 3.63994 15.4238 3.51461C15.6297 3.30866 15.9636 3.30866 16.1696 3.51461C16.3755 3.72055 16.3755 4.05443 16.1696 4.26038C15.8451 4.5849 15.4136 4.76364 14.9546 4.76364C14.4956 4.76364 14.0641 4.5849 13.7396 4.26038C13.5336 4.05446 13.5336 3.72055 13.7396 3.51461Z" />
        <path d="M3.04541 11.9062C1.36614 11.9062 0 13.2724 0 14.9517C0 16.6309 1.36614 17.9971 3.04541 17.9971C4.72468 17.9971 6.09082 16.6309 6.09082 14.9517C6.09082 13.2724 4.72468 11.9062 3.04541 11.9062ZM4.26041 16.1667C3.93592 16.4912 3.50441 16.6699 3.04541 16.6699C2.58641 16.6699 2.15494 16.4912 1.83041 16.1667C1.62446 15.9607 1.62446 15.6268 1.83041 15.4209C2.03636 15.215 2.37027 15.215 2.57618 15.4209C2.70151 15.5462 2.86815 15.6152 3.04541 15.6152C3.22267 15.6152 3.38927 15.5462 3.51464 15.4209C3.72055 15.2149 4.05446 15.2149 4.26041 15.4209C4.46636 15.6268 4.46636 15.9607 4.26041 16.1667Z" />
        <path d="M14.9546 11.9062C13.2753 11.9062 11.9092 13.2724 11.9092 14.9517C11.9092 16.6309 13.2753 17.9971 14.9546 17.9971C16.6339 17.9971 18 16.6309 18 14.9517C18 13.2724 16.6339 11.9062 14.9546 11.9062ZM16.1696 16.1667C15.8451 16.4912 15.4136 16.6699 14.9546 16.6699C14.4956 16.6699 14.0641 16.4912 13.7396 16.1667C13.5336 15.9607 13.5336 15.6268 13.7396 15.4209C13.9455 15.215 14.2794 15.215 14.4854 15.4209C14.6107 15.5462 14.7773 15.6152 14.9546 15.6152C15.1318 15.6152 15.2985 15.5462 15.4238 15.4209C15.6297 15.2149 15.9636 15.2149 16.1696 15.4209C16.3755 15.6268 16.3755 15.9607 16.1696 16.1667Z" />
      </g>
      <defs>
        <clipPath id={pathId}>
          <rect width="18" height="18" />
        </clipPath>
      </defs>
    </svg>
  );
}
