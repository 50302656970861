import type { PropsWithChildren } from "react";
import { Drawer } from "vaul";

import { cn } from "$/lib/utils/functions/misc.functions";

type Props = {
  title?: string;
  description?: string;
  className?: string;
};

export default function BottomSheetContent({
  title,
  description,
  className,
  children,
}: PropsWithChildren<Props>) {
  const hasTitleOnly = !!title && !description && !children;

  return (
    <div
      className={cn(
        "relative max-h-[inherit] w-full rounded-t-md bg-snow py-4",
        className,
      )}
    >
      <Drawer.Handle className="mx-auto mb-4 h-fit rounded-full bg-grey-500" />

      <div className="max-h-[inherit] w-full overflow-auto p-4 pb-12 pt-0">
        {!!title && (
          <Drawer.Title
            className={cn("text-lg font-semibold", !hasTitleOnly && "mb-8")}
          >
            {title}
          </Drawer.Title>
        )}
        {!!description && (
          <Drawer.Description
            className={cn("text-sm leading-normal", !!children && "mb-5")}
          >
            {description}
          </Drawer.Description>
        )}
        {children}
      </div>
    </div>
  );
}
