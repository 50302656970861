import SelectInput from "../inputs/SelectInput";

type Props = {
  onSetPageLimit: (limit: number) => void;
  pageLimit: number;
  isDisabled: boolean;
};
export default function RecordsOptionsDropdown({
  onSetPageLimit,
  pageLimit,
  isDisabled,
}: Props) {
  return (
    <div className="hidden items-center gap-4 md:flex">
      <label className="shrink-0" htmlFor="records">
        Nombre de lignes
      </label>
      <SelectInput
        label=""
        hideLabel
        options={[
          { label: "5", value: "5" },
          { label: "10", value: "10" },
          { label: "25", value: "25" },
          { label: "50", value: "50" },
        ]}
        disabled={isDisabled}
        className="h-10 min-h-10 min-w-20 rounded-md bg-blue-light"
        value={pageLimit.toString()}
        onValueChange={(e) => onSetPageLimit(Number(e))}
      ></SelectInput>
    </div>
  );
}
