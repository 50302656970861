import type { ComponentProps } from "react";

export default function SidebarArrowIcon(svgProps: ComponentProps<"svg">) {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="white"
      aria-label="Sidebar Arrow"
      {...svgProps}
    >
      <path d="M11.1993 10.3906H7.3055H3.79923C3.19923 10.3906 2.89923 9.66563 3.32423 9.24062L6.56175 6.00312C7.0805 5.48437 7.92425 5.48437 8.443 6.00312L9.67425 7.23437L11.6805 9.24062C12.0993 9.66563 11.7993 10.3906 11.1993 10.3906Z" />
    </svg>
  );
}
