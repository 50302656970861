import type { ComponentProps } from "react";

export default function ChevronIcon(svgProps: ComponentProps<"svg">) {
  return (
    <svg
      viewBox="0 0 128 128"
      width="16"
      height="16"
      fill="currentColor"
      aria-label="Chevron"
      {...svgProps}
    >
      <path d="m44 108c-1.023 0-2.047-.391-2.828-1.172-1.563-1.563-1.563-4.094 0-5.656l37.172-37.172-37.172-37.172c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l40 40c1.563 1.563 1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z"></path>
    </svg>
  );
}
