import type { ComponentProps, PropsWithChildren } from "react";

import { useMediaQuery } from "$/lib/hooks/useMediaQuery";

import BottomSheetTrigger from "../bottom-sheet/BottomSheetTrigger";
import ModalTrigger from "../modal/ModalTrigger";

export default function ResponsiveDialogTrigger(
  props: PropsWithChildren<ComponentProps<"button">>,
) {
  const isMobile = useMediaQuery("(max-width: 767px)");

  if (isMobile) return <BottomSheetTrigger {...props} />;

  return <ModalTrigger {...props} />;
}
