import { type ComponentProps, useId } from "react";

export default function DriverIcon(svgProps: ComponentProps<"svg">) {
  const pathId = useId();
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      aria-label="Driver"
      {...svgProps}
    >
      <g clipPath={`url(#${pathId})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.03098 0.28125C9.10572 0.28125 9.18046 0.28125 9.2552 0.285402C13.9389 0.401665 17.6967 4.15115 17.6967 8.76014V8.85149V8.94699C17.6967 13.7304 13.8185 17.6127 9.03098 17.6127C4.24759 17.6127 0.365234 13.7345 0.365234 8.94699C0.365234 8.91378 0.365234 8.88471 0.365234 8.85149C0.365234 8.82243 0.365234 8.78921 0.365234 8.76014C0.365234 4.15115 4.12302 0.401665 8.80676 0.285402C8.8815 0.285402 8.95624 0.28125 9.03098 0.28125ZM10.476 9.36637C10.3929 9.79405 9.77838 10.1262 9.03098 10.1262C8.28357 10.1262 7.66904 9.79405 7.586 9.36637C7.66904 8.93038 8.28357 8.5899 9.03098 8.5899C9.77838 8.5899 10.3929 8.93038 10.476 9.36637ZM10.177 14.4612C10.2559 17.675 15.7036 13.2902 15.8946 11.0896C15.849 10.6453 15.5459 10.3172 14.8732 10.2134C14.2005 10.1096 13.5196 10.0556 12.8843 10.1387C12.6808 11.102 11.7341 11.8992 10.4552 12.2439C10.2434 13.0287 10.1895 13.9048 10.177 14.4612ZM2.16731 11.0896C2.35831 13.2902 7.80606 17.675 7.88496 14.4612C7.8725 13.9048 7.82267 13.0287 7.60676 12.2439C6.32786 11.8992 5.38115 11.102 5.17769 10.1387C4.5424 10.0556 3.86143 10.1096 3.18876 10.2134C2.5161 10.3172 2.20883 10.6453 2.16731 11.0896ZM2.3251 7.49371C2.3251 7.56845 2.32925 7.66395 2.33755 7.79682C2.35001 8.02935 2.62406 8.27018 3.13894 8.34907C3.71195 8.34492 4.46766 8.18713 5.38946 7.78021C8.36662 6.45564 9.68703 6.45564 12.6642 7.78021C13.586 8.19128 14.3417 8.34492 14.9147 8.34907C15.4296 8.26603 15.7036 8.02935 15.7161 7.79682C15.7244 7.66395 15.7286 7.56429 15.7286 7.49371C15.7286 7.42727 15.7244 7.39405 15.7244 7.33177C15.0061 4.4418 12.0995 2.09994 9.02267 2.02519C5.94586 2.09994 3.03928 4.4418 2.32094 7.33177C2.3251 7.3899 2.32094 7.42312 2.31679 7.49371H2.3251Z"
        />
      </g>
      <defs>
        <clipPath id={pathId}>
          <rect width="18" height="18" />
        </clipPath>
      </defs>
    </svg>
  );
}
