/* eslint-disable react-refresh/only-export-components */
import {
  type QueryKey,
  type UseQueryOptions,
  useQuery,
} from "@tanstack/react-query";
import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import useDebounce from "$/lib/hooks/useDebounce";
import useThrottle from "$/lib/hooks/useThrottle";
import type { Option, Primitive } from "$/types/util.types";

import {
  type AutocompleteContextType,
  AutocompleteSearchDelayMode,
} from "./types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AutocompleteContext = createContext<AutocompleteContextType<any>>({
  search: "",
  options: [],
  multi: false,
  selected: null,
  isAutocompleteLoading: false,
  handleSelection: () => {},
  handleSearch: () => {},
  handleClear: () => {},
});

type Props<TValue, TQueryData> = {
  multi?: boolean;
  delayMode?: AutocompleteSearchDelayMode;
  delayDuration?: number;
  defaultSelected?: TValue | TValue[] | null;
  queryOptions: Omit<
    UseQueryOptions<TQueryData, unknown, TQueryData, QueryKey>,
    "queryFn"
  > & {
    queryFn: (search: string) => Promise<TQueryData>;
  };
  optionsResolver: (data: TQueryData) => Option<TValue>[];
  onSelect?: (option: TValue, index: number, search: string) => void;
};

export default function AutocompleteProvider<
  TValue extends Primitive,
  TQueryData,
>({
  multi,
  queryOptions,
  defaultSelected,
  delayMode = AutocompleteSearchDelayMode.THROTTLED,
  delayDuration = 275,
  optionsResolver,
  onSelect,
  children,
}: PropsWithChildren<Props<TValue, TQueryData>>) {
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState<Option<TValue>[]>([]);
  const [selected, setSelected] = useState<TValue | TValue[] | null>(() => {
    if (defaultSelected) {
      if (multi) {
        return Array.isArray(defaultSelected)
          ? defaultSelected
          : [defaultSelected];
      }
      return defaultSelected;
    }
    return multi ? [] : null;
  });

  const throttledSearch = useThrottle(search, {
    duration: delayDuration,
    disabled: delayMode !== AutocompleteSearchDelayMode.THROTTLED,
  });
  const debouncedSearch = useDebounce(search, {
    duration: delayDuration,
    disabled: delayMode !== AutocompleteSearchDelayMode.DEBOUNCED,
  });
  const resolvedSearchValue =
    delayMode === AutocompleteSearchDelayMode.THROTTLED
      ? throttledSearch
      : delayMode === AutocompleteSearchDelayMode.DEBOUNCED
        ? debouncedSearch
        : search;

  const resolvedQueryKey = [...queryOptions.queryKey, resolvedSearchValue];

  const queryResult = useQuery({
    ...queryOptions,
    queryFn: async () => queryOptions.queryFn(resolvedSearchValue),
    queryKey: resolvedQueryKey,
  });

  const handleSearch = useCallback((value: string) => {
    setSearch(value);
  }, []);

  const handleSelection = useCallback(
    (value: TValue, index: number) => {
      if (multi) {
        setSelected((p) => {
          if (p === null || !Array.isArray(p)) {
            return [value];
          }

          if (p.includes(value)) {
            return p.filter((o) => o !== value);
          }

          return [...p, value];
        });
      } else {
        setSelected(value);
      }
      onSelect?.(value, index, search);
    },
    [multi, search, onSelect],
  );

  const handleClear = useCallback(() => {
    setSelected(multi ? [] : null);
  }, [multi]);

  useEffect(() => {
    if (queryResult.data) {
      setOptions(optionsResolver(queryResult.data));
    }
  }, [queryResult.data, optionsResolver]);

  const providerSelectValue = multi
    ? {
        multi: true as const,
        selected: selected as TValue[] | null,
      }
    : {
        multi: false as const,
        selected: selected as TValue | null,
      };

  return (
    <AutocompleteContext.Provider
      value={{
        search,
        options,
        isAutocompleteLoading: queryResult.isPending,
        handleSelection,
        handleSearch,
        handleClear,
        ...providerSelectValue,
      }}
    >
      {children}
    </AutocompleteContext.Provider>
  );
}

export function useAutoComplete<TValue extends Primitive>() {
  return useContext(AutocompleteContext) as AutocompleteContextType<TValue>;
}
