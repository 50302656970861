import { type ClassValue, clsx } from "clsx";
import type { DeepPartial, FieldErrors, FieldValues } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { decryptString, encryptString } from "./util.functions";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function createMagicLinkEmailRedirectUrl(email: string) {
  const provider = email.split("@")[1];

  if (provider === "gmail.com") {
    return encodeURI(
      `https://mail.google.com/mail/u/${email}/#search/from:(${import.meta.env.VITE_MAGIC_LINK_SENDER_EMAIL})+in:anywhere+newer_than:1h`,
    );
  }

  if (provider === "outlook.com" || provider === "hotmail.com") {
    return encodeURI(`https://outlook.live.com/mail/?login_hint=${email}`);
  }

  if (provider === "icloud.com") {
    return encodeURI("https://www.icloud.com/mail/");
  }

  if (provider === "yahoo.com") {
    return encodeURI("https://mail.yahoo.com/");
  }

  return null;
}

export function getDeepFormError<T extends FieldValues>(
  error: FieldErrors<T>,
  errorPath: string[],
) {
  let currentError: FieldErrors<T> | undefined = error as
    | FieldErrors<T>
    | undefined;
  for (const key of errorPath) {
    if (!currentError) {
      return undefined;
    }
    currentError = currentError[key] as FieldErrors<T>;
  }

  return currentError as FieldErrors<T>[keyof FieldErrors<T>];
}

export function getDeepFormDefaultValue<T extends FieldValues>(
  defaultValues: Readonly<DeepPartial<T>> | undefined,
  defaultValuePath: string[],
) {
  let currentDefault: Readonly<DeepPartial<T>> | undefined = defaultValues;
  for (const key of defaultValuePath) {
    if (!currentDefault) {
      return undefined;
    }
    currentDefault = currentDefault[key] as
      | Readonly<DeepPartial<T>>
      | undefined;
  }

  return currentDefault as T[keyof T];
}

export function encryptAppString(input: string) {
  const secretKey = import.meta.env.VITE_DATA_ENCRYPTION_KEY;
  return encryptString(input, secretKey);
}

export function decryptAppString(input: string) {
  const secretKey = import.meta.env.VITE_DATA_ENCRYPTION_KEY;
  return decryptString(input, secretKey);
}

export function formatPrice(amount: number) {
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(amount);
}
