import type { ComponentProps } from "react";

export default function ChevronUpIcon(svgProps: ComponentProps<"svg">) {
  return (
    <svg
      viewBox="0 0 1560 1560"
      height="16"
      width="16"
      fill="currentColor"
      role="img"
      aria-label="chevron"
      {...svgProps}
    >
      <path d="m776.8 1185.1c-8.2 0-16.4-3.1-22.6-9.4l-740.8-741.7c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l740.8 741.8c12.5 12.5 12.5 32.8 0 45.3-6.3 6.2-14.5 9.3-22.7 9.3z"></path>
      <path d="m776.8 1185.1c-8.2 0-16.4-3.1-22.6-9.4-12.5-12.5-12.5-32.8 0-45.3l747.2-746.2c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-747.2 746.2c-6.3 6.3-14.5 9.4-22.7 9.4z"></path>
    </svg>
  );
}
