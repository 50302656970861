import { type ComponentProps, useId } from "react";

export default function CertificationIcon(svgProps: ComponentProps<"svg">) {
  const pathId = useId();
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      aria-label="Certification"
      {...svgProps}
    >
      <g clipPath={`url(#${pathId})`}>
        <path d="M11.8793 12.6148L12.7024 11.2792L14.1878 10.7726L14.3524 9.21245L15.47 8.11223L14.9386 6.63546L15.47 5.15865L14.3524 4.05844L14.1878 2.49834L12.7024 1.99174L11.8793 0.656051L10.3224 0.844031L8.99987 0L7.67733 0.844102L6.12047 0.656121L5.29736 1.99178L3.81193 2.49838L3.64737 4.05847L2.52979 5.15869L3.06114 6.6355L2.52979 8.1123L3.64733 9.21248L3.8119 10.7726L5.29732 11.2792L6.12043 12.6149L7.67733 12.4269L8.99987 13.271L10.3224 12.4269L11.8793 12.6148ZM4.44963 6.6355C4.44963 4.1265 6.49088 2.08526 8.99987 2.08526C11.5089 2.08526 13.5501 4.1265 13.5501 6.6355C13.5501 9.14449 11.5089 11.1857 8.99987 11.1857C6.49088 11.1857 4.44963 9.14449 4.44963 6.6355Z" />
        <path d="M8.99977 3.14062C7.07268 3.14062 5.50488 4.70842 5.50488 6.63551C5.50488 8.5626 7.07268 10.1304 8.99977 10.1304C10.9269 10.1304 12.4946 8.5626 12.4946 6.63551C12.4946 4.70842 10.9269 3.14062 8.99977 3.14062Z" />
        <path d="M7.42858 13.5168L5.57655 13.7404L4.59805 12.1525L4.2654 12.0391L2.83057 16.5238L5.41367 16.3817L7.43463 17.9968L8.62389 14.2797L7.42858 13.5168Z" />
        <path d="M13.4018 12.1525L12.4233 13.7404L10.5713 13.5168L9.37598 14.2797L10.5652 17.9968L12.5862 16.3817L15.1693 16.5238L13.7345 12.0391L13.4018 12.1525Z" />
      </g>
      <defs>
        <clipPath id={pathId}>
          <rect width="18" height="18" />
        </clipPath>
      </defs>
    </svg>
  );
}
