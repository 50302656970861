import useApiMutation from "$/lib/hooks/useApiMutation";
import { useAuth } from "$/lib/providers/AuthProvider";
import { restApiClient } from "$/lib/utils/clients/restApi.client";

export async function logout() {
  return restApiClient.url("/auth/logout").post();
}

export function useLogout() {
  const { clearUser } = useAuth();

  return useApiMutation({
    mutationFn: logout,
    onSuccess: () => {
      clearUser();
    },
  });
}
