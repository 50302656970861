import { useEffect, useState } from "react";

type UseDebounceOptions = {
  duration?: number;
  disabled?: boolean;
  immediateInvalidator?: (newValue: unknown) => boolean;
};

export default function useDebounce<T>(value: T, options?: UseDebounceOptions) {
  const {
    duration = 275,
    disabled = false,
    immediateInvalidator,
  } = options || {};

  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (disabled) return;

    if (immediateInvalidator?.(value)) {
      setDebouncedValue(value);
      return;
    }

    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, duration);

    return () => {
      clearTimeout(timeout);
    };
  }, [value, duration, disabled, immediateInvalidator]);

  return debouncedValue;
}
