import { createFileRoute } from "@tanstack/react-router";

import DeliveryRequestTable from "$/features/delivery-request/components/DeliveryRequestTable";

export const Route = createFileRoute(
  "/_user-layout/journey-management/delivery-request/",
)({
  component: DeliveryRequestPage,
});

export default function DeliveryRequestPage() {
  return (
    <div className="flex h-full flex-col gap-5 px-6 lg:px-10">
      <DeliveryRequestTable conceptorView />
    </div>
  );
}
