import type { ComponentProps } from "react";

export default function DownloadIcon(svgProps: ComponentProps<"svg">) {
  return (
    <svg
      height="16"
      width="16"
      viewBox="0 0 24 24"
      fill="currentColor"
      aria-label="download"
      {...svgProps}
    >
      <path d="m21 19h-18a1 1 0 0 0 0 2h18a1 1 0 0 0 0-2z"></path>
      <path d="m12 2a1 1 0 0 0 -1 1v10.59l-3.29-3.3a1 1 0 0 0 -1.42 1.42l5 5a1 1 0 0 0 1.42 0l5-5a1 1 0 0 0 -1.42-1.42l-3.29 3.3v-10.59a1 1 0 0 0 -1-1z"></path>
    </svg>
  );
}
