import { type ComponentProps, useId } from "react";

export default function ListIcon(svgProps: ComponentProps<"svg">) {
  const pathId = useId();
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      aria-label="List"
      {...svgProps}
    >
      <g clipPath={`url(#${pathId})`}>
        <path d="M15.751 2.8125H4.50498C3.88388 2.8125 3.38037 3.31601 3.38037 3.9371C3.38037 4.5582 3.88388 5.06171 4.50498 5.06171H15.751C16.3721 5.06171 16.8756 4.5582 16.8756 3.9371C16.8756 3.31601 16.3721 2.8125 15.751 2.8125Z" />
        <path d="M2.25551 3.93237C2.25424 3.63459 2.13493 3.34947 1.92375 3.13952C1.48512 2.70349 0.776689 2.70349 0.338058 3.13952C0.126843 3.34947 0.00752958 3.63459 0.00629954 3.93237C-0.00209985 4.00522 -0.00209985 4.07881 0.00629954 4.15167C0.0190216 4.22494 0.0397565 4.2966 0.0681528 4.36534C0.0983414 4.43194 0.134083 4.4959 0.17499 4.55652C0.215371 4.61957 0.262499 4.67802 0.315566 4.73084C0.366735 4.7818 0.423282 4.82706 0.484257 4.86579C0.543474 4.90891 0.607682 4.94479 0.675439 4.97263C0.74998 5.01129 0.82944 5.03968 0.911606 5.05697C0.98446 5.06513 1.05805 5.06513 1.1309 5.05697C1.42787 5.05722 1.71289 4.93998 1.92375 4.73084C1.97682 4.67802 2.02395 4.61957 2.06433 4.55652C2.10523 4.4959 2.14097 4.43194 2.17116 4.36534C2.20705 4.29744 2.23534 4.22579 2.25551 4.15167C2.26391 4.07881 2.26391 4.00522 2.25551 3.93237Z" />
        <path d="M2.25556 8.99487C2.26392 8.92202 2.26392 8.84842 2.25556 8.77557C2.23612 8.703 2.2078 8.63313 2.17121 8.56752C2.14225 8.49853 2.10644 8.4326 2.06437 8.37071C2.0254 8.30837 1.9781 8.25161 1.9238 8.20202C1.48517 7.76599 0.776737 7.76599 0.338106 8.20202C0.126891 8.41197 0.00757769 8.69709 0.00634766 8.99487C0.00852658 9.14307 0.0370986 9.28966 0.090693 9.42784C0.118878 9.49437 0.152757 9.55833 0.191907 9.61902C0.234678 9.68014 0.283668 9.73669 0.338106 9.78771C0.3878 9.84191 0.444522 9.88921 0.506797 9.92829C0.566014 9.97145 0.630187 10.0073 0.69798 10.0351C0.766545 10.064 0.838274 10.0848 0.911654 10.097C0.983594 10.1131 1.05722 10.1207 1.13095 10.1195C1.20381 10.1279 1.2774 10.1279 1.35025 10.1195C1.4218 10.1072 1.49167 10.0864 1.5583 10.0576C1.62792 10.03 1.69399 9.99412 1.75511 9.95078C1.81738 9.9117 1.87411 9.8644 1.9238 9.81021C1.97799 9.76051 2.02529 9.70379 2.06437 9.64152C2.1076 9.58233 2.14348 9.51816 2.17121 9.45033C2.20962 9.37569 2.23799 9.29626 2.25556 9.21417C2.2642 9.14131 2.2642 9.06772 2.25556 8.99487Z" />
        <path d="M2.25563 14.0574C2.26396 13.9845 2.26396 13.9109 2.25563 13.8381C2.23619 13.7637 2.20787 13.692 2.17128 13.6244C2.14109 13.5578 2.10535 13.4938 2.06444 13.4332C2.02536 13.3709 1.97806 13.3142 1.92387 13.2645C1.48524 12.8285 0.776806 12.8285 0.338175 13.2645C0.283983 13.3142 0.236679 13.3709 0.197599 13.4332C0.156692 13.4938 0.120951 13.5578 0.0907619 13.6244C0.0615925 13.6929 0.0408225 13.7646 0.0289087 13.8381C0.0130588 13.9101 0.005538 13.9836 0.00641659 14.0574C0.00768177 14.3551 0.126995 14.6403 0.338175 14.8502C0.387868 14.9044 0.444591 14.9517 0.506866 14.9908C0.566083 15.0339 0.630256 15.0698 0.698049 15.0976C0.766614 15.1265 0.838343 15.1473 0.911723 15.1595C0.983663 15.1756 1.05729 15.1832 1.13102 15.182C1.20387 15.1904 1.27747 15.1904 1.35032 15.182C1.42187 15.1697 1.49174 15.1489 1.55837 15.1201C1.62799 15.0925 1.69406 15.0566 1.75518 15.0133C1.81745 14.9742 1.87417 14.9269 1.92387 14.8727C1.97806 14.823 2.02536 14.7663 2.06444 14.704C2.10771 14.6449 2.14359 14.5807 2.17128 14.5128C2.20966 14.4382 2.23802 14.3588 2.25563 14.2767C2.26427 14.2038 2.26427 14.1302 2.25563 14.0574Z" />
        <path d="M16.8756 7.875H4.50498C3.88388 7.875 3.38037 8.37851 3.38037 8.9996C3.38037 9.6207 3.88388 10.1242 4.50498 10.1242H16.8756C17.4967 10.1242 18.0002 9.6207 18.0002 8.9996C18.0002 8.37851 17.4967 7.875 16.8756 7.875Z" />
        <path d="M11.8149 12.9375H4.50498C3.88388 12.9375 3.38037 13.441 3.38037 14.0621C3.38037 14.6832 3.88388 15.1867 4.50498 15.1867H11.8149C12.436 15.1867 12.9395 14.6832 12.9395 14.0621C12.9395 13.441 12.436 12.9375 11.8149 12.9375Z" />
      </g>
      <defs>
        <clipPath id={pathId}>
          <rect width="18" height="18" />
        </clipPath>
      </defs>
    </svg>
  );
}
