import { useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";

import useApiMutation from "$/lib/hooks/useApiMutation";

import { deleteDeliveryRequest } from "../utils/delivery-request.apis";
import { deliveryRequestsQueryKeys } from "../utils/delivery-request.constants";

export function useDeleteDeliveryRequest() {
  const queryClient = useQueryClient();

  return useApiMutation({
    mutationFn: deleteDeliveryRequest,

    onSuccess: () => {
      toast.success("Demande de livraison supprimée avec succès");
      queryClient.invalidateQueries({
        queryKey: deliveryRequestsQueryKeys.getDeliveryRequests,
      });
    },
  });
}
