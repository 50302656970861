import type { ComponentProps } from "react";

export default function BricksLoader(svgProps: ComponentProps<"svg">) {
  return (
    <svg
      role="img"
      width="16"
      height="16"
      viewBox="0 0 100 100"
      fill="currentColor"
      aria-label="Bricks Loader"
      {...svgProps}
    >
      <rect x="15" y="15" width="30" height="30" rx="3" ry="3">
        <animate
          attributeName="x"
          dur="2s"
          repeatCount="indefinite"
          keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1"
          values="15;55;55;55;55;15;15;15;15"
          begin="-1.8333333333333333s"
        />
        <animate
          attributeName="y"
          dur="2s"
          repeatCount="indefinite"
          keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1"
          values="15;55;55;55;55;15;15;15;15"
          begin="-1.3333333333333333s"
        />
      </rect>
      <rect x="15" y="15" width="30" height="30" rx="3" ry="3">
        <animate
          attributeName="x"
          dur="2s"
          repeatCount="indefinite"
          keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1"
          values="15;55;55;55;55;15;15;15;15"
          begin="-1.1666666666666667s"
        />
        <animate
          attributeName="y"
          dur="2s"
          repeatCount="indefinite"
          keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1"
          values="15;55;55;55;55;15;15;15;15"
          begin="-0.6666666666666666s"
        />
      </rect>
      <rect x="15" y="15" width="30" height="30" rx="3" ry="3">
        <animate
          attributeName="x"
          dur="2s"
          repeatCount="indefinite"
          keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1"
          values="15;55;55;55;55;15;15;15;15"
          begin="-0.5s"
        />
        <animate
          attributeName="y"
          dur="2s"
          repeatCount="indefinite"
          keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1"
          values="15;55;55;55;55;15;15;15;15"
          begin="0s"
        />
      </rect>
    </svg>
  );
}
