import type { ComponentProps } from "react";

export default function WarningExclamationIcon(
  svgProps: ComponentProps<"svg">,
) {
  return (
    <svg
      viewBox="0 0 16 16"
      height="16"
      width="16"
      fill="transparent"
      stroke="currentColor"
      role="img"
      aria-label="info"
      {...svgProps}
    >
      <path
        d="M8 14.75C11.7279 14.75 14.75 11.7279 14.75 8C14.75 4.27208 11.7279 1.25 8 1.25C4.27208 1.25 1.25 4.27208 1.25 8C1.25 11.7279 4.27208 14.75 8 14.75Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 5V8" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 11H8.0075" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
