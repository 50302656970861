import type { ComponentProps } from "react";
import type { FieldValues, Path } from "react-hook-form";
import { z } from "zod";

import type FormColorInput from "$/lib/components/common/form/FormColorInput";
import type FormComboboxInput from "$/lib/components/common/form/FormComboboxInput";
import type FormDateInput from "$/lib/components/common/form/FormDateInput";
import type FormDatetimeInput from "$/lib/components/common/form/FormDateTimeInput";
import type FormNumberInput from "$/lib/components/common/form/FormNumberInput";
import type FormSelectInput from "$/lib/components/common/form/FormSelectInput";
import type FormTextAreaInput from "$/lib/components/common/form/FormTextAreaInput";
import type FormTextInput from "$/lib/components/common/form/FormTextInput";
import type FormTimeInput from "$/lib/components/common/form/FormTimeInput";
import type {
  Equals,
  FlattenTuple,
  Option,
  Primitive,
  UnionToIntersection,
  UnionToTuple,
  WithReadonly,
} from "$/types/util.types";

import type ManagedFormCombobox from "../autocomplete/ManagedFormCombobox";

export const DynamicFormInputType = {
  TEXT: "text",
  NUMBER: "number",
  SELECT: "select",
  DATETIME: "datetime",
  DATE: "date",
  TIME: "time",
  LONG_TEXT: "long_text",
  COLOR: "color",
  COMBOBOX: "combobox",
} as const;
export type DynamicFormInputType =
  (typeof DynamicFormInputType)[keyof typeof DynamicFormInputType];

export type DynamicFormInputTypeBasedZodParserReturn = {
  [DynamicFormInputType.TEXT]: z.ZodString;
  [DynamicFormInputType.NUMBER]: z.ZodNumber;
  [DynamicFormInputType.SELECT]: z.ZodNativeEnum<z.EnumLike>;
  [DynamicFormInputType.DATE]: z.ZodDate;
  [DynamicFormInputType.DATETIME]: z.ZodDate;
  [DynamicFormInputType.TIME]: z.ZodString;
  [DynamicFormInputType.LONG_TEXT]: z.ZodString;
  [DynamicFormInputType.COLOR]: z.ZodString;
  [DynamicFormInputType.COMBOBOX]:
    | DynamicFormComboboxInputOptionsTypeToZodType[keyof DynamicFormComboboxInputOptionsTypeToZodType]
    | z.ZodArray<
        DynamicFormComboboxInputOptionsTypeToZodType[keyof DynamicFormComboboxInputOptionsTypeToZodType]
      >;
};

export type DynamicFormInputTypeBasedComponent = {
  [DynamicFormInputType.TEXT]: typeof FormTextInput;
  [DynamicFormInputType.NUMBER]: typeof FormNumberInput;
  [DynamicFormInputType.SELECT]: typeof FormSelectInput;
  [DynamicFormInputType.DATE]: typeof FormDateInput;
  [DynamicFormInputType.DATETIME]: typeof FormDatetimeInput;
  [DynamicFormInputType.TIME]: typeof FormTimeInput;
  [DynamicFormInputType.LONG_TEXT]: typeof FormTextAreaInput;
  [DynamicFormInputType.COLOR]: typeof FormColorInput;
  [DynamicFormInputType.COMBOBOX]: typeof FormComboboxInput;
};

export type DynamicFormSelectInputZodParserReturn<
  TSelectOptions extends string = string,
> = z.ZodEnum<[TSelectOptions, ...TSelectOptions[]]>;

export type DynamicFormSelectInputAttributes<
  TSelectOptions extends string = string,
> = {
  type: "select";
  options: Option<TSelectOptions>[];
  componentProps?: Omit<
    ComponentProps<typeof FormSelectInput>,
    keyof BaseDynamicFormInput
  >;
  extraParsing?: (
    value: z.ZodEnum<[TSelectOptions, ...TSelectOptions[]]>,
  ) => z.ZodTypeAny;
  parseValue?: (value: TSelectOptions) => unknown;
};

export const DynamicFormComboboxInputOptionsType = {
  STRING: "string",
  NUMBER: "number",
} as const;
export type DynamicFormComboboxInputOptionsType =
  (typeof DynamicFormComboboxInputOptionsType)[keyof typeof DynamicFormComboboxInputOptionsType];

type DynamicFormComboboxInputOptionsTypeToZodType = {
  [DynamicFormComboboxInputOptionsType.STRING]: z.ZodString;
  [DynamicFormComboboxInputOptionsType.NUMBER]: z.ZodNumber;
};

export type DynamicFormComboboxInputZodParserReturn<
  TComboboxOptionsType extends
    DynamicFormComboboxInputOptionsType = DynamicFormComboboxInputOptionsType,
  TMulti extends boolean = false,
> = TMulti extends true
  ? z.ZodArray<
      DynamicFormComboboxInputOptionsTypeToZodType[TComboboxOptionsType]
    >
  : DynamicFormComboboxInputOptionsTypeToZodType[TComboboxOptionsType];

type DynamicFormComboboxInputMultiAttributes<
  TComboboxOptionsType extends DynamicFormComboboxInputOptionsType,
> =
  | {
      multi: true;
      extraParsing?: (
        value: z.ZodArray<
          DynamicFormComboboxInputOptionsTypeToZodType[TComboboxOptionsType]
        >,
      ) => z.ZodTypeAny;
    }
  | {
      multi?: false;
      extraParsing?: (
        value: DynamicFormComboboxInputOptionsTypeToZodType[TComboboxOptionsType],
      ) => z.ZodTypeAny;
    };

export type DynamicFormComboboxInputAttributes<
  TComboboxOptions extends
    DynamicFormComboboxInputOptionsType = DynamicFormComboboxInputOptionsType,
> = {
  type: "combobox";
  optionsType: TComboboxOptions;
  componentProps: Omit<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ComponentProps<typeof ManagedFormCombobox<Primitive, any>>,
    keyof BaseDynamicFormInput
  >;
} & DynamicFormComboboxInputMultiAttributes<TComboboxOptions>;

export type DynamicFormInputTypeBasedAttributes<
  T extends DynamicFormInputType,
> = T extends "select"
  ? DynamicFormSelectInputAttributes<string>
  : T extends "combobox"
    ? DynamicFormComboboxInputAttributes<DynamicFormComboboxInputOptionsType>
    : {
        type: T;
        componentProps?: Omit<
          ComponentProps<DynamicFormInputTypeBasedComponent[T]>,
          keyof BaseDynamicFormInput
        >;
        extraParsing?: (
          value: DynamicFormInputTypeBasedZodParserReturn[T],
        ) => z.ZodTypeAny;
      };

type RequiredDynamicFormInput = {
  optional?: false;
  requiredMessage?: never;
};

type OptionalDynamicFormInput = {
  optional?: true;
  requiredMessage?: string;
};

export type BaseDynamicFormInput<
  T extends FieldValues = FieldValues,
  P extends Path<T> = Path<T>,
> = {
  name: P;
  label: string;
  placeholder?: string;
  disabled?: boolean;
};

export type DynamicFormInput<
  T extends FieldValues = FieldValues,
  P extends Path<T> = Path<T>,
> = BaseDynamicFormInput<T, P> &
  (RequiredDynamicFormInput | OptionalDynamicFormInput) &
  {
    [K in DynamicFormInputType]: DynamicFormInputTypeBasedAttributes<K>;
  }[DynamicFormInputType];

export type DynamicFormInputsGroup<T extends FieldValues = FieldValues> =
  WithReadonly<{
    name: string;
    inputs: DynamicFormInput<T>[];
    customRenderer?: (
      group: Omit<DynamicFormInputsGroup<T>, "customRenderer">,
    ) => React.ReactNode;
  }>;

type InferObjectZodSchema<T extends object> = {
  [Key in keyof T]-?: Equals<T[Key], Exclude<T[Key], undefined>> extends false
    ?
        | z.ZodOptional<z.ZodType<NonNullable<T[Key]>>>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        | z.ZodPipeline<z.ZodOptional<z.ZodType<any>>, z.ZodType<T[Key]>>
    : // eslint-disable-next-line @typescript-eslint/no-explicit-any
      z.ZodType<T[Key]> | z.ZodPipeline<z.ZodType<any>, z.ZodType<T[Key]>>;
};

type BaseDynamicForm<T extends FieldValues = FieldValues> = {
  title: string;
  isSubmitting: boolean;
  customSchema?:
    | z.ZodObject<InferObjectZodSchema<T>>
    | z.ZodEffects<z.ZodObject<InferObjectZodSchema<T>>>;
};

type DynamicFormGrouping<T extends FieldValues = FieldValues> =
  | {
      isGrouped: true;
      groups: DynamicFormInputsGroup<T>[];
      inputs?: never;
    }
  | {
      isGrouped?: false;
      groups?: never;
      inputs: DynamicFormInput<T>[];
    };

export type DynamicForm<T extends FieldValues = FieldValues> =
  BaseDynamicForm<T> & DynamicFormGrouping<T>;

export type InferDynamicFormInputsObject<
  T extends WithReadonly<DynamicFormInput>[],
> = UnionToIntersection<
  {
    [I in keyof T]: {
      [K in T[I]["name"]]: T[I];
    };
  }[number]
>;

export type InferDynamicFormInputsZodSchema<
  T extends WithReadonly<DynamicFormInput>[],
> = {
  [K in keyof InferDynamicFormInputsObject<T>]: InferDynamicFormInputsObject<T>[K]["extraParsing"] extends (
    arg: unknown,
  ) => infer ParsingResult
    ? ParsingResult extends z.ZodTypeAny
      ? InferDynamicFormInputsObject<T>[K]["optional"] extends true
        ? z.ZodOptional<ParsingResult>
        : ParsingResult
      : never
    : InferDynamicFormInputsObject<T>[K]["type"] extends "select"
      ? InferDynamicFormInputsObject<T>[K] extends { options: Option<string>[] }
        ? InferDynamicFormInputsObject<T>[K]["optional"] extends true
          ? z.ZodOptional<
              DynamicFormSelectInputZodParserReturn<
                InferDynamicFormInputsObject<T>[K]["options"][number]["value"]
              >
            >
          : DynamicFormSelectInputZodParserReturn<
              InferDynamicFormInputsObject<T>[K]["options"][number]["value"]
            >
        : InferDynamicFormInputsObject<T>[K]["optional"] extends true
          ? z.ZodOptional<DynamicFormSelectInputZodParserReturn<string>>
          : DynamicFormSelectInputZodParserReturn<string>
      : InferDynamicFormInputsObject<T>[K]["type"] extends "combobox"
        ? InferDynamicFormInputsObject<T>[K] extends {
            optionsType: DynamicFormComboboxInputOptionsType;
            multi?: boolean;
          }
          ? InferDynamicFormInputsObject<T>[K]["optional"] extends true
            ? z.ZodOptional<
                DynamicFormComboboxInputZodParserReturn<
                  InferDynamicFormInputsObject<T>[K]["optionsType"],
                  InferDynamicFormInputsObject<T>[K]["multi"] extends true
                    ? true
                    : false
                >
              >
            : DynamicFormComboboxInputZodParserReturn<
                InferDynamicFormInputsObject<T>[K]["optionsType"],
                InferDynamicFormInputsObject<T>[K]["multi"] extends true
                  ? true
                  : false
              >
          : InferDynamicFormInputsObject<T>[K]["optional"] extends true
            ? z.ZodOptional<DynamicFormSelectInputZodParserReturn<string>>
            : DynamicFormSelectInputZodParserReturn<string>
        : InferDynamicFormInputsObject<T>[K]["optional"] extends true
          ? z.ZodOptional<
              DynamicFormInputTypeBasedZodParserReturn[InferDynamicFormInputsObject<T>[K]["type"]]
            >
          : DynamicFormInputTypeBasedZodParserReturn[InferDynamicFormInputsObject<T>[K]["type"]];
};

export type InferDynamicFormGroupsInputs<
  T extends WithReadonly<
    {
      inputs: WithReadonly<DynamicFormInput[]>;
    }[]
  >,
> = FlattenTuple<UnionToTuple<T[number]["inputs"]>>;

export type InferDynamicFormGroupsInputsZodSchema<
  T extends WithReadonly<DynamicFormInputsGroup[]>,
> = InferDynamicFormInputsZodSchema<
  Exclude<InferDynamicFormGroupsInputs<T>, [unknown]>
>;

export type InferDynamicFormZodSchema<T extends DynamicForm> = T extends {
  isGrouped: true;
}
  ? InferDynamicFormGroupsInputsZodSchema<T["groups"]>
  : InferDynamicFormInputsZodSchema<NonNullable<T["inputs"]>>;
