import { type ComponentProps, type PropsWithChildren, forwardRef } from "react";

import { cn } from "$/lib/utils/functions/misc.functions";

import BricksLoader from "../loaders/BricksLoader";

type Props = ComponentProps<"button"> & {
  isLoading?: boolean;
  noFill?: boolean;
};

const Button = forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
  (
    { className, isLoading, disabled, type, children, noFill, ...buttonProps },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        // eslint-disable-next-line react/button-has-type
        type={type || "button"}
        aria-busy={isLoading}
        className={cn(
          "flex min-h-12 w-full items-center justify-center gap-2 rounded-xl bg-primary px-5 font-medium text-snow transition-all duration-200 hover:opacity-75 hover:shadow-sm active:opacity-50 disabled:cursor-not-allowed disabled:opacity-50 aria-busy:cursor-wait",
          noFill && "border bg-snow text-primary",
          className,
        )}
        disabled={disabled || isLoading}
        {...buttonProps}
      >
        {children} {isLoading && <BricksLoader />}
      </button>
    );
  },
);

Button.displayName = "Button";

export default Button;
