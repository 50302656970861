import type {
  ComponentProps,
  MouseEventHandler,
  PropsWithChildren,
} from "react";
import { Drawer } from "vaul";

import { useRootDialog } from "$/lib/providers/DialogProvider";
import { cn } from "$/lib/utils/functions/misc.functions";

export default function BottomSheetTrigger({
  onClick,
  className,
  children,
  ...buttonProps
}: PropsWithChildren<ComponentProps<"button">>) {
  const rootDialog = useRootDialog();

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    rootDialog.handleSetOpen(true);
    onClick?.(e);
  };

  return (
    <Drawer.Trigger asChild>
      <button
        type="button"
        onClick={handleClick}
        className={cn(
          "outline-none ring-blue/50 duration-150 focus:ring-2",
          className,
        )}
        {...buttonProps}
      >
        {children}
      </button>
    </Drawer.Trigger>
  );
}
