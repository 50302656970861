import { type ComponentProps, useId } from "react";

export default function MaterialIcon(svgProps: ComponentProps<"svg">) {
  const pathId = useId();
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      aria-label="Material"
      {...svgProps}
    >
      <g clipPath={`url(#${pathId})`}>
        <path d="M16.8059 13.6999L9.25789 17.9362C9.09758 18.0262 8.90211 18.0262 8.7418 17.9362L1.19375 13.6999C0.834805 13.4988 0.835508 12.9809 1.19375 12.7802L3.07109 11.7266L8.2257 14.6196C8.70643 14.8897 9.29396 14.8893 9.77398 14.6196L14.9286 11.7266L16.8059 12.7802C17.1649 12.9813 17.1642 13.4991 16.8059 13.6999Z" />
        <path d="M16.8059 8.53801L14.9286 7.48438L9.77398 10.3774C9.2941 10.647 8.70629 10.6474 8.2257 10.3774L3.07109 7.48438L1.19375 8.53801C0.835508 8.73875 0.834805 9.2566 1.19375 9.4577L8.7418 13.694C8.90211 13.784 9.09758 13.784 9.25789 13.694L16.8059 9.4577C17.1642 9.25695 17.1649 8.7391 16.8059 8.53801ZM4.33813 10.1517C4.04668 10.1517 3.81078 9.91543 3.81078 9.62434C3.81078 9.33289 4.04668 9.09699 4.33813 9.09699C4.62922 9.09699 4.86547 9.33289 4.86547 9.62434C4.86547 9.91543 4.62922 10.1517 4.33813 10.1517ZM6.58355 11.4577C6.29211 11.4577 6.05621 11.2215 6.05621 10.9304C6.05621 10.6389 6.29211 10.403 6.58355 10.403C6.87465 10.403 7.1109 10.6389 7.1109 10.9304C7.1109 11.2215 6.87465 11.4577 6.58355 11.4577ZM8.99984 12.6812C8.70875 12.6812 8.4725 12.4453 8.4725 12.1538C8.4725 11.8627 8.70875 11.6265 8.99984 11.6265C9.29094 11.6265 9.52719 11.8627 9.52719 12.1538C9.52719 12.4453 9.29094 12.6812 8.99984 12.6812ZM11.4161 11.4577C11.125 11.4577 10.8888 11.2215 10.8888 10.9304C10.8888 10.6389 11.125 10.403 11.4161 10.403C11.7076 10.403 11.9435 10.6389 11.9435 10.9304C11.9435 11.2215 11.7076 11.4577 11.4161 11.4577ZM13.6616 10.1517C13.3705 10.1517 13.1342 9.91543 13.1342 9.62434C13.1342 9.33289 13.3705 9.09699 13.6616 9.09699C13.953 9.09699 14.1889 9.33289 14.1889 9.62434C14.1889 9.91543 13.953 10.1517 13.6616 10.1517Z" />
        <path d="M16.8059 5.22352L9.25789 9.45984C9.09754 9.54984 8.90211 9.54984 8.7418 9.45984L1.19375 5.22352C0.834805 5.02242 0.835508 4.50457 1.19375 4.30383L8.7418 0.0675C8.90214 -0.0225 9.09758 -0.0225 9.25789 0.0675L16.8059 4.30383C17.1649 4.50492 17.1642 5.02277 16.8059 5.22352Z" />
      </g>
      <defs>
        <clipPath id={pathId}>
          <rect width="18" height="18" />
        </clipPath>
      </defs>
    </svg>
  );
}
