import type { ComponentProps } from "react";

type Props = ComponentProps<"svg"> & {
  variant?: "outlined" | "filled";
};

export default function ClockIcon({
  variant = "outlined",
  ...svgProps
}: Props) {
  if (variant === "filled") {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="#7D7D7D"
        role="img"
        aria-label="clock"
        {...svgProps}
      >
        <path d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389956 7.20038 -0.17433 9.00998 0.172937 10.7558C0.520203 12.5016 1.37737 14.1053 2.63604 15.364C3.89472 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9973 6.61389 17.0482 4.32629 15.3609 2.63905C13.6737 0.951818 11.3861 0.00272979 9 0ZM12.3363 12.3363C12.2528 12.4199 12.1536 12.4862 12.0444 12.5314C11.9352 12.5767 11.8182 12.6 11.7 12.6C11.5818 12.6 11.4648 12.5767 11.3556 12.5314C11.2464 12.4862 11.1472 12.4199 11.0636 12.3363L8.36365 9.6363C8.28005 9.55276 8.21374 9.45357 8.1685 9.34439C8.12326 9.23521 8.09999 9.11818 8.1 9V3.6C8.1 3.3613 8.19482 3.13239 8.36361 2.9636C8.53239 2.79482 8.76131 2.7 9 2.7C9.2387 2.7 9.46762 2.79482 9.6364 2.9636C9.80518 3.13239 9.9 3.3613 9.9 3.6V8.62735L12.3363 11.0636C12.4199 11.1472 12.4862 11.2464 12.5314 11.3556C12.5767 11.4648 12.6 11.5818 12.6 11.7C12.6 11.8182 12.5767 11.9352 12.5314 12.0444C12.4862 12.1536 12.4199 12.2528 12.3363 12.3363Z" />
      </svg>
    );
  }

  return (
    <svg
      viewBox="0 0 512 512"
      height="16"
      width="16"
      fill="currentColor"
      role="img"
      aria-label="clock"
      {...svgProps}
    >
      <path
        d="M347.216,301.211l-71.387-53.54V138.609c0-10.966-8.864-19.83-19.83-19.83c-10.966,0-19.83,8.864-19.83,19.83v118.978
			c0,6.246,2.935,12.136,7.932,15.864l79.318,59.489c3.569,2.677,7.734,3.966,11.878,3.966c6.048,0,11.997-2.717,15.884-7.952
			C357.766,320.208,355.981,307.775,347.216,301.211z"
      ></path>
      <path
        d="M256,0C114.833,0,0,114.833,0,256s114.833,256,256,256s256-114.833,256-256S397.167,0,256,0z M256,472.341
			c-119.275,0-216.341-97.066-216.341-216.341S136.725,39.659,256,39.659c119.295,0,216.341,97.066,216.341,216.341
			S375.275,472.341,256,472.341z"
      ></path>
    </svg>
  );
}
