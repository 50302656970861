import { Link } from "@tanstack/react-router";
import { type ComponentProps } from "react";

import type { UserRoleEnum } from "$/features/user/types/models/user.types.ts";
import { useAuth } from "$/lib/providers/AuthProvider";
import { useHeaderContext } from "$/lib/providers/HeaderProvider.tsx";
import { protectedNavigation } from "$/lib/utils/constants/misc.constants.ts";
import { cn } from "$/lib/utils/functions/misc.functions";

import AbsenceIcon from "../../icons/AbsenceIcon.tsx";
import ActionsIcon from "../../icons/ActionsIcon.tsx";
import AdditionalServicesIcon from "../../icons/AdditionalServicesIcon.tsx";
import AdministrationIcon from "../../icons/AdministrationIcon.tsx";
import ArrowLeftIcon from "../../icons/ArrowLeftIcon";
import BroadcastIcon from "../../icons/BroadcastIcon.tsx";
import CNRIcon from "../../icons/CNRIcon.tsx";
import CalendarIcon from "../../icons/CalendarIcon.tsx";
import CertificationIcon from "../../icons/CertificationIcon.tsx";
import ClientsIcon from "../../icons/ClientsIcon.tsx";
import ClockIcon from "../../icons/ClockIcon.tsx";
import CommercialIcon from "../../icons/CommercialIcon.tsx";
import DeliveryRequestIcon from "../../icons/DeliveryRequestIcon.tsx";
import DriverIcon from "../../icons/DriverIcon.tsx";
import EquipmentIcon from "../../icons/EquipmentIcon.tsx";
import ExclamationIcon from "../../icons/ExclamationIcon.tsx";
import FlagIcon from "../../icons/FlagIcon.tsx";
import IncidentIcon from "../../icons/IncidentIcon.tsx";
import ListIcon from "../../icons/ListIcon.tsx";
import MainLogo from "../../icons/MainLogo";
import MaterialIcon from "../../icons/MaterialIcon.tsx";
import ModificationsIcon from "../../icons/ModificationsIcon";
import PoolsIcon from "../../icons/PoolsIcon.tsx";
import ProfileIcon from "../../icons/ProfileIcon";
import ReplacementsIcon from "../../icons/ReplacementsIcon.tsx";
import ResourcesIcon from "../../icons/ResourcesIcon.tsx";
import SettingsIcon from "../../icons/SettingsIcon";
import TrailerIcon from "../../icons/TrailerIcon.tsx";
import TransverseIcon from "../../icons/TransversaleIcon.tsx";
import VehicleDamageIcon from "../../icons/VehicleDamageIcon.tsx";
import VehicleIcon from "../../icons/VehicleIcon.tsx";
import LogoutButton from "./LogoutButton";
import SidebarDeepLink from "./SidebarDeepLink.tsx";
import SidebarLink from "./SidebarLink.tsx";

export type SideBarLinkProps = {
  label: string;
  path: string;
  icon: (props: ComponentProps<"svg">) => JSX.Element;
  deepLinks: Omit<SideBarLinkProps, "deepLinks">[];
  allowedRoles: UserRoleEnum[];
  closeSidebar?: () => void;
};

const links: SideBarLinkProps[] = [
  {
    label: "Commercial",
    path: "/sales",
    icon: CommercialIcon,
    allowedRoles: protectedNavigation["sales"],
    deepLinks: [
      {
        allowedRoles: protectedNavigation["sales/companies"],
        label: "Clients",
        path: "/companies",
        icon: ClientsIcon,
      },
      {
        allowedRoles: protectedNavigation["sales/companies"],
        label: "Cahiers des charges",
        path: "/delivery-request",
        icon: DeliveryRequestIcon,
      },
      {
        allowedRoles: protectedNavigation["sales/additional-services"],
        label: "Prestations additionnelles",
        path: "/additional-services",
        icon: AdditionalServicesIcon,
      },
      // {
      //   allowedRoles: protectedNavigation["sales/stats"],
      //   label: "Chiffre d'affaires",
      //   path: "/stats",
      //   icon: StatsIcon,
      // },
    ],
  },

  {
    label: "Suivis des tournées",
    path: "/journey-management",
    icon: PoolsIcon,
    allowedRoles: protectedNavigation["journey-management"],
    deepLinks: [
      {
        allowedRoles:
          protectedNavigation["journey-management/delivery-request"],
        label: "CDC Vue Conception",
        path: "/delivery-request",
        icon: DeliveryRequestIcon,
      },
      {
        allowedRoles: protectedNavigation["journey-management/drivers-pools"],
        label: "Tournées",
        path: "/drivers-pools",
        icon: PoolsIcon,
      },
      {
        allowedRoles: protectedNavigation["journey-management/journeys"],
        label: "Liste des trajets",
        path: "/journeys",
        icon: ListIcon,
      },
      {
        allowedRoles: protectedNavigation["journey-management/calendar"],
        label: "Calendrier des trajets",
        path: "/calendar",
        icon: CalendarIcon,
      },
      {
        allowedRoles: protectedNavigation["journey-management/live"],
        label: "Trajets en temps réel",
        path: "/live",
        icon: BroadcastIcon,
      },
      {
        allowedRoles: protectedNavigation["journey-management/modifications"],
        label: "Modifications",
        path: "/modifications",
        icon: ModificationsIcon,
      },
      {
        allowedRoles: protectedNavigation["journey-management/replacements"],
        label: "Remplacements",
        path: "/replacements",
        icon: ReplacementsIcon,
      },
    ],
  },
  {
    label: "Suivis des événements",
    path: "/incidents-management",
    icon: ExclamationIcon,
    allowedRoles: protectedNavigation["incidents-management"],
    deepLinks: [
      {
        label: "Incidents",
        path: "/incidents",
        icon: IncidentIcon,
        allowedRoles: protectedNavigation["incidents-management/incidents"],
      },
      {
        allowedRoles: protectedNavigation["incidents-management/infractions"],
        label: "Infractions",
        path: "/infractions",
        icon: FlagIcon,
      },
      {
        allowedRoles: protectedNavigation["incidents-management/delays"],
        label: "Retards",
        path: "/delays",
        icon: ClockIcon,
      },
      {
        allowedRoles: protectedNavigation["incidents-management/damages"],
        label: "Dommages",
        path: "/damages",
        icon: VehicleDamageIcon,
      },
    ],
  },
  {
    label: "Resources Humaines",
    path: "/human-resources",
    icon: ResourcesIcon,
    allowedRoles: protectedNavigation["human-resources"],
    deepLinks: [
      {
        label: "Chauffeurs",
        path: "/drivers",
        icon: DriverIcon,
        allowedRoles: protectedNavigation["human-resources/drivers"],
      },
      {
        label: "Absences",
        path: "/absences",
        icon: AbsenceIcon,
        allowedRoles: protectedNavigation["human-resources/absences"],
      },
      {
        label: "Habilitations",
        path: "/driver-certifications",
        icon: CertificationIcon,
        allowedRoles:
          protectedNavigation["human-resources/driver-certifications"],
      },
    ],
  },
  {
    label: "Matériel",
    path: "/materials",
    icon: MaterialIcon,
    allowedRoles: protectedNavigation["materials"],

    deepLinks: [
      {
        label: "Véhicules",
        path: "/vehicles",
        icon: VehicleIcon,
        allowedRoles: protectedNavigation["materials/vehicles"],
      },
      {
        label: "Remorques",
        path: "/trailers",
        icon: TrailerIcon,
        allowedRoles: protectedNavigation["materials/trailers"],
      },
      {
        label: "Equipment",
        path: "/equipment",
        icon: EquipmentIcon,
        allowedRoles: protectedNavigation["materials/equipment"],
      },
    ],
  },
  {
    label: "Transverse",
    path: "/transverse",
    icon: TransverseIcon,
    allowedRoles: protectedNavigation["transverse"],
    deepLinks: [
      {
        label: "CNR",
        path: "/cnr",
        icon: CNRIcon,
        allowedRoles: protectedNavigation["transverse/cnr"],
      },
      {
        label: "Cause - retards",
        path: "/delays",
        icon: ClockIcon,
        allowedRoles: protectedNavigation["transverse/delays"],
      },
      {
        label: "Cause - incidents",
        path: "/incidents",
        icon: IncidentIcon,
        allowedRoles: protectedNavigation["transverse/incidents"],
      },
      {
        label: "Cause - dommages",
        path: "/damages",
        icon: VehicleDamageIcon,
        allowedRoles: protectedNavigation["transverse/damages"],
      },
      {
        label: "Actions",
        path: "/actions",
        icon: ActionsIcon,
        allowedRoles: protectedNavigation["transverse/actions"],
      },
    ],
  },
  {
    label: "Adminstration",
    path: "/administration",
    icon: AdministrationIcon,
    allowedRoles: protectedNavigation["administration"],

    deepLinks: [
      {
        label: "Paramètres",
        path: "/parameters",
        icon: SettingsIcon,
        allowedRoles: protectedNavigation["administration/parameters"],
      },
      {
        label: "Profils",
        path: "/profiles",
        icon: ProfileIcon,
        allowedRoles: protectedNavigation["administration/profiles"],
      },
      {
        label: "Utilisateurs",
        path: "/users",
        icon: ClientsIcon,
        allowedRoles: protectedNavigation["administration/users"],
      },
    ],
  },
];

export default function SideBar() {
  const { toggleSideBar, isSideBarOpen } = useHeaderContext();
  const { role } = useAuth();
  if (!role) return null;

  return (
    <div
      className={cn(
        "sidebar_mask fixed bottom-0 left-0 top-0 z-layout-max flex h-full w-full -translate-x-full duration-300 ease-out md:w-64 md:translate-x-0",
        isSideBarOpen && "translate-x-0",
        !isSideBarOpen && "ease-in md:delay-0 md:duration-0",
      )}
    >
      <nav className="top-0 flex h-full w-1/2 flex-col items-center gap-12 bg-black pt-12 text-snow md:w-64">
        <div className="flex items-center gap-4">
          <button
            type="button"
            onClick={toggleSideBar}
            className="rounded-circle bg-snow p-1 text-black md:hidden"
          >
            <ArrowLeftIcon width={18} height={18} />
          </button>

          <Link to="/">
            <MainLogo />
          </Link>
        </div>

        <div className="mb-11 flex size-full flex-col justify-between overflow-y-auto">
          <div className="overflow-y-auto pb-8 scrollbar-none">
            {links.map(
              (link) =>
                link.allowedRoles.includes(role) && (
                  <SidebarLink
                    key={link.path}
                    closeSidebar={toggleSideBar}
                    role={role}
                    {...link}
                  />
                ),
            )}
          </div>

          <div className="space-y-3">
            {/* separator */}
            <div className="mx-4 h-0.5 rounded-pill bg-snow" />
            <SidebarDeepLink
              allowedRoles={["ADMIN", "CONCEPTOR", "OPERATOR", "SALES"]}
              path="/profile"
              label="Profil"
              icon={ProfileIcon}
              closeSidebar={toggleSideBar}
            />
            <SidebarDeepLink
              allowedRoles={["ADMIN", "CONCEPTOR", "OPERATOR", "SALES"]}
              path="/settings"
              label="Paramètres"
              icon={SettingsIcon}
              closeSidebar={toggleSideBar}
            />
            <LogoutButton />
          </div>
        </div>
      </nav>
      <button
        type="button"
        onClick={toggleSideBar}
        className="h-full w-1/2 md:hidden"
      />
    </div>
  );
}
