import type { ComponentProps } from "react";

import CalendarIcon from "$/lib/components/icons/CalendarIcon";
import { dateTimeFormatter } from "$/lib/utils/constants/intl.constants";
import { cn } from "$/lib/utils/functions/misc.functions";
import type { DatePickerRange } from "$/types/misc.types";

import DatePickerInput from "./DatePickerInput";

type Props = Omit<
  ComponentProps<typeof DatePickerInput<true>>,
  | "selectsRange"
  | "selectsEnds"
  | "startDate"
  | "endDate"
  | "onChange"
  | "customInput"
> & {
  startDate: Date | null;
  endDate: Date | null;
  onChange: (dates: DatePickerRange) => void;
};

export default function DateRangePickerInput({
  startDate,
  endDate,
  className,
  onChange,
  ...datePickerInputProps
}: Props) {
  const formattedStartDate = startDate
    ? dateTimeFormatter.format(new Date(startDate))
    : "Début";
  const formattedEndDate = endDate
    ? dateTimeFormatter.format(new Date(endDate))
    : "Fin";

  return (
    <DatePickerInput<true>
      selectsRange
      selectsEnd
      className={cn("min-h-10 rounded-lg px-4 py-2", className)}
      startDate={startDate ? new Date(startDate) : null}
      endDate={endDate ? new Date(endDate) : null}
      onChange={onChange}
      customInput={
        <button type="button" className="flex items-center gap-2 bg-blue-light">
          <CalendarIcon fill="black" />
          <span>
            {formattedStartDate} - {formattedEndDate}
          </span>
        </button>
      }
      isClearable
      {...datePickerInputProps}
    />
  );
}
