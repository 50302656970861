import DateRangePickerInput from "$/lib/components/ui/inputs/date-picker-input/DateRangePickerInput";
import { useMediaQuery } from "$/lib/hooks/useMediaQuery";
import { convertDateToString } from "$/lib/utils/functions/date.functions";
import type { DatePickerRange } from "$/types/misc.types";

import { useEnhancedTable } from "./EnhancedTableProvider";

export default function TableDateRangeFilter() {
  const { filters, handleSetFilters } = useEnhancedTable<
    unknown,
    unknown,
    { dateRange: (string | null)[] }
  >();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const onChange = (dates: DatePickerRange) => {
    handleSetFilters(
      "dateRange",
      dates.map((date) => (date ? convertDateToString(date) : null)),
    );
  };

  const startDate = filters.dateRange?.[0]
    ? new Date(filters.dateRange[0])
    : null;

  const endDate = filters.dateRange?.[1]
    ? new Date(filters.dateRange[1])
    : null;

  return (
    <DateRangePickerInput
      startDate={startDate}
      endDate={endDate}
      onChange={onChange}
      popperPlacement={isMobile ? "bottom-start" : "top"}
    />
  );
}
