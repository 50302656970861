import { restApiClient } from "$/lib/utils/clients/restApi.client";
import type { CNRIndex } from "$/types/models/CNR-index";

import type {
  CreateCNRIndexPayloadType,
  GetCNRsFilters,
} from "../types/CNR-index-apis.types";

export async function getCNRs(filters?: GetCNRsFilters): Promise<CNRIndex[]> {
  return restApiClient.url("/cnr-index/all").post({ ...filters });
}

export async function getCNRsList(
  pagination?: { offset: number; limit: number },
  filters?: {
    search?: string;
  },
  include?: number[],
) {
  return restApiClient
    .url(`/cnr-index/list`)
    .post<CNRIndex[]>({ ...pagination, ...filters, include });
}

export async function createCNRIndex(payload: CreateCNRIndexPayloadType) {
  return await restApiClient.url("/cnr-index").post<never>(payload);
}
