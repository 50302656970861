import { Link } from "@tanstack/react-router";
import { Fragment } from "react/jsx-runtime";

import authBg from "$/assets/auth-bg.svg";

import ArrowLeftIcon from "../icons/ArrowLeftIcon";
import HomeIcon from "../icons/HomeIcon";

export default function NotFound() {
  return (
    <Fragment>
      <main
        className="flex h-full flex-col items-center justify-center bg-snow bg-cover"
        style={{
          backgroundImage: `url(${authBg})`,
        }}
      >
        <h1 className="mb-4 text-[180px] leading-[180px] text-primary-200 md:text-[300px] md:leading-[300px]">
          404
          <span className="flex justify-center text-base">
            La page que vous recherchez n'existe pas...
          </span>
        </h1>
        <div className="flex w-full items-center justify-center gap-8 ^lg:flex-col ^lg:gap-2">
          <button
            type="button"
            onClick={() => history.go(-1)}
            className="flex items-center gap-2 text-primary-400 underline"
          >
            <ArrowLeftIcon /> Retour à la page précédente
          </button>

          <Link
            to="/"
            className="flex items-center gap-2 text-primary-400 underline"
          >
            <HomeIcon />
            Retour à l'accueil
          </Link>
        </div>
      </main>
    </Fragment>
  );
}
