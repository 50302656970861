import type { ReactNode } from "@tanstack/react-router";
import {
  type DeepKeys,
  type DeepValue,
  type IdentifiedColumnDef,
  type OnChangeFn,
  type Row,
  type RowSelectionState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  type ComponentProps,
  Fragment,
  useEffect,
  useMemo,
  useRef,
} from "react";

import { cn } from "$/lib/utils/functions/misc.functions";

import Pagination from "./Pagination";
import TableBodyContent from "./TableBodyContent";
import TableRowCheckbox from "./TableRowCheckbox";

type TableBaseProps<T> = {
  columns: TableColumn<T>[];
  errorComponent?: ReactNode;
  noDataComponent?: ReactNode;
  headerClassName?: string;
  tableClassName?: string;
  onRowClick?: (
    row: Row<T>,
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => void;
};

export type TableDataProps<T> =
  | {
      data: T[];
      isPendingData: false;
      isErroredData: false;
    }
  | {
      data: undefined;
      isPendingData: true;
      isErroredData: false;
    }
  | {
      data: undefined;
      isPendingData: false;
      isErroredData: true;
    };

type TableSelectableProps =
  | {
      selectable?: false;
      onRowSelectionChange?: never;
      selectedRows?: never;
    }
  | {
      selectable: true;
      selectedRows: { [key: number]: boolean };
      onRowSelectionChange: OnChangeFn<RowSelectionState>;
    };

type TablePaginationProps =
  | {
      paginatable: true;
      pagination: Omit<
        ComponentProps<typeof Pagination>,
        "isPendingData" | "isErroredData"
      >;
    }
  | {
      paginatable?: false;
      pagination?: never;
    };

type TableProps<T> = TableBaseProps<T> &
  TableDataProps<T> &
  TableSelectableProps &
  TablePaginationProps;

type SelectorWithValues<T> = {
  [K in DeepKeys<T>]: {
    selector: K;
  } & IdentifiedColumnDef<T, DeepValue<T, K>>;
}[DeepKeys<T>];

export type TableColumn<T> = SelectorWithValues<T>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EMPTY_DATA_ARR: any[] = [];

export default function Table<T>({
  data,
  isPendingData,
  isErroredData,
  selectable,
  columns,
  selectedRows,
  errorComponent,
  noDataComponent,
  headerClassName,
  tableClassName,
  pagination,
  paginatable,
  onRowSelectionChange,
  onRowClick,
}: TableProps<T>) {
  const lastSelectedRowRef = useRef<{ index: number; checked: boolean }>({
    index: -1,
    checked: false,
  });
  const handleSetLastSelectedRow = (index: number, checked: boolean) => {
    lastSelectedRowRef.current = { index, checked };
  };

  const containerRef = useRef<HTMLDivElement | null>(null);
  const tableHeaderRef = useRef<HTMLTableRowElement | null>(null);

  // since the data is an array passed from the props and usually coming from
  // react-query, it's best if we memoize it here to prevent unnecessary rerenders.
  // This becomes especially relevant when using the getIsAllRowsSelected() method
  // on the table (https://github.com/TanStack/table/issues/4614)
  const memoizedData = useMemo(() => {
    // the EMPTY_DATA_ARR also prevents unnecessary rerenders by keeping the
    // same array reference
    if (!data || data.length === 0) return EMPTY_DATA_ARR as T[];
    return data;
  }, [data]);

  const columnHelper = createColumnHelper<T>();
  const tableColumns = useMemo(() => {
    const mappedColumns = columns.map(
      ({ selector, cell, header, ...column }) => {
        return columnHelper.accessor(selector, {
          id: column.id || (selector as string),
          header: header ?? (() => ""),
          cell: cell ?? ((info) => info.getValue() ?? "N/A"),
          ...column,
        });
      },
    );

    if (selectable) {
      const selectorColumn = columnHelper.accessor(() => {}, {
        id: "$_TABLE_SELECTOR_COLUMN_$", // no need for a real id
        enableResizing: false,
        maxSize: 60,
        header: ({ table }) => (
          <div className="ml-4">
            <input
              className="size-4 hover:cursor-pointer"
              disabled={!memoizedData.length || isPendingData}
              type="checkbox"
              checked={table.getIsAllRowsSelected()}
              onChange={() => table.toggleAllRowsSelected()}
            />
          </div>
        ),
        cell: ({ row, table }) => {
          return (
            <TableRowCheckbox
              table={table}
              row={row}
              lastSelectedRow={lastSelectedRowRef.current}
              handleSetLastSelectedRow={handleSetLastSelectedRow}
            />
          );
        },
      });

      mappedColumns.unshift(selectorColumn as (typeof mappedColumns)[0]);
    }

    return mappedColumns;
  }, [isPendingData, columns, selectable, memoizedData, columnHelper]);

  const table = useReactTable<T>({
    data: memoizedData,
    columns: tableColumns,
    onRowSelectionChange,
    defaultColumn: {
      minSize: 20,
      maxSize: 500,
    },
    ...(selectable && {
      state: {
        rowSelection: selectedRows,
      },
    }),

    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      // eslint-disable-next-line no-inner-declarations
      function handleScroll() {
        if (container) {
          const scrollPosition = container.scrollTop;
          if (scrollPosition > 20) {
            tableHeaderRef.current?.classList.add("shadow-md");
          } else {
            tableHeaderRef.current?.classList.remove("shadow-md");
          }
        }
      }

      container.addEventListener("scroll", handleScroll);

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <div className="flex flex-col gap-6">
      <div
        ref={containerRef}
        className={cn(
          "relative z-50 max-h-[67dvh] overflow-auto bg-snow",
          tableClassName,
        )}
      >
        <table className="w-full min-w-[1000px] border-separate border-spacing-0 border-transparent bg-snow text-grey-500">
          <thead className="z-50 bg-snow">
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <Fragment key={headerGroup.id}>
                  <tr
                    className="sticky top-0 z-50 h-16 rounded-b-lg bg-snow duration-200 ease-in"
                    ref={tableHeaderRef}
                    key={headerGroup.id}
                  >
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{
                            minWidth: Math.max(
                              header.column.columnDef.minSize ?? 0,
                              header.getSize(),
                            ),
                            maxWidth: header.column.columnDef.maxSize,
                          }}
                          className={cn(
                            "pl-4 text-start text-black first:rounded-bl-lg last:rounded-br-lg",
                            headerClassName,
                          )}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {/* TODO: implement resizing kept this for reference
                          {header.column.getCanResize() && (
                            <button
                              {...{
                                onDoubleClick: () => header.column.resetSize(),
                                onMouseDown: header.getResizeHandler(),
                                onTouchStart: header.getResizeHandler(),
                                className: ` w-[2px] bg-grey  hover:bg-black cursor-col-resize  active:cursor-col-resize focus:cursor-col-resize   absolute right-1 top-1/2  -translate-y-1/2  h-1/2 resizer `,
                              }}
                            />
                          )} */}
                        </th>
                      );
                    })}
                    <th />
                  </tr>
                </Fragment>
              );
            })}
          </thead>

          <tbody>
            <TableBodyContent<T>
              data={memoizedData}
              noDataComponent={noDataComponent}
              errorComponent={errorComponent}
              isErroredData={isErroredData}
              isPendingData={isPendingData}
              table={table}
              tableColumns={tableColumns.length}
              onRowClick={onRowClick}
            />
          </tbody>
        </table>
      </div>

      {paginatable && !isErroredData && (
        <Pagination {...pagination} isPendingData={isPendingData} />
      )}
    </div>
  );
}
