import type { PropsWithChildren } from "react";

import { useMediaQuery } from "$/lib/hooks/useMediaQuery";

import BottomSheetContent from "../bottom-sheet/BottomSheetContent";
import ModalContent from "../modal/ModalContent";

type Props = {
  title?: string;
  description?: string;
  className?: string;
};

export default function ResponsiveDialogContent(
  props: PropsWithChildren<Props>,
) {
  const isMobile = useMediaQuery("(max-width: 767px)");

  if (isMobile) return <BottomSheetContent {...props} />;

  return <ModalContent {...props} />;
}
