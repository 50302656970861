import { Navigate, Outlet, createFileRoute } from "@tanstack/react-router";
import { Fragment } from "react/jsx-runtime";

import authBg from "$/assets/auth-bg.svg";
import MainLogo from "$/lib/components/icons/MainLogo";
import PageLoader from "$/lib/components/loaders/page-loader";
import { useAuth } from "$/lib/providers/AuthProvider";

export const Route = createFileRoute("/_authentication-layout")({
  component: AuthLayoutComponent,
});

function AuthLayoutComponent() {
  const { user, isFetching } = useAuth();

  if (isFetching) return <PageLoader />;

  if (user) return <Navigate to="/" />;

  return (
    <Fragment>
      <MainLogo className="fixed left-1/2 top-16 h-20 w-52 -translate-x-1/2 text-primary md:top-32" />

      <main
        className="flex h-full items-center justify-center bg-snow bg-cover"
        style={{
          backgroundImage: `url(${authBg})`,
        }}
      >
        <div className="flex size-full max-w-md flex-col items-center justify-center px-5">
          <Outlet />
        </div>
      </main>
    </Fragment>
  );
}
