import * as Dialog from "@radix-ui/react-dialog";
import {
  type ComponentProps,
  type MouseEventHandler,
  type PropsWithChildren,
} from "react";

import { useRootDialog } from "$/lib/providers/DialogProvider";
import { cn } from "$/lib/utils/functions/misc.functions";

export default function ModalTrigger({
  onClick,
  className,
  children,
  ...buttonProps
}: PropsWithChildren<ComponentProps<"button">>) {
  const rootDialog = useRootDialog();

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    rootDialog.handleSetOpen(true);
    onClick?.(e);
  };

  return (
    <Dialog.Trigger asChild>
      <button
        type="button"
        onClick={handleClick}
        className={cn("outline-none ring-blue/80 focus:ring-2", className)}
        {...buttonProps}
      >
        {children}
      </button>
    </Dialog.Trigger>
  );
}
