import { type ComponentProps, useId } from "react";

export default function ActionsIcon(svgProps: ComponentProps<"svg">) {
  const pathId = useId();
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      aria-label="Actions"
      {...svgProps}
    >
      <g clipPath={`url(#${pathId})`}>
        <path d="M9.0146 6.19629C8.06319 6.19629 7.28906 6.97041 7.28906 7.92183C7.28906 8.87325 8.06319 9.64723 9.0146 9.64723C9.96602 9.64723 10.74 8.87325 10.74 7.92183C10.74 6.97041 9.96602 6.19629 9.0146 6.19629Z" />
        <path d="M10.5261 13.5151C10.042 13.6467 9.53291 13.717 9.00763 13.717C5.81239 13.717 3.21289 11.1171 3.21289 7.92169C3.21289 4.72646 5.81239 2.12695 9.00763 2.12695C12.203 2.12695 14.8029 4.72646 14.8029 7.92169C14.8029 9.41103 14.2382 10.7704 13.3115 11.7981L15.5464 12.3968C16.4454 11.0892 16.9299 9.543 16.9299 7.92197C16.9299 5.80586 16.1058 3.81651 14.6096 2.32031C13.1132 0.823975 11.1239 0 9.00791 0C6.8918 0 4.90245 0.823975 3.40625 2.32031C1.90991 3.81651 1.08594 5.80586 1.08594 7.92197C1.08594 10.0379 1.90991 12.0273 3.40625 13.5236C4.90245 15.0198 6.8918 15.8439 9.00791 15.8439C9.71652 15.8439 10.4107 15.7508 11.0772 15.5715L10.5261 13.5151Z" />
        <path d="M13.7541 7.92169C13.7541 5.30818 11.6274 3.18164 9.01349 3.18164C6.39998 3.18164 4.27344 5.30818 4.27344 7.92169C4.27344 10.5356 6.39998 12.6623 9.01349 12.6623C9.44415 12.6623 9.86191 12.6046 10.2588 12.4963L9.82071 10.8612L12.1396 11.4824C13.129 10.6129 13.7541 9.33893 13.7541 7.92169ZM9.01376 10.7021C7.48076 10.7021 6.23354 9.45483 6.23354 7.92197C6.23354 6.38896 7.48076 5.14174 9.01376 5.14174C10.5468 5.14174 11.7939 6.38896 11.7939 7.92197C11.7939 9.45483 10.5466 10.7021 9.01376 10.7021Z" />
        <path d="M13.6515 16.2175L15.4336 17.9996L16.9597 16.4735L15.1776 14.6914L16.2059 13.6635L11.3125 12.3525L12.6236 17.2456L13.6515 16.2175Z" />
      </g>
      <defs>
        <clipPath id={pathId}>
          <rect width="18" height="18" />
        </clipPath>
      </defs>
    </svg>
  );
}
