import { type ComponentProps, type ReactNode, forwardRef, useId } from "react";
import { NumericFormat } from "react-number-format";

import { cn } from "$/lib/utils/functions/misc.functions";

import InputError from "./InputError";

export type Props = ComponentProps<typeof NumericFormat> & {
  label: string;
  hideLabel?: boolean;
  postFix?: ReactNode;
  error?: string;
  onInteractiveChange?: (delta: number) => void;
};

const NumberInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      label,
      hideLabel,
      postFix,
      error,
      name,
      className,
      isAllowed,
      ...inputProps
    },
    ref,
  ) => {
    const innerId = useId();
    const inputId = id || innerId;
    const labelHtmlFor = name || inputId;

    // const [onKeyDown, onWheel] = useMemo(() => {
    //   const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    //     onKeyDownProp?.(e);
    //     if (e.key === "ArrowUp" || e.key === "ArrowDown") {
    //       e.preventDefault();
    //       onInteractiveChange?.(e.key === "ArrowUp" ? 1 : -1);
    //     }
    //   };
    //   const onWheel = (e: React.WheelEvent<HTMLInputElement>) => {
    //     onWheelProp?.(e);
    //     e.preventDefault();
    //     onInteractiveChange?.(e.deltaY);
    //   };
    //   return [onKeyDown, onWheel];
    // }, [onKeyDownProp, onWheelProp, onInteractiveChange]);

    return (
      <div className="w-full">
        <label
          htmlFor={labelHtmlFor}
          className={cn(
            "mb-1 ml-2 block text-xs text-black",
            hideLabel && "hidden",
          )}
        >
          {label}
        </label>
        <div className="relative">
          <NumericFormat
            id={inputId}
            aria-invalid={!!error}
            isAllowed={isAllowed}
            name={name}
            decimalSeparator=","
            getInputRef={ref}
            className={cn(
              "min-h-12 w-full rounded-lg border-2 border-blue-light bg-blue-light px-2 text-sm outline-none duration-150 placeholder:text-grey-200 focus:border-primary/30 disabled:cursor-not-allowed disabled:opacity-50 aria-invalid:border-error aria-invalid:focus:border-error",
              className,
            )}
            {...inputProps}
          />
          {!!postFix && (
            <div className="absolute right-2 top-1/2 -translate-y-1/2">
              {postFix}
            </div>
          )}
        </div>
        {!!error && <InputError errorMessage={error} />}
      </div>
    );
  },
);

NumberInput.displayName = "NumberInput";

export default NumberInput;
