import type { ComponentProps } from "react";
import { type FieldValues } from "react-hook-form";

import ResponsiveDialogContent from "$/lib/components/common/dialogs/responsive-dialog.tsx/ResponsiveDialogContent";
import ResponsiveDialogPortal from "$/lib/components/common/dialogs/responsive-dialog.tsx/ResponsiveDialogPortal";
import Form from "$/lib/components/common/form/Form";
import Button from "$/lib/components/ui/Button";
import { useRootDialog } from "$/lib/providers/DialogProvider";

import {
  type DynamicForm,
  flattenDynamicFormGroupsInputs,
  generateDynamicFormSchema,
} from "../dynamic-form";
import DialogFormInput from "./DialogFormInput";
import DialogFormInputGroup from "./DialogFormInputGroup";

type FormProps<T extends FieldValues> = ComponentProps<typeof Form<T>>;

type Prop<T extends FieldValues> = DynamicForm<T> &
  Pick<FormProps<T>, "onSubmit" | "onSubmitError" | "options"> & {
    submitText?: string;
    inputsPerRow?: number;
  };

export default function DialogForm<T extends FieldValues = FieldValues>(
  props: Prop<T>,
) {
  return (
    <ResponsiveDialogPortal>
      <ResponsiveDialogContent title={props.title}>
        <InnerDialogForm<T> {...props} />
      </ResponsiveDialogContent>
    </ResponsiveDialogPortal>
  );
}

function InnerDialogForm<T extends FieldValues = FieldValues>(props: Prop<T>) {
  const { isGrouped, customSchema, isSubmitting, inputsPerRow } = props;
  const { handleSetOpen } = useRootDialog();

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.stopPropagation();
    }
  };

  if (!isGrouped) {
    const {
      inputs,
      options,
      submitText = "Soumettre",
      onSubmit,
      onSubmitError,
      isSubmitting,
    } = props;
    const schema = customSchema || generateDynamicFormSchema(inputs);

    return (
      <Form
        onSubmit={onSubmit}
        onSubmitError={onSubmitError}
        options={options}
        resolverSchema={schema}
        onKeyDown={handleKeyDown}
      >
        <div
          className="grid gap-4 md:grid-cols-[repeat(var(--inputs-per-row),minmax(0,1fr))]"
          style={{
            "--inputs-per-row": inputsPerRow || 3,
          }}
        >
          {inputs.map((input) => (
            <DialogFormInput<T> key={input.name} input={input} />
          ))}
        </div>

        <div className="mt-8 flex gap-2 md:px-[20%]">
          <Button noFill onClick={() => handleSetOpen(false)}>
            Annuler
          </Button>
          <Button
            className="whitespace-nowrap"
            type="submit"
            isLoading={isSubmitting}
          >
            {submitText}
          </Button>
        </div>
      </Form>
    );
  }

  const {
    groups,
    submitText = "Soumettre",
    options,
    onSubmit,
    onSubmitError,
  } = props;

  const schema =
    customSchema ||
    generateDynamicFormSchema(flattenDynamicFormGroupsInputs(groups));

  return (
    <Form
      onSubmit={onSubmit}
      onSubmitError={onSubmitError}
      options={options}
      resolverSchema={schema}
      onKeyDown={handleKeyDown}
    >
      {groups.map((group) => {
        return (
          <DialogFormInputGroup<T>
            key={group.name}
            group={group}
            inputsPerRow={inputsPerRow}
          />
        );
      })}

      <div className="mt-8 flex gap-2 px-[20%]">
        <Button noFill onClick={() => handleSetOpen(false)}>
          Annuler
        </Button>
        <Button
          className="whitespace-nowrap"
          type="submit"
          isLoading={isSubmitting}
        >
          {submitText}
        </Button>
      </div>
    </Form>
  );
}
