import { useEffect, useRef, useState } from "react";

type UseThrottleOptions = {
  duration?: number;
  disabled?: boolean;
};

export default function useThrottle<T>(value: T, options?: UseThrottleOptions) {
  const { duration = 275, disabled = false } = options || {};

  const [throttledValue, setThrottledValue] = useState(value);
  const lastUpdated = useRef<number | null>(null);

  useEffect(() => {
    if (disabled) return;

    const now = Date.now();

    if (lastUpdated.current && now >= lastUpdated.current + duration) {
      lastUpdated.current = now;
      setThrottledValue(value);
    } else {
      const id = setTimeout(() => {
        lastUpdated.current = now;
        setThrottledValue(value);
      }, duration);

      return () => clearTimeout(id);
    }
  }, [value, disabled, duration]);

  return throttledValue;
}
