import { useRouter } from "@tanstack/react-router";
import { useEffect, useState } from "react";

import { useHeaderContext } from "$/lib/providers/HeaderProvider";
import { cn } from "$/lib/utils/functions/misc.functions";

import ArrowLeftIcon from "../icons/ArrowLeftIcon";
import BurgerMenuIcon from "../icons/BurgerMenuIcon";

export default function Header() {
  const { toggleSideBar, component, hasBackButton, onBackClick } =
    useHeaderContext();
  const [isScrolled, setIsScrolled] = useState(false);

  const router = useRouter();

  useEffect(() => {
    function handleScroll() {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <header
      className={cn(
        "fixed right-0 top-0 z-layout-min flex h-20 w-full items-center justify-between gap-4 border-b border-b-snow bg-snow px-2 py-2 duration-300 ease-in-out md:w-[calc(100%-250px)] md:px-10",
        isScrolled && "shadow-xl",
      )}
    >
      <div className="flex items-center md:hidden">
        <button
          type="button"
          className="m-4 text-primary md:hidden"
          onClick={toggleSideBar}
        >
          <BurgerMenuIcon />
        </button>
        {!!hasBackButton && (
          <button
            type="button"
            onClick={() => {
              if (onBackClick) {
                return onBackClick();
              }
              router.history.go(-1);
            }}
            className="rounded-xl bg-blue-light px-4 py-2 text-black md:hidden"
          >
            <ArrowLeftIcon width={18} />
          </button>
        )}
      </div>
      <div className="flex w-full items-center gap-5 ^md:pr-2">
        {!!hasBackButton && (
          <button
            type="button"
            onClick={() => {
              if (onBackClick) {
                return onBackClick();
              }
              router.history.go(-1);
            }}
            className="rounded-xl bg-blue-light px-4 py-3 text-black ^md:hidden"
          >
            <ArrowLeftIcon />
          </button>
        )}
        {typeof component === "string" ? (
          <h1 className="text-xl font-bold text-black">{component}</h1>
        ) : (
          component
        )}
      </div>
    </header>
  );
}
