import { type RefObject, useEffect } from "react";

const focusableElementsString =
  'a[href]:not([tabindex^="-"]), input:not([disabled]):not([tabindex^="-"]), select:not([disabled]):not([tabindex^="-"]), textarea:not([disabled]):not([tabindex^="-"]), button:not([disabled]):not([tabindex^="-"]), iframe:not([tabindex^="-"]), object:not([tabindex^="-"]), embed:not([tabindex^="-"]), [tabindex]:not([tabindex^="-"]), [contenteditable]:not([tabindex^="-"]), audio[controls]:not([tabindex^="-"]), video[controls]:not([tabindex^="-"])';

type UseFocusTrapOptions = {
  disabled?: boolean;
};

export default function useFocusTrap<T extends HTMLElement | null>(
  elementRef: RefObject<T>,
  options?: UseFocusTrapOptions,
) {
  const { disabled = false } = options || {};

  useEffect(() => {
    const element = elementRef.current;

    if (!element || disabled) return;

    const handleFocusNavigation = (e: KeyboardEvent) => {
      const focusableElements = Array.from(
        element.querySelectorAll(focusableElementsString),
      );

      if (focusableElements.length === 0) return e.preventDefault();

      const firstFocusableElement = focusableElements[0];
      const lastFocusableElement =
        focusableElements[focusableElements.length - 1];
      const activeElement = document.activeElement as HTMLElement | null;

      const activeElementInModal =
        !!activeElement && focusableElements.includes(activeElement);

      if (e.key === "Tab") {
        if (!activeElementInModal) {
          if (e.shiftKey) {
            (lastFocusableElement as HTMLElement).focus();
            e.preventDefault();
          } else {
            (firstFocusableElement as HTMLElement).focus();
            e.preventDefault();
          }
        }
        if (e.shiftKey) {
          if (activeElement === firstFocusableElement) {
            (lastFocusableElement as HTMLElement).focus();
            e.preventDefault();
          }
        } else {
          if (activeElement === lastFocusableElement) {
            (firstFocusableElement as HTMLElement).focus();
            e.preventDefault();
          }
        }
      }
    };

    element.addEventListener("keydown", handleFocusNavigation);

    return () => {
      element.removeEventListener("keydown", handleFocusNavigation);
    };
  }, [disabled, elementRef]);
}
