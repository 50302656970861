import type { ComponentProps } from "react";
import type { FieldValues, Path, PathValue } from "react-hook-form";
import { useFormContext } from "react-hook-form";

import { cn, getDeepFormError } from "$/lib/utils/functions/misc.functions";
import type { FormInput } from "$/types/misc.types";
import type { Primitive } from "$/types/util.types";

import ComboboxInput from "../../ui/inputs/combobox-input/ComboboxInput";

type Props<
  TFieldValues extends FieldValues,
  TOption extends Primitive,
> = FormInput<TFieldValues> & {
  hideLabel?: boolean;
  options: ComponentProps<typeof ComboboxInput<TOption>>["options"];
  isSearching?: boolean;
  multi?: boolean;
  onSearch?: (search: string) => void;
  clearable?: boolean;
};

export default function FormComboboxInput<
  TFieldValues extends FieldValues,
  TOption extends Primitive,
>({
  name,
  label,
  hideLabel,
  options,
  isSearching,
  multi,
  clearable,
  onSearch,
  disabled,
  ...registerOptions
}: Props<TFieldValues, TOption>) {
  const {
    register,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitted },
  } = useFormContext<TFieldValues>();

  const error = getDeepFormError(errors, name.split("."));

  const handleValueChange = (value: TOption) => {
    let newValue: TOption | TOption[] = value;

    if (multi) {
      const oldValues = getValues(name) as TOption[] | undefined;

      if (!oldValues) {
        newValue = [value];
      } else {
        if (oldValues.includes(value)) {
          newValue = oldValues.filter((o) => o !== value);
        } else {
          newValue = [...oldValues, value];
        }
      }
    }

    setValue(name, newValue as PathValue<TFieldValues, Path<TFieldValues>>, {
      shouldDirty: true,
      shouldValidate: isSubmitted,
    });
  };

  const value = watch(name) as TOption | undefined;
  const hasValue = multi
    ? !!(value as unknown as TOption[] | undefined)?.length
    : Boolean(value);

  const registerProps = register(name, {
    ...registerOptions,
    setValueAs: (value) => value || undefined,
  });

  const handleClear = () => {
    if (multi) {
      setValue(name, [] as PathValue<TFieldValues, Path<TFieldValues>>);
    } else {
      setValue(name, "" as PathValue<TFieldValues, Path<TFieldValues>>);
    }
  };

  return (
    <ComboboxInput<TOption>
      {...registerProps}
      // undefined here to avoid passing `false` to the component
      multi={multi as undefined}
      disabled={disabled}
      hideLabel={hideLabel}
      selected={value}
      onSelect={handleValueChange}
      onSearch={onSearch}
      options={options}
      placeholder={label}
      label={label}
      isSearching={isSearching}
      buttonClassName={cn(!hasValue && "text-grey-200")}
      onClear={clearable ? handleClear : undefined}
      error={error?.message?.toString()}
    />
  );
}
