import type { ComponentProps } from "react";

export default function WarningIcon(svgProps: ComponentProps<"svg">) {
  return (
    <svg
      width="71"
      height="70"
      viewBox="0 0 71 70"
      fill="#E20613"
      aria-label="Warning"
      {...svgProps}
    >
      <path d="M42.802 11.6665C42.0601 10.4346 41.0122 9.41532 39.7602 8.70778C38.5081 8.00024 37.0944 7.62842 35.6562 7.62842C34.2181 7.62842 32.8043 8.00024 31.5523 8.70778C30.3002 9.41532 29.2523 10.4346 28.5104 11.6665L5.52704 49.204C4.75636 50.4728 4.33693 51.9237 4.31175 53.4079C4.28658 54.8922 4.65656 56.3564 5.38378 57.6506C6.111 58.9447 7.16929 60.0222 8.45016 60.7726C9.73103 61.523 11.1884 61.9192 12.6729 61.9207H58.6395C60.124 61.9192 61.5814 61.523 62.8623 60.7726C64.1431 60.0222 65.2014 58.9447 65.9286 57.6506C66.6559 56.3564 67.0258 54.8922 67.0007 53.4079C66.9755 51.9237 66.5561 50.4728 65.7854 49.204L42.802 11.6665Z" />
      <path
        d="M35.6562 43.0205C35.0784 43.013 34.5264 42.7801 34.1178 42.3715C33.7092 41.9628 33.4763 41.4108 33.4688 40.833V27.708C33.4688 27.1278 33.6992 26.5714 34.1095 26.1612C34.5197 25.751 35.0761 25.5205 35.6562 25.5205C36.2364 25.5205 36.7928 25.751 37.203 26.1612C37.6133 26.5714 37.8438 27.1278 37.8438 27.708V40.833C37.8362 41.4108 37.6033 41.9628 37.1947 42.3715C36.7861 42.7801 36.2341 43.013 35.6562 43.0205Z"
        fill="white"
      />
      <path
        d="M35.6569 51.0413C37.2677 51.0413 38.5736 49.7355 38.5736 48.1247C38.5736 46.5138 37.2677 45.208 35.6569 45.208C34.0461 45.208 32.7402 46.5138 32.7402 48.1247C32.7402 49.7355 34.0461 51.0413 35.6569 51.0413Z"
        fill="white"
      />
    </svg>
  );
}
