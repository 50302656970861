import type { ComponentProps } from "react";

export default function XIcon(svgProps: ComponentProps<"svg">) {
  return (
    <svg
      viewBox="0 0 15 15"
      width="15"
      height="15"
      fill="currentColor"
      role="img"
      aria-label="X"
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.303721 13.2446C-0.0978379 13.6463 -0.097776 14.2973 0.303845 14.6988C0.705466 15.1004 1.35656 15.1004 1.75811 14.6987L7.50152 8.95434L13.2454 14.6982C13.647 15.0998 14.2981 15.0998 14.6997 14.6982C15.1012 14.2967 15.1012 13.6455 14.6997 13.244L8.95567 7.49998L14.6993 1.75539C15.1007 1.35377 15.1007 0.70268 14.699 0.301131C14.2975 -0.100428 13.6464 -0.100367 13.2448 0.301254L7.50132 6.04573L1.75741 0.301748C1.35583 -0.0998319 0.704725 -0.0998319 0.303146 0.301748C-0.0984444 0.703338 -0.0984444 1.35443 0.303146 1.75602L6.04727 7.50009L0.303721 13.2446Z"
      />
    </svg>
  );
}
