import { type ForwardedRef, type ReactNode, forwardRef } from "react";

import noResults from "$/assets/illustrations/no-results.svg";
import { valueOrNothing } from "$/lib/utils/functions/util.functions";

import type { ComboboxOption } from "./ComboboxInput";
import ComboboxInputOption from "./ComboboxInputOption";

type Props<T> = {
  id: string;
  options: ComboboxOption<T>[];
  focusedOptionIndex: number;
  multi: boolean;
  selected?: T | T[];
  handleSetFocusedOptionIndex: (index: number) => void;
  handleSelect: (option: T, index: number) => void;
};

function ComboboxInputOptionsListInner<T>(
  {
    id,
    options,
    focusedOptionIndex,
    multi,
    selected,
    handleSetFocusedOptionIndex,
    handleSelect,
  }: Props<T>,
  listRef: ForwardedRef<HTMLUListElement>,
) {
  if (options.length === 0) {
    return (
      <div className="flex h-56 flex-col items-center justify-center gap-2 pb-6">
        <img src={noResults} alt="No results found" className="size-32" />
        <p className="text-center text-sm text-grey-400">
          Aucun résultat trouvé
        </p>
      </div>
    );
  }

  return (
    <ul
      id={id}
      ref={listRef}
      role="listbox"
      aria-multiselectable={multi}
      aria-activedescendant={valueOrNothing(
        focusedOptionIndex !== -1,
        `option-${focusedOptionIndex}`,
      )}
      className="max-h-56 space-y-1 overflow-auto overscroll-contain pb-1 scrollbar-custom"
    >
      {options.map((option, i) => {
        let isSelected = false;
        if (selected) {
          if (multi) {
            if (!Array.isArray(selected)) {
              throw new Error("Multi select options must be an array");
            }
            isSelected = selected.includes(option.value);
          } else {
            isSelected = selected === option.value;
          }
        }

        return (
          <li
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            role="option"
            data-focused={focusedOptionIndex === i}
            aria-selected={isSelected}
            title={option.label}
            onMouseOver={() => handleSetFocusedOptionIndex(i)}
            tabIndex={-1}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSelect(option.value, i);
            }}
            className="rounded-md data-[focused=true]:bg-blue-light"
          >
            {option.render?.({
              value: option.value,
              label: option.label,
              isSelected,
              multi,
            }) ?? (
              <ComboboxInputOption
                label={option.label}
                isSelected={isSelected}
                multi={multi}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
}

const ComboboxInputOptionsList = forwardRef(ComboboxInputOptionsListInner) as {
  <T>(props: Props<T> & { ref?: ForwardedRef<HTMLUListElement> }): ReactNode;
  displayName: string;
};
ComboboxInputOptionsList.displayName = "ComboboxInputOptionsList";

export default ComboboxInputOptionsList;
