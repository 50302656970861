import type { ComponentProps, PropsWithChildren } from "react";

import type { KeyValueObject } from "$/types/util.types";

import { EnhancedTableDateRangeFilter } from ".";
import EnhancedTableExportToCSV from "./EnhancedTableExportToCSV";
import EnhancedTableSearchFilter from "./EnhancedTableSearchFilter";

type Props<TData extends KeyValueObject> = {
  searchable?: boolean;
  searchPlaceholder?: string;
  hasDatePicker?: boolean;
  hasExport?: boolean;
  exportProps?: ComponentProps<typeof EnhancedTableExportToCSV<TData>>;
};

// TODO: add other fixed buttons + required props
export default function EnhancedTableActionBar<
  TSelectedData extends KeyValueObject,
>({
  children,
  searchPlaceholder,
  hasDatePicker,
  hasExport,
  exportProps,
  searchable = true,
}: PropsWithChildren<Props<TSelectedData>>) {
  if (hasExport && !exportProps) {
    throw new Error("Export props not provided");
  }

  return (
    <div className="flex w-full flex-col items-center justify-between gap-4 rounded bg-snow px-4 py-2 md:items-start lg:flex-row lg:items-center">
      <div>
        {searchable && (
          <EnhancedTableSearchFilter placeHolder={searchPlaceholder} />
        )}
      </div>
      {/* wrapper for buttons */}
      <div className="flex flex-wrap gap-4 lg:justify-end">
        {hasDatePicker && <EnhancedTableDateRangeFilter />}

        {children}
        {!!hasExport && (
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          <EnhancedTableExportToCSV<TSelectedData> {...exportProps!} />
        )}
      </div>
    </div>
  );
}
