import type { ComponentProps } from "react";
import type { FieldValues } from "react-hook-form";

import FormSelectInput from "$/lib/components/common/form/FormSelectInput";

import ManagedFormCombobox from "../autocomplete/ManagedFormCombobox";
import {
  type DynamicFormInput,
  getDynamicFormInputComponent,
  getDynamicInputComponentProps,
} from "../dynamic-form";

type Props<T extends FieldValues> = {
  input: DynamicFormInput<T>;
};

export default function DialogFormInput<T extends FieldValues>({
  input,
}: Props<T>) {
  if (input.type === "select") {
    const selectProps = getDynamicInputComponentProps(input) as ComponentProps<
      typeof FormSelectInput
    >;

    return <FormSelectInput {...selectProps} />;
  }

  if (input.type === "combobox") {
    const comboboxProps = getDynamicInputComponentProps(
      input,
    ) as ComponentProps<typeof ManagedFormCombobox>;

    return <ManagedFormCombobox {...comboboxProps} />;
  }

  const InputComponent = getDynamicFormInputComponent(input);
  const inputProps = getDynamicInputComponentProps(input);

  return <InputComponent {...inputProps} />;
}
