import type { ComponentProps } from "react";

export default function ProfileIcon(svgProps: ComponentProps<"svg">) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      aria-label="Profile"
      {...svgProps}
    >
      <path
        d="M9 0C4.02996 0 0 4.02917 0 8.99961C0 13.97 4.02956 17.9992 9 17.9992C13.9708 17.9992 18 13.97 18 8.99961C18 4.02917 13.9708 0 9 0ZM9 2.69099C10.6446 2.69099 11.9772 4.02403 11.9772 5.66779C11.9772 7.31196 10.6446 8.6446 9 8.6446C7.35623 8.6446 6.02359 7.31196 6.02359 5.66779C6.02359 4.02403 7.35623 2.69099 9 2.69099ZM8.99802 15.6462C7.35781 15.6462 5.85557 15.0489 4.69687 14.0602C4.41461 13.8194 4.25173 13.4664 4.25173 13.096C4.25173 11.4289 5.60098 10.0947 7.26847 10.0947H10.7323C12.4002 10.0947 13.7443 11.4289 13.7443 13.096C13.7443 13.4668 13.5822 13.819 13.2996 14.0598C12.1413 15.0489 10.6386 15.6462 8.99802 15.6462Z"
        fill="#B8B8B8"
      />
    </svg>
  );
}
