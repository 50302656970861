import { restApiClient } from "$/lib/utils/clients/restApi.client";

import type {
  CreateCompanyAddressPayloadType,
  CreateCompanyContactPayloadType,
  CreateCompanyInput,
  CreateCompanyServicePayloadType,
  GetCompaniesAddressesListResponse,
  GetCompaniesListEntity,
  GetCompaniesListResponse,
  GetCompaniesResponse,
  GetCompanyAddressesListEntity,
  GetCompanyAddressesResponse,
  GetCompanyContactsResponse,
  GetCompanyEntity,
  GetCompanyServicesResponse,
  UpdateCompanyAddressPayloadType,
  UpdateCompanyInput,
} from "../types/company-apis.types";

export async function getCompanies(
  pagination?: { offset: number; limit: number },
  filters?: {
    search?: string;
  },
) {
  return restApiClient
    .url("/companies/all")
    .query({
      ...pagination,
      ...filters,
    })
    .get<GetCompaniesResponse>();
}

export async function getCompaniesList(
  pagination?: { offset: number; limit: number },
  filters?: {
    search?: string;
  },
  include: number[] = [],
) {
  return restApiClient.url("/companies/list").post<GetCompaniesListResponse>({
    ...pagination,
    ...filters,
    include,
  });
}

export async function getCompaniesAddressesList(
  pagination?: { offset: number; limit: number },
  filters?: {
    search?: string;
  },
) {
  return restApiClient
    .url("/companies/addresses/list")
    .query({
      ...pagination,
      ...filters,
    })
    .get<GetCompaniesAddressesListResponse>();
}

export async function createCompany(payload: CreateCompanyInput) {
  return await restApiClient.url("/companies").post<never>(payload);
}

export async function updateCompany(
  companyId: number,
  payload: UpdateCompanyInput,
) {
  return restApiClient.url(`/companies/${companyId}`).patch<never>(payload);
}

export async function deleteCompany(companyId: number) {
  return restApiClient.url(`/companies/${companyId}`).delete<never>();
}

// Single Company apis
export async function getCompanyDetails(
  companyId: number,
): Promise<GetCompanyEntity> {
  return restApiClient.url(`/companies/${companyId}`).get<GetCompanyEntity>();
}

// ---------------------------------------- Additional Services --------------------------------

export async function createCompanyService(
  companyId: number,
  payload: CreateCompanyServicePayloadType,
) {
  return await restApiClient
    .url(`/companies/${companyId}/services`)
    .post<never>(payload);
}

export async function deleteCompanyService({
  companyId,
  id,
}: {
  companyId: number;
  id: number;
}): Promise<never> {
  return restApiClient
    .url(`/companies/${companyId}/services/${id}`)
    .delete<never>();
}

export async function editCompanyService(
  companyId: number,
  id: number,
  payload: CreateCompanyServicePayloadType,
) {
  return restApiClient
    .url(`/companies/${companyId}/services/${id}`)
    .patch<never>(payload);
}

export async function getSingleCompanyServices(
  companyId: number,
  pagination?: { offset: number; limit: number },
  filters?: {
    search?: string;
  },
): Promise<GetCompanyServicesResponse> {
  return restApiClient
    .url(`/companies/${companyId}/services/all`)
    .query({ ...pagination, ...filters })
    .get<GetCompanyServicesResponse>();
}

// ---------------------------------------- Contacts --------------------------------

export async function createCompanyContact(
  companyId: number,
  payload: CreateCompanyContactPayloadType,
) {
  return await restApiClient
    .url(`/companies/${companyId}/contacts`)
    .post<never>(payload);
}

export async function deleteCompanyContact({
  companyId,
  id,
}: {
  companyId: number;
  id: number;
}): Promise<never> {
  return restApiClient
    .url(`/companies/${companyId}/contacts/${id}`)
    .delete<never>();
}

export async function editCompanyContacts(
  companyId: number,
  id: number,
  payload: CreateCompanyContactPayloadType,
) {
  return restApiClient
    .url(`/companies/${companyId}/contacts/${id}`)
    .patch<never>(payload);
}

export async function getCompanyContacts(
  companyId: number,
  pagination?: { offset: number; limit: number },
  filters?: {
    search?: string;
  },
): Promise<GetCompanyContactsResponse> {
  return restApiClient
    .url(`/companies/${companyId}/contacts/all`)
    .query({ ...pagination, ...filters })
    .get<GetCompanyContactsResponse>();
}

// ---------------------------------------- Address ----------------------------------------

export async function createCompanyAddress(
  companyId: number,
  payload: CreateCompanyAddressPayloadType,
) {
  return await restApiClient
    .url(`/companies/${companyId}/addresses`)
    .post<never>(payload);
}

export async function editCompanyAddress(
  companyId: number,
  id: number,
  payload: UpdateCompanyAddressPayloadType,
) {
  return restApiClient
    .url(`/companies/${companyId}/addresses/${id}`)
    .patch<never>(payload);
}

export async function deleteCompanyAddress({
  companyId,
  id,
}: {
  companyId: number;
  id: number;
}): Promise<never> {
  return restApiClient
    .url(`/companies/${companyId}/addresses/${id}`)
    .delete<never>();
}

export async function getCompanyAddresses(
  companyId: number,
  pagination?: { offset: number; limit: number },
  filters?: {
    search?: string;
  },
): Promise<GetCompanyAddressesResponse> {
  return restApiClient
    .url(`/companies/${companyId}/addresses/all`)
    .query({ ...pagination, ...filters })
    .get<GetCompanyAddressesResponse>();
}

export async function getCompanyAddressesList(
  companyId = 1,
  pagination?: { offset: number; limit: number },
  filters?: {
    search: string;
  },
  include: number[] = [],
): Promise<GetCompanyAddressesListEntity[]> {
  return restApiClient
    .url(`/companies/${companyId}/addresses/list`)
    .post({ ...pagination, ...filters, include });
}

export async function getCompanyList(
  pagination?: { offset: number; limit: number },
  filters?: {
    search?: string;
  },
) {
  return restApiClient
    .url(`/companies/list`)
    .query({ ...pagination, ...filters })
    .get<GetCompaniesListEntity[]>();
}
