import { forwardRef, useId } from "react";

import { cn } from "$/lib/utils/functions/misc.functions";
import type { BaseInput } from "$/types/misc.types";

import CalendarIcon from "../../icons/CalendarIcon";
import InputError from "./InputError";

// TODO: custom date input
const DatetimeInput = forwardRef<HTMLInputElement, BaseInput>(
  ({ id, label, hideLabel, error, name, className, ...inputProps }, ref) => {
    const innerId = useId();
    const inputId = id || innerId;
    const labelHtmlFor = name || inputId;

    return (
      <div className="w-full">
        <label
          htmlFor={labelHtmlFor}
          className={cn(
            "mb-1 ml-2 block text-xs text-black",
            hideLabel && "hidden",
          )}
        >
          {label}
        </label>
        <div className="relative w-full">
          <input
            id={inputId}
            type="datetime-local"
            aria-invalid={!!error}
            name={name}
            ref={ref}
            className={cn(
              "min-h-12 w-full rounded-lg border-2 border-blue-light bg-blue-light px-2 text-sm outline-none duration-150 placeholder:text-grey-200 focus:border-primary/30 disabled:cursor-not-allowed disabled:opacity-50 aria-invalid:border-error aria-invalid:focus:border-error",
              className,
            )}
            {...inputProps}
          />

          <CalendarIcon className="pointer-events-none absolute bottom-4 right-3 bg-blue-light" />
        </div>

        {!!error && <InputError errorMessage={error} />}
      </div>
    );
  },
);

DatetimeInput.displayName = "DatetimeInput";

export default DatetimeInput;
