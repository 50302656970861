import * as Sentry from "@sentry/react";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { setDefaultOptions } from "date-fns";
import { fr } from "date-fns/locale";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { ZodIssueCode, z } from "zod";

import PageLoader from "./lib/components/loaders/page-loader";
import ErrorPage from "./lib/components/ui/ErrorPage";
import NotFound from "./lib/components/ui/NotFound";
import "./lib/styles/globals.scss";
import { routeTree } from "./routeTree.gen";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["https://parnass360.com"],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const router = createRouter({
  routeTree,
  defaultPendingMs: 0,
  defaultPreload: "intent",
  defaultPendingComponent: PageLoader,
  defaultNotFoundComponent: NotFound,
  defaultErrorComponent: ErrorPage,
});

declare module "react" {
  export interface CSSProperties {
    [key: `--${string}`]: string | number;
  }
}

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

setDefaultOptions({ locale: fr, weekStartsOn: 1 });

z.setErrorMap((issue, ctx) => {
  if (issue.code === ZodIssueCode.invalid_type) {
    // handle required errors (Zod reports a missing field as an "invalid_type" error)
    if (issue.received === "undefined") {
      return { message: "Veuillez remplir ce champ" };
    }
  }

  // Use default message for other errors
  return { message: ctx.defaultError };
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>,
);
