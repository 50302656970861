import type { ComponentProps } from "react";

export default function VehicleDamageIcon(svgProps: ComponentProps<"svg">) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      aria-label="Vehicle Damage"
      {...svgProps}
    >
      <path d="M11.1223 4.08484C11.1655 4.10122 11.2095 4.10942 11.2533 4.10942C11.3967 4.10942 11.5324 4.02283 11.588 3.88125L12.1514 2.44325C12.2239 2.2584 12.1326 2.0501 11.9477 1.97758C11.7623 1.90617 11.554 1.99628 11.4821 2.18117L10.9187 3.6192C10.8468 3.80401 10.9375 4.01228 11.1223 4.08484Z" />
      <path d="M9.08558 3.88032C9.14116 4.02193 9.27693 4.10848 9.42026 4.10848C9.46414 4.10848 9.50805 4.10029 9.55125 4.08391C9.73611 4.01134 9.82741 3.80308 9.75488 3.61823L9.19154 2.1802C9.11957 1.99534 8.91071 1.90756 8.72586 1.97661C8.541 2.04913 8.45027 2.25743 8.52223 2.44228L9.08558 3.88032Z" />
      <path d="M4.25217 12.8516C3.37228 12.8516 2.65625 13.5677 2.65625 14.4476C2.65625 14.5201 2.65977 14.5915 2.67263 14.6594C2.78612 15.455 3.46418 16.0436 4.2522 16.0436C5.0409 16.0436 5.71839 15.455 5.82889 14.6758C5.84468 14.5915 5.84763 14.519 5.84763 14.4476C5.84759 13.5677 5.13202 12.8516 4.25217 12.8516Z" />
      <path d="M17.1563 10.5531L15.8774 9.91068C15.5902 9.76324 15.362 9.53627 15.2158 9.25077L14.0129 6.84509C13.8837 6.59004 13.6233 6.42972 13.3349 6.42972H11.4937L11.0725 7.69341L12.0922 9.22271C12.173 9.34323 12.173 9.50002 12.0922 9.62054L11.0018 11.2563L10.4582 12.8862C10.415 13.0161 10.3014 13.1109 10.1651 13.1284C10.0288 13.1483 9.89487 13.0875 9.81823 12.9716L8.70075 11.2961C8.62003 11.1744 8.62059 11.0152 8.70314 10.8947L9.47422 9.76559L8.15379 7.99994C8.04677 7.85721 8.0608 7.6571 8.18712 7.53075L8.87632 6.84158L8.32286 6.42969H6.90532C6.61813 6.42969 6.34957 6.59584 6.22146 6.85093L5.0818 9.12557C4.88876 9.5105 4.53836 9.79017 4.11996 9.89314L0.993516 10.6748C0.656438 10.759 0.421875 11.0609 0.421875 11.4107V13.6666C0.421875 14.0539 0.737262 14.3687 1.12395 14.3687H1.94593C1.98914 13.1303 3.00364 12.135 4.25211 12.135C5.50058 12.135 6.51505 13.1303 6.55836 14.3687H10.8829C10.9268 13.1303 11.9413 12.135 13.1898 12.135C14.4382 12.135 15.4526 13.1303 15.496 14.3687H16.8767C17.2634 14.3687 17.5781 14.054 17.5781 13.6666V11.2376C17.5781 10.9498 17.4126 10.6818 17.1563 10.5531Z" />
      <path d="M13.1897 12.8516C12.3098 12.8516 11.5942 13.5677 11.5942 14.4476C11.5942 14.5201 11.5972 14.5915 11.61 14.6594C11.7236 15.455 12.4016 16.0436 13.1897 16.0436C13.9777 16.0436 14.6558 15.455 14.7664 14.6758C14.7821 14.5915 14.785 14.519 14.785 14.4476C14.7851 13.5677 14.0696 12.8516 13.1897 12.8516Z" />
    </svg>
  );
}
