export const CNRIndexTypeEnum = {
  GAS: "GAS",
  ELECTRIC: "ELECTRIC",
  GAZOLINE_AVERAGE_MONTHLY_TANK: "GAZOLINE_AVERAGE_MONTHLY_TANK",
  GAZOLINE_END_OF_MONTH_TANK: "GAZOLINE_END_OF_MONTH_TANK",
  GAZOLINE_AVERAGE_MONTHLY_PUMP: "GAZOLINE_AVERAGE_MONTHLY_PUMP",
  GAZOLINE_END_OF_MONTH_PUMP: "GAZOLINE_END_OF_MONTH_PUMP",
} as const;
export type CNRIndexTypeEnum =
  (typeof CNRIndexTypeEnum)[keyof typeof CNRIndexTypeEnum];

export type CNRIndex = {
  id: number;
  type: CNRIndexTypeEnum;
  index: number;
  pricePerLitre: number;
  date: string;
};
