import { forwardRef, useId } from "react";

import { cn } from "$/lib/utils/functions/misc.functions";
import type { BaseInput } from "$/types/misc.types";

import InputError from "./InputError";

// TODO: custom color input
const ColorInput = forwardRef<HTMLInputElement, BaseInput>(
  ({ id, label, hideLabel, error, name, className, ...inputProps }, ref) => {
    const innerId = useId();
    const inputId = id || innerId;
    const labelHtmlFor = name || inputId;

    return (
      <div className="w-full">
        <label
          htmlFor={labelHtmlFor}
          className={cn(
            "mb-1 ml-2 block text-xs text-black",
            hideLabel && "hidden",
          )}
        >
          {label}
        </label>
        <input
          id={inputId}
          type="color"
          name={name}
          ref={ref}
          className={cn(
            "min-h-12 w-full rounded-lg border-2 border-blue-light bg-blue-light px-2 py-2.5 text-sm outline-none duration-150 placeholder:text-grey-200 focus:border-primary/30 disabled:cursor-not-allowed disabled:opacity-50 aria-invalid:border-error aria-invalid:focus:border-error",
            className,
          )}
          {...inputProps}
        />

        {!!error && <InputError errorMessage={error} />}
      </div>
    );
  },
);

ColorInput.displayName = "ColorInput";

export default ColorInput;
