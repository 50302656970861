import { forwardRef, useId } from "react";

import { cn } from "$/lib/utils/functions/misc.functions";
import type { BaseInput } from "$/types/misc.types";

import InputError from "./InputError";

type Props = BaseInput<"autoComplete"> & {
  autoComplete?: boolean;
  suggestions?: string[];
};

const TextInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      label,
      hideLabel,
      error,
      name,
      autoComplete,
      suggestions,
      className,
      ...inputProps
    },
    ref,
  ) => {
    const innerId = useId();
    const inputId = id || innerId;
    const labelHtmlFor = name || inputId;

    return (
      <div className="w-full">
        <label
          htmlFor={labelHtmlFor}
          className={cn(
            "mb-1 ml-2 block text-xs text-black",
            hideLabel && "hidden",
          )}
        >
          {label}
        </label>
        <input
          id={inputId}
          type="text"
          aria-invalid={!!error}
          autoComplete={
            autoComplete === undefined || autoComplete ? "on" : "off"
          }
          name={name}
          ref={ref}
          list={suggestions ? `${inputId}-suggestions` : undefined}
          className={cn(
            "min-h-12 w-full rounded-lg border-2 border-blue-light bg-blue-light px-2 text-sm outline-none duration-150 placeholder:text-grey-200 focus:border-primary/30 disabled:cursor-not-allowed disabled:opacity-50 aria-invalid:border-error aria-invalid:focus:border-error",
            className,
          )}
          {...inputProps}
        />
        {!!suggestions && (
          <datalist id={`${inputId}-suggestions`}>
            {suggestions.map((suggestion) => (
              <option key={suggestion} value={suggestion} />
            ))}
          </datalist>
        )}
        {!!error && <InputError errorMessage={error} />}
      </div>
    );
  },
);

TextInput.displayName = "TextInput";

export default TextInput;
