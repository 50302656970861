import type { ComponentProps } from "react";

export default function BurgerMenuIcon(svgProps: ComponentProps<"svg">) {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="currentColor"
      aria-label="BurgerMenu"
      {...svgProps}
    >
      <rect width="21" height="4.28571" rx="2.14286" />
      <rect y="6.85742" width="21" height="4.28571" rx="2.14286" />
      <rect y="13.7139" width="21" height="4.28571" rx="2.14286" />
    </svg>
  );
}
