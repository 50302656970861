export const UserRoleEnum = {
  ADMIN: "ADMIN",
  SALES: "SALES",
  DRIVER: "DRIVER",
  CONCEPTOR: "CONCEPTOR",
  OPERATOR: "OPERATOR",
} as const;
export type UserRoleEnum = (typeof UserRoleEnum)[keyof typeof UserRoleEnum];

export const UserRoleWithoutDriverEnum = Object.fromEntries(
  Object.entries(UserRoleEnum).filter(
    ([_, value]) => value !== UserRoleEnum.DRIVER,
  ),
) as Omit<typeof UserRoleEnum, "DRIVER">;
export type UserRoleWithoutDriverEnum =
  (typeof UserRoleWithoutDriverEnum)[keyof typeof UserRoleWithoutDriverEnum];

export type User = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  role: UserRoleEnum;
  deactivated: boolean;
};
