import { type ComponentProps, useId } from "react";

export default function ResourcesIcon(svgProps: ComponentProps<"svg">) {
  const pathId = useId();
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      aria-label="Resources"
      {...svgProps}
    >
      <g clipPath={`url(#${pathId})`}>
        <path d="M16.9667 12.6895C18.6527 8.62653 16.6457 3.65672 12.6143 1.89703C12.5758 1.87919 12.5341 1.86913 12.4918 1.86744C12.4494 1.86575 12.4071 1.87246 12.3673 1.88718C12.3275 1.9019 12.291 1.92435 12.26 1.95322C12.2289 1.9821 12.2038 2.01683 12.1862 2.05543C12.1686 2.09403 12.1588 2.13572 12.1574 2.17812C12.156 2.22051 12.163 2.26277 12.1779 2.30246C12.1929 2.34215 12.2156 2.37849 12.2447 2.40938C12.2737 2.44027 12.3086 2.4651 12.3473 2.48246C13.7088 3.10249 14.8635 4.10067 15.6739 5.35818C16.4843 6.61569 16.9163 8.07958 16.9185 9.57559C16.9193 10.5433 16.7382 11.5025 16.3845 12.4032C16.026 12.316 15.6478 12.3642 15.3227 12.5385C14.9975 12.7128 14.7481 13.0011 14.6223 13.348C14.4966 13.6949 14.5033 14.076 14.6411 14.4182C14.779 14.7605 15.0384 15.0398 15.3695 15.2026C15.7006 15.3654 16.0802 15.4002 16.4354 15.3005C16.7906 15.2007 17.0965 14.9733 17.2944 14.6619C17.4924 14.3505 17.5684 13.977 17.5079 13.613C17.4474 13.249 17.2547 12.9202 16.9667 12.6895Z" />
        <path d="M1.05283 10.6012C1.57231 10.5591 1.29714 9.90988 1.34559 9.57185C1.34785 7.73381 1.99904 5.9556 3.18432 4.5508C4.3696 3.14599 6.01285 2.20481 7.82429 1.89325C7.9232 2.24083 8.14451 2.54089 8.44736 2.73807C8.75021 2.93524 9.11416 3.01622 9.47204 2.96604C9.82992 2.91587 10.1576 2.73793 10.3945 2.46508C10.6315 2.19223 10.7617 1.84287 10.7613 1.48149C10.7276 -0.350012 8.09272 -0.547787 7.78568 1.24993C5.68559 1.59439 3.79282 2.71862 2.48559 4.39797C1.17837 6.07732 0.552977 8.1881 0.734385 10.3085C0.740954 10.3884 0.77737 10.4629 0.836383 10.5171C0.895396 10.5714 0.972675 10.6014 1.05283 10.6012Z" />
        <path d="M13.5063 16.0114C11.8852 17.1137 9.90775 17.5634 7.96941 17.2706C6.03107 16.9778 4.27467 15.9641 3.05145 14.4323C3.29432 14.1556 3.42645 13.799 3.42249 13.4308C3.41853 13.0627 3.27875 12.709 3.02998 12.4376C2.78121 12.1661 2.44098 11.9962 2.07457 11.9603C1.70816 11.9243 1.34139 12.025 1.04465 12.2429C0.747907 12.4608 0.542095 12.7807 0.466711 13.141C0.391326 13.5014 0.45168 13.8769 0.636189 14.1955C0.820698 14.5141 1.11637 14.7533 1.46645 14.8673C1.81653 14.9812 2.19637 14.9619 2.53307 14.813C3.85484 16.4793 5.75815 17.5839 7.86075 17.9048C9.96335 18.2257 12.1095 17.7392 13.8682 16.543C13.9369 16.4942 13.9837 16.4205 13.9986 16.3376C14.0135 16.2547 13.9953 16.1693 13.9479 16.0997C13.9005 16.0301 13.8277 15.9818 13.7451 15.9653C13.6626 15.9488 13.5768 15.9654 13.5063 16.0114Z" />
        <path d="M8.9986 4.844C6.95912 4.81505 5.81714 7.32418 7.16178 8.83288C7.39064 9.09504 7.67297 9.30518 7.9898 9.44916C8.30662 9.59314 8.65059 9.66764 8.9986 9.66764C9.3466 9.66764 9.69057 9.59314 10.0074 9.44916C10.3242 9.30518 10.6066 9.09504 10.8354 8.83288C12.1704 7.32418 11.0349 4.81505 8.9986 4.844Z" />
        <path d="M10.9415 9.61814C10.6258 9.87442 10.2624 10.0657 9.87238 10.1808C9.48237 10.296 9.07341 10.3328 8.6691 10.2891C8.26479 10.2455 7.87312 10.1222 7.51668 9.92641C7.16025 9.73063 6.8461 9.46622 6.59236 9.14844C6.0865 9.48228 5.65488 9.91681 5.32445 10.4249C4.99402 10.933 4.77187 11.5038 4.67187 12.1015C4.65158 12.2282 4.65886 12.3578 4.69322 12.4814C4.72758 12.605 4.78821 12.7197 4.87097 12.8178C4.95374 12.9158 5.05668 12.9948 5.17277 13.0494C5.28887 13.104 5.41537 13.1329 5.54366 13.1341H12.4502C12.5788 13.1334 12.7057 13.1049 12.8222 13.0505C12.9387 12.9961 13.042 12.9171 13.1252 12.819C13.2083 12.721 13.2692 12.606 13.3037 12.4822C13.3382 12.3583 13.3456 12.2285 13.3252 12.1015C13.2237 11.5038 13.0006 10.9334 12.6697 10.4254C12.3388 9.91747 11.9072 9.48285 11.4015 9.14848C11.2665 9.32204 11.1122 9.47961 10.9415 9.61814Z" />
      </g>
      <defs>
        <clipPath id={pathId}>
          <rect width="18" height="18" />
        </clipPath>
      </defs>
    </svg>
  );
}
