import type { ComponentProps } from "react";

export default function CommercialIcon(svgProps: ComponentProps<"svg">) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      aria-label="Commercial"
      {...svgProps}
    >
      <path d="M2.30133 6.84035C3.33984 7.22285 4.64062 7.47 6.04688 7.55859C7.16625 5.29102 9.50273 3.72656 12.1992 3.72656C12.9164 3.72615 13.6292 3.83862 14.3114 4.05984C14.1005 3.51316 13.5155 2.88738 12.1127 2.37234C10.793 1.88473 9.05203 1.61719 7.20703 1.61719C5.36203 1.61719 3.62109 1.88473 2.30133 2.37059C0.399375 3.07125 0 3.97266 0 4.60547C0 5.23828 0.399375 6.13969 2.30133 6.84035Z" />
      <path d="M2.30133 11.2377C3.19676 11.5675 4.2873 11.7967 5.47277 11.9099C5.25565 10.8057 5.31324 9.66504 5.64047 8.58832C4.26129 8.47547 2.98195 8.21813 1.93676 7.83281C1.13238 7.53645 0.484102 7.17469 0 6.75V9.00281C0 9.63563 0.399375 10.537 2.30133 11.2377Z" />
      <path d="M5.78109 12.9898C4.34848 12.8844 3.01746 12.6218 1.93676 12.2234C1.13238 11.9271 0.484102 11.5653 0 11.1406V13.3934C0 14.0262 0.399375 14.9277 2.30133 15.6283C3.62109 16.1142 5.36203 16.3817 7.20703 16.3817C7.63875 16.3817 8.06484 16.367 8.48145 16.3381C7.2471 15.5388 6.30086 14.3655 5.78109 12.9898Z" />
      <path d="M12.1992 4.78125C9 4.78125 6.39844 7.38281 6.39844 10.582C6.39844 13.7812 9 16.3828 12.1992 16.3828C15.3984 16.3828 18 13.7812 18 10.582C18 7.38281 15.3984 4.78125 12.1992 4.78125ZM11.7703 10.0547H12.6281C13.0689 10.0598 13.4906 10.2352 13.805 10.5442C14.1194 10.8532 14.302 11.2719 14.3147 11.7125C14.3275 12.1531 14.1693 12.5816 13.8732 12.9082C13.5772 13.2348 13.1663 13.4343 12.7266 13.4648V13.7461C12.7266 13.886 12.671 14.0201 12.5721 14.119C12.4732 14.2179 12.3391 14.2734 12.1992 14.2734C12.0594 14.2734 11.9252 14.2179 11.8263 14.119C11.7274 14.0201 11.6719 13.886 11.6719 13.7461V13.4691H10.5905C10.4506 13.4691 10.3165 13.4135 10.2176 13.3146C10.1187 13.2157 10.0631 13.0816 10.0631 12.9417C10.0631 12.8019 10.1187 12.6677 10.2176 12.5688C10.3165 12.4699 10.4506 12.4144 10.5905 12.4144H12.6295C12.8026 12.4144 12.9686 12.3456 13.0909 12.2233C13.2133 12.1009 13.282 11.9349 13.282 11.7619C13.282 11.5888 13.2133 11.4229 13.0909 11.3005C12.9686 11.1781 12.8026 11.1094 12.6295 11.1094H11.7703C11.3295 11.1042 10.9078 10.9288 10.5934 10.6198C10.2791 10.3108 10.0964 9.89219 10.0837 9.45156C10.071 9.01094 10.2292 8.58247 10.5252 8.25584C10.8212 7.92922 11.2321 7.72976 11.6719 7.69922V7.41797C11.6719 7.27811 11.7274 7.14398 11.8263 7.04508C11.9252 6.94618 12.0594 6.89062 12.1992 6.89062C12.3391 6.89062 12.4732 6.94618 12.5721 7.04508C12.671 7.14398 12.7266 7.27811 12.7266 7.41797V7.695H13.808C13.9478 7.695 14.082 7.75056 14.1809 7.84946C14.2798 7.94835 14.3353 8.08248 14.3353 8.22234C14.3353 8.3622 14.2798 8.49634 14.1809 8.59523C14.082 8.69413 13.9478 8.74969 13.808 8.74969H11.7689C11.5959 8.74969 11.4299 8.81843 11.3075 8.9408C11.1852 9.06317 11.1164 9.22913 11.1164 9.40219C11.1164 9.57524 11.1852 9.74121 11.3075 9.86357C11.4299 9.98594 11.5959 10.0547 11.7689 10.0547H11.7703Z" />
    </svg>
  );
}
