import { type ComponentProps, forwardRef, useId } from "react";
import TextAreaAutoResize from "react-textarea-autosize";

import { cn } from "$/lib/utils/functions/misc.functions";

import InputError from "./InputError";

type Props = ComponentProps<typeof TextAreaAutoResize> & {
  label: string;
  hideLabel?: boolean;
  error?: string;
};

const TextAreaInput = forwardRef<HTMLTextAreaElement, Props>(
  ({ id, label, hideLabel, error, name, className, ...inputProps }, ref) => {
    const innerId = useId();
    const inputId = id || innerId;
    const labelHtmlFor = name || inputId;

    return (
      <div className="w-full">
        <label
          htmlFor={labelHtmlFor}
          className={cn(
            "mb-1 ml-2 block text-xs text-black",
            hideLabel && "hidden",
          )}
        >
          {label}
        </label>
        <TextAreaAutoResize
          name={name}
          minRows={Math.min(4, inputProps.minRows || 4)}
          ref={ref}
          aria-invalid={!!error}
          id={id}
          className={cn(
            "max-h-72 min-h-28 w-full rounded-lg border-2 border-blue-light bg-blue-light px-2 py-3 pr-4 text-sm outline-none transition-colors duration-150 placeholder:text-grey-200 focus:border-primary/30 disabled:cursor-not-allowed disabled:opacity-50 aria-invalid:border-error aria-invalid:focus:border-error",
            className,
          )}
          {...inputProps}
        />

        {!!error && <InputError errorMessage={error} />}
      </div>
    );
  },
);

TextAreaInput.displayName = "TextAreaInput";

export default TextAreaInput;
