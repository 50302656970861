import { type ComponentProps, useId } from "react";

export default function ClientsIcon(svgProps: ComponentProps<"svg">) {
  const pathId = useId();

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      aria-label="Clients"
      {...svgProps}
    >
      <g clipPath={`url(#${pathId})`}>
        <path d="M3.12891 11.3906C4.28903 11.3906 5.22949 10.4502 5.22949 9.29004C5.22949 8.12992 4.28903 7.18945 3.12891 7.18945C1.96878 7.18945 1.02832 8.12992 1.02832 9.29004C1.02832 10.4502 1.96878 11.3906 3.12891 11.3906Z" />
        <path d="M14.8008 11.3906C15.9609 11.3906 16.9014 10.4502 16.9014 9.29004C16.9014 8.12992 15.9609 7.18945 14.8008 7.18945C13.6407 7.18945 12.7002 8.12992 12.7002 9.29004C12.7002 10.4502 13.6407 11.3906 14.8008 11.3906Z" />
        <path d="M13.4438 11.7183C13.9689 12.5379 14.2737 13.5116 14.2737 14.5552V16.6646H17.4729C17.7641 16.6646 18.0002 16.4285 18.0002 16.1372V14.5552C18.0002 12.1997 15.5123 10.6864 13.4438 11.7183Z" />
        <path d="M0 14.5959V16.1376C0 16.4288 0.236109 16.6649 0.527344 16.6649H3.72656V14.5555C3.72656 13.5107 4.03197 12.536 4.55822 11.7157C2.45531 10.6791 0 12.2332 0 14.5959Z" />
        <path d="M9 10.3359C6.67005 10.3359 4.78125 12.2247 4.78125 14.5547V16.6641C4.78125 16.9553 5.01736 17.1914 5.30859 17.1914H12.6914C12.9826 17.1914 13.2188 16.9553 13.2188 16.6641V14.5547C13.2188 12.2247 11.3299 10.3359 9 10.3359Z" />
        <path d="M8.11738 3.8182C8.32329 4.02414 8.65721 4.02418 8.86315 3.8182L10.9725 1.70882C11.1785 1.50288 11.1785 1.169 10.9725 0.963053C10.7666 0.757107 10.4327 0.757107 10.2268 0.963053L8.49025 2.69953L7.77328 1.98258C7.56737 1.77664 7.23345 1.77664 7.02751 1.98258C6.82156 2.18853 6.82156 2.52241 7.02751 2.72835L8.11738 3.8182Z" />
        <path d="M9 10.3359C10.4562 10.3359 11.6367 9.15544 11.6367 7.69922C11.6367 6.243 10.4562 5.0625 9 5.0625C7.54378 5.0625 6.36328 6.243 6.36328 7.69922C6.36328 9.15544 7.54378 10.3359 9 10.3359Z" />
      </g>
      <defs>
        <clipPath id={pathId}>
          <rect width="18" height="18" />
        </clipPath>
      </defs>
    </svg>
  );
}
