import { format as formatDate, isValid as isValidDate } from "date-fns";
import { useMemo } from "react";
import type { FieldValues, Path, PathValue } from "react-hook-form";
import { useFormContext } from "react-hook-form";

import { getTimeFromDate } from "$/lib/utils/functions/date.functions";
import {
  getDeepFormDefaultValue,
  getDeepFormError,
} from "$/lib/utils/functions/misc.functions";
import type { FormInput } from "$/types/misc.types";

import TimeInput from "../../ui/inputs/TimeInput";

type Props<T extends FieldValues> = FormInput<T> & {
  placeholder?: string;
  className?: string;
  hideLabel?: boolean;
  readonly?: boolean;
};

export default function FormTimeInput<T extends FieldValues>({
  name,
  label,
  hideLabel,
  placeholder,
  className,
  readonly,
  ...registerOptions
}: Props<T>) {
  const {
    register,
    setValue,
    formState: { errors, defaultValues },
  } = useFormContext<T>();
  const error = getDeepFormError(errors, name.split("."));

  const defaultValue = useMemo(() => {
    let innerDefaultValue = getDeepFormDefaultValue(
      defaultValues,
      name.split("."),
    ) as string | undefined;

    formatDate(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", {});

    if (innerDefaultValue) {
      if (isValidDate(new Date(innerDefaultValue))) {
        innerDefaultValue = getTimeFromDate(innerDefaultValue);
      }
    }

    setValue(name, innerDefaultValue as PathValue<T, Path<T>>);

    return innerDefaultValue;

    // no need to re-run this effect as defaultValues is not expected to change and we only want to run this once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TimeInput
      {...register(name, registerOptions)}
      defaultValue={defaultValue}
      label={label}
      hideLabel={hideLabel}
      error={error?.message?.toString()}
      placeholder={placeholder}
      readOnly={readonly}
      className={className}
    />
  );
}
