import type { ComponentProps } from "react";

export default function PlusIcon(svgProps: ComponentProps<"svg">) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="currentColor"
      aria-label="Plus"
      {...svgProps}
    >
      <path d="M7 0C6.69058 0 6.39383 0.122917 6.17504 0.341709C5.95625 0.560501 5.83333 0.857247 5.83333 1.16667V5.83333H1.16667C0.857247 5.83333 0.560501 5.95625 0.341709 6.17504C0.122917 6.39383 0 6.69058 0 7C0 7.30942 0.122917 7.60616 0.341709 7.82496C0.560501 8.04375 0.857247 8.16667 1.16667 8.16667H5.83333V12.8333C5.83333 13.1428 5.95625 13.4395 6.17504 13.6583C6.39383 13.8771 6.69058 14 7 14C7.30942 14 7.60616 13.8771 7.82496 13.6583C8.04375 13.4395 8.16667 13.1428 8.16667 12.8333V8.16667H12.8333C13.1428 8.16667 13.4395 8.04375 13.6583 7.82496C13.8771 7.60616 14 7.30942 14 7C14 6.69058 13.8771 6.39383 13.6583 6.17504C13.4395 5.95625 13.1428 5.83333 12.8333 5.83333H8.16667V1.16667C8.16667 0.857247 8.04375 0.560501 7.82496 0.341709C7.60616 0.122917 7.30942 0 7 0Z" />
    </svg>
  );
}
