import { type PropsWithChildren, useState } from "react";

import {
  DialogForm,
  DialogFormRoot,
  DialogFormTrigger,
} from "$/features/common/dialog-form";
import { formatObjectDateFields } from "$/lib/utils/functions/date.functions";
import type { MutationFormProps } from "$/types/misc.types";

import { useMutateDeliveryRequest } from "../hooks/useMutateDeliveryRequest";
import type {
  CreateDeliveryRequestPayloadType,
  GetDeliveryRequestEntity,
} from "../types/delivery-request-apis.types";
import {
  deliveryRequestFormInputFieldsGroups,
  deliveryRequestFormInputFieldsSchema,
} from "../utils/delivery-request.constants";

type Props = MutationFormProps<GetDeliveryRequestEntity>;

export default function MutateDeliveryRequestDialogForm({
  id,
  children,
  defaultValues,
}: PropsWithChildren<Props>) {
  const [open, setOpen] = useState(false);

  const { mutate, isPending } = useMutateDeliveryRequest({ id });

  const isEditing = !!id;

  const inputGroups = isEditing
    ? deliveryRequestFormInputFieldsGroups.map((group) => ({
        ...group,
        inputs: group.inputs.filter(
          (input) => input.name !== "clientId" && input.name !== "vehicleType",
        ),
      }))
    : deliveryRequestFormInputFieldsGroups;

  return (
    <DialogFormRoot open={open} handleSetOpen={(newOpen) => setOpen(newOpen)}>
      <DialogFormTrigger>{children}</DialogFormTrigger>

      <DialogForm<CreateDeliveryRequestPayloadType>
        options={{
          defaultValues:
            defaultValues &&
            formatObjectDateFields<GetDeliveryRequestEntity>(defaultValues, {
              date: ["startDate", "endDate"],
            }),
        }}
        title={`${isEditing ? "Editer" : "Ajouter"} un cahier des charges`}
        isSubmitting={isPending}
        isGrouped
        groups={inputGroups}
        // @ts-expect-error FIXME: customSchema is not a valid prop
        customSchema={deliveryRequestFormInputFieldsSchema}
        onSubmit={(data) => {
          mutate(data, {
            onSuccess: () => setOpen(false),
          });
        }}
      />
    </DialogFormRoot>
  );
}
