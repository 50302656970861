import { type ComponentProps, useId } from "react";

export default function TrailerIcon(svgProps: ComponentProps<"svg">) {
  const pathId = useId();
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      aria-label="Trailer"
      {...svgProps}
    >
      <g clipPath={`url(#${pathId})`}>
        <path d="M1.05887 2.51562H11.6471C12.2319 2.51562 12.7059 2.98967 12.7059 3.57446V8.33915H3.43323e-05V3.57446C3.43323e-05 2.98971 0.474081 2.51562 1.05887 2.51562ZM15.6177 12.5745H12.7059C12.7059 13.1592 12.2318 13.6333 11.6471 13.6333H9.52942C9.52942 12.3176 8.46281 11.2509 7.14705 11.2509C6.65729 11.2509 6.20209 11.3987 5.82353 11.6521C5.445 11.3987 4.9898 11.2509 4.50003 11.2509C3.18428 11.2509 2.11767 12.3175 2.11767 13.6333H1.05884C0.474046 13.6333 0 13.1592 0 12.5745V9.39799H12.7059V11.5156H17.4706C17.763 11.5156 18 11.7526 18 12.045C18 12.3374 17.763 12.5745 17.4706 12.5745H16.6765V13.3686C16.6765 13.661 16.4394 13.898 16.1471 13.898C15.8547 13.898 15.6176 13.661 15.6176 13.3686L15.6177 12.5745ZM5.82356 12.3365C6.15987 11.9933 6.6286 11.7804 7.14709 11.7804C8.17042 11.7804 9.00003 12.6099 9.00003 13.6333C9.00003 14.6567 8.17045 15.4862 7.14709 15.4862C6.6286 15.4862 6.15987 15.2733 5.82356 14.9301C5.48726 15.2733 5.01852 15.4862 4.50003 15.4862C3.47671 15.4862 2.64709 14.6567 2.64709 13.6333C2.64709 12.6099 3.47667 11.7804 4.50003 11.7804C5.01852 11.7804 5.48726 11.9933 5.82356 12.3365ZM4.50003 14.4274C4.93861 14.4274 5.29414 14.0719 5.29414 13.6333C5.29414 13.1947 4.93861 12.8392 4.50003 12.8392C4.06146 12.8392 3.70592 13.1947 3.70592 13.6333C3.70592 14.0718 4.06146 14.4274 4.50003 14.4274ZM7.14709 14.4274C7.58566 14.4274 7.9412 14.0719 7.9412 13.6333C7.9412 13.1947 7.58566 12.8392 7.14709 12.8392C6.70852 12.8392 6.35298 13.1947 6.35298 13.6333C6.35298 14.0719 6.70852 14.4274 7.14709 14.4274Z" />
      </g>
      <defs>
        <clipPath id={pathId}>
          <rect width="18" height="18" />
        </clipPath>
      </defs>
    </svg>
  );
}
