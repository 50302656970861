import { Link } from "@tanstack/react-router";
import { format } from "date-fns";
import { useMemo } from "react";

import {
  EnhancedTable,
  EnhancedTableActionBar,
  EnhancedTableProvider,
  EnhancedTableSortableColumnHeader,
} from "$/features/common/enhanced-table";
import EnhancedTableCategoryFilter from "$/features/common/enhanced-table/EnhancedTableCategoryFilter";
import PlusIcon from "$/lib/components/icons/PlusIcon";
import type { TableColumn } from "$/lib/components/ui/table/Table";
import { deliveryRequestStatusEnumToLabel } from "$/lib/utils/constants/enum-labels.constants";
import {
  encryptAppString,
  formatPrice,
} from "$/lib/utils/functions/misc.functions";
import { typedObjectKeys } from "$/lib/utils/functions/util.functions";
import type { DatePickerRange } from "$/types/misc.types";

import type {
  GetDeliveryRequestEntity,
  GetDeliveryRequestResponse,
} from "../types/delivery-request-apis.types";
import { DeliveryRequestStatusEnum } from "../types/delivery-request.types";
import { getDeliveryRequests } from "../utils/delivery-request.apis";
import { deliveryRequestsQueryKeys } from "../utils/delivery-request.constants";
import DeliveryRequestActionButtons from "./DeliveryRequestActionButtons";
import DeliveryRequestStatusTag from "./DeliveryRequestStatusTag";
import MutateDeliveryRequestDialogForm from "./MutateDeliveryRequestDialogForm";

type Props = {
  conceptorView?: boolean;
};

export default function DeliveryRequestTable({ conceptorView }: Props) {
  const columns = useMemo(() => {
    const cols: TableColumn<GetDeliveryRequestEntity>[] = [
      {
        selector: "name",
        header: "Nom",
        minSize: 300,
        cell(props) {
          if (!props.row.original.id) return props.getValue();

          return (
            <Link
              className="text-base font-semibold text-black underline underline-offset-4"
              to={encryptAppString(props.row.original.id.toString())}
            >
              {props.getValue()}
            </Link>
          );
        },
      },
    ];

    if (!conceptorView) {
      cols.push(
        {
          selector: "id",
          id: "actions",
          cell(props) {
            const isDisabled: boolean = [
              DeliveryRequestStatusEnum.WAITING_FOR_CONCEPTION,
              DeliveryRequestStatusEnum.IN_PROGRESS,
              DeliveryRequestStatusEnum.COMPLETED,
              // @ts-expect-error since not all statuses are included
            ].includes(props.row.original.status);

            if (isDisabled) return null;

            return (
              <DeliveryRequestActionButtons
                requestId={props.getValue()}
                defaultValues={props.row.original}
              />
            );
          },
        },
        {
          selector: "price",
          header: () => (
            <EnhancedTableSortableColumnHeader<GetDeliveryRequestEntity>
              selector={"price"}
              title="Prix"
            />
          ),
          cell(props) {
            return formatPrice(props.getValue());
          },
        },
      );
    }
    cols.push(
      {
        selector: "client.id",
        header: "Client",
        cell(props) {
          const clientId = props.getValue();
          const clientName = props.row.original.client.name;
          if (clientId && clientName)
            return (
              <Link
                className="underline"
                to={`/sales/companies/${encryptAppString(clientId.toString())}`}
              >
                {clientName}
              </Link>
            );

          return "N/A";
        },
      },
      {
        selector: "linesCount",
        header: "Trajets",
      },
      {
        selector: "status",
        header: "Statut",
        cell(props) {
          return <DeliveryRequestStatusTag value={props.getValue()} />;
        },
      },
      {
        selector: "createdAt",
        size: 250,
        header: () => (
          <EnhancedTableSortableColumnHeader<GetDeliveryRequestEntity>
            selector={"createdAt"}
            title="Date de création"
          />
        ),
        cell(props) {
          return <div>{format(new Date(props.getValue()), "dd/MM/yyyy")}</div>;
        },
      },
    );

    return cols;
  }, [conceptorView]);

  return (
    <EnhancedTableProvider<
      GetDeliveryRequestResponse,
      GetDeliveryRequestEntity,
      {
        search: string;
        dateRange: DatePickerRange;
        statuses: DeliveryRequestStatusEnum[];
      }
    >
      dataSelector={(response) => response.data}
      totalCountSelector={(response) => response.count}
      initialFilters={{
        search: "",
        dateRange: [null, null],
        statuses: [],
      }}
      queryOptions={{
        queryKey: [
          ...deliveryRequestsQueryKeys.getDeliveryRequests,
          conceptorView,
        ],
        queryFn: ({ filters, pagination, sorting }) => {
          if (conceptorView)
            return getDeliveryRequests(sorting, pagination, {
              ...filters,
              statuses: [
                DeliveryRequestStatusEnum.WAITING_FOR_CONCEPTION,
                DeliveryRequestStatusEnum.IN_PROGRESS,
                DeliveryRequestStatusEnum.COMPLETED,
              ],
            });
          return getDeliveryRequests(sorting, pagination, filters);
        },
      }}
    >
      <EnhancedTableActionBar<GetDeliveryRequestEntity>
        hasDatePicker
        hasExport={!conceptorView}
        exportProps={{
          fileName: "Request-Line",
          includedFields: [
            "price",
            "name",
            "linesCount",
            "status",
            "createdAt",
          ],
          headersMapping: {
            createdAt: "Créé à",
            price: "Prix",
            name: "Nom",
            linesCount: "Trajets",
            status: "Statut",
          },
          valuesMapping: {
            status: (value: DeliveryRequestStatusEnum) =>
              deliveryRequestStatusEnumToLabel[value],
          },
        }}
      >
        {!conceptorView && (
          <>
            <div className="w-fit">
              <EnhancedTableCategoryFilter
                name="statuses"
                placeHolder="Statut"
                options={typedObjectKeys(DeliveryRequestStatusEnum).map(
                  (status) => ({
                    label: deliveryRequestStatusEnumToLabel[status],
                    value: status,
                  }),
                )}
              />
            </div>
            <MutateDeliveryRequestDialogForm>
              <span className="flex min-h-10 items-center justify-center gap-2 rounded-md bg-blue-light px-4 py-2 text-sm transition-all duration-200 hover:opacity-75 hover:shadow-sm active:opacity-50">
                <PlusIcon />
                Ajouter un cahier
              </span>
            </MutateDeliveryRequestDialogForm>
          </>
        )}
      </EnhancedTableActionBar>

      <EnhancedTable<GetDeliveryRequestEntity>
        selectable
        paginatable
        columns={columns}
      />
    </EnhancedTableProvider>
  );
}
