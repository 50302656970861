import { type Row, type Table, flexRender } from "@tanstack/react-table";
import type { CSSProperties } from "react";
import { Fragment } from "react/jsx-runtime";

import { cn } from "$/lib/utils/functions/misc.functions";
import { valueOrNothing } from "$/lib/utils/functions/util.functions";

import TableSkeletonLoader from "./TableSkeletonLoader";

type Props<T> = {
  data: T[];
  table: Table<T>;
  isPendingData: boolean;
  isErroredData: boolean;
  tableColumns: number;
  noDataComponent: React.ReactNode;
  errorComponent: React.ReactNode;
  onRowClick?: (
    row: Row<T>,
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => void;
};

export default function TableBodyContent<T>({
  table,
  errorComponent,
  noDataComponent,
  data,
  tableColumns,
  isPendingData,
  isErroredData,
  onRowClick,
}: Props<T>) {
  if (isPendingData) {
    return <TableSkeletonLoader columns={tableColumns} />;
  }

  if (isErroredData) {
    return (
      <tr>
        <th className="sticky left-1/2 h-32 w-fit -translate-x-1/2">
          {errorComponent
            ? errorComponent
            : "Erreur lors de la récupération des données"}
        </th>
      </tr>
    );
  }

  if (!data.length) {
    return (
      <tr>
        <th className="sticky left-1/2 h-32 w-full -translate-x-1/2">
          {noDataComponent ?? "Pas de données"}
        </th>
      </tr>
    );
  }

  return table.getRowModel().rows.map((row, index) => {
    return (
      <Fragment key={row.id}>
        {index === 0 && <tr className="h-2" />}
        <tr
          className={cn(
            "relative mx-4 h-16",
            "after:absolute after:-bottom-2 after:left-0 after:h-px after:w-full after:bg-grey-100",
            "duration-200 hover:bg-grey-50",
            row.getIsSelected() && "bg-grey-100",
            valueOrNothing(!!onRowClick, "cursor-pointer"),
          )}
          onClick={(e) => {
            onRowClick?.(row, e);
          }}
        >
          {row.getVisibleCells().map((cell) => {
            const colSize = cell.column.getSize();

            // TODO: fix size calculation
            const style: CSSProperties = {
              width: valueOrNothing(colSize !== 150, colSize),
            };

            return (
              <td
                key={cell.id}
                style={style}
                className={cn(
                  "break-all pl-4 first:rounded-bl-lg first:rounded-tl-lg",
                )}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            );
          })}
        </tr>
        <tr className="h-4" />
      </Fragment>
    );
  });
}
