import * as Select from "@radix-ui/react-select";
import {
  type CSSProperties,
  type ComponentProps,
  type PropsWithChildren,
  forwardRef,
  useId,
} from "react";

import { cn } from "$/lib/utils/functions/misc.functions";

import CheckIcon from "../../icons/CheckIcon";
import ChevronUpIcon from "../../icons/ChevronUpIcon";
import TriangleIcon from "../../icons/TriangleIcon";
import InputError from "./InputError";

type Option = {
  label: string;
  value: string;
};

type Props = ComponentProps<typeof Select.Root> & {
  label: string;
  hideLabel?: boolean;
  options: Option[];
  error?: string;
  placeholder?: string;
  contentListPosition?: "popper" | "item-aligned";
  maxListHeight?: CSSProperties["maxHeight"];
  className?: string;
  optionsClassName?: string;
};

const SelectInput = forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
  (
    {
      label,
      hideLabel,
      error,
      placeholder,
      options,
      contentListPosition = "popper",
      maxListHeight,
      className,
      optionsClassName,
      children,
      ...rootProps
    },
    ref,
  ) => {
    const labelId = useId();

    return (
      <Select.Root {...rootProps}>
        <div className="w-full">
          <label
            id={labelId}
            className={cn(
              "mb-1 ml-2 block text-xs text-black",
              hideLabel && "hidden",
            )}
          >
            {label}
          </label>
          <Select.Trigger
            ref={ref}
            aria-invalid={!!error}
            type="button"
            className={cn(
              "inline-flex h-12 min-h-12 w-full items-center justify-between gap-4 rounded-lg border-2 border-blue-light bg-blue-light px-2 text-sm leading-none shadow-sm outline-none duration-150 focus:border-primary/30 focus-visible:border-primary/30 disabled:cursor-not-allowed disabled:opacity-50 data-[state='open']:border-primary/30 data-[placeholder]:text-grey-200 aria-invalid:border-error",
              className,
            )}
            aria-labelledby={labelId}
          >
            <Select.Value placeholder={placeholder} />
            <Select.Icon>
              <TriangleIcon
                width={6}
                height={6}
                className="rotate-180"
                fill="black"
              />
            </Select.Icon>
          </Select.Trigger>

          <Select.Portal>
            <Select.Content
              className="z-popover mt-1 overflow-hidden rounded-md bg-snow px-2 py-1 shadow-2xl"
              position={contentListPosition}
              style={{
                width: "var(--radix-select-trigger-width)",
                maxHeight:
                  maxListHeight ??
                  "var(--radix-select-content-available-height)",
              }}
            >
              <Select.ScrollUpButton className="flex h-6 cursor-default items-center justify-center bg-blue-light">
                <ChevronUpIcon />
              </Select.ScrollUpButton>
              <Select.Viewport className="relative">
                {options.map((option) => (
                  <Select.Item
                    key={option.value}
                    className={cn(
                      "relative my-0.5 select-none items-center rounded-md py-3 pl-2 pr-8 leading-none data-[disabled]:pointer-events-none data-[highlighted]:bg-blue-light data-[disabled]:text-opacity-40 data-[highlighted]:outline-none",
                      "data-[state='checked']:bg-primary/15",
                      optionsClassName,
                    )}
                    value={option.value}
                  >
                    <Select.ItemText>{option.label}</Select.ItemText>
                    <Select.ItemIndicator className="absolute right-0 inline-flex w-6 items-center justify-center">
                      <CheckIcon />
                    </Select.ItemIndicator>
                  </Select.Item>
                ))}
              </Select.Viewport>
              <Select.ScrollDownButton className="flex h-6 cursor-default items-center justify-center bg-blue-light">
                <CheckIcon rotate={180} />
              </Select.ScrollDownButton>
              {children}
            </Select.Content>
          </Select.Portal>

          {!!error && <InputError errorMessage={error} />}
        </div>
      </Select.Root>
    );
  },
);

SelectInput.displayName = "SelectInput";

export default SelectInput;
