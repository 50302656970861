import { useEffect, useState } from "react";

import SearchIcon from "$/lib/components/icons/SearchIcon";
import XIcon from "$/lib/components/icons/XIcon";
import useMemoizedDebounce from "$/lib/hooks/useMemoizedDebounce";

import { useEnhancedTable } from "./EnhancedTableProvider";

type Props = { placeHolder?: string };

export default function EnhancedTableSearchFilter({
  placeHolder = "Chercher",
}: Props) {
  const [searchText, setSearchText] = useState("");
  const { handleSetFilters } = useEnhancedTable<
    unknown,
    unknown,
    { search: string }
  >();

  const debouncedSearchText = useMemoizedDebounce(searchText);

  useEffect(() => {
    handleSetFilters("search", debouncedSearchText);
  }, [debouncedSearchText, handleSetFilters]);

  return (
    <div className="relative flex h-10 w-full text-grey-300 md:w-96">
      <SearchIcon className="pointer-events-none absolute left-2 top-1/2 -translate-y-1/2" />
      <input
        type="search"
        placeholder={placeHolder}
        value={searchText}
        className="h-10 w-full rounded-xl bg-blue-light pl-10 pr-4 text-black outline-none ring-blue/80 duration-100 focus:ring-2"
        onChange={(e) => setSearchText(e.target.value)}
      />
      {searchText && (
        <button
          className="absolute right-[18px] top-1/2 h-full -translate-y-1/2 bg-blue-light"
          type="button"
          onClick={() => setSearchText("")}
        >
          <XIcon width={12} height={12} className="fill-grey-500" />
        </button>
      )}
    </div>
  );
}
