import type { ComponentProps } from "react";

export default function HomeIcon(svgProps: ComponentProps<"svg">) {
  return (
    <svg
      height="16"
      width="16"
      viewBox="0 0 48 48"
      fill="currentColor"
      aria-label="Home"
      {...svgProps}
    >
      <path d="m45.5 47.5h-15.3c-1.1 0-2-.9-2-2v-9.5c0-2.4-1.9-4.3-4.3-4.3s-4.3 1.9-4.3 4.3v9.5c0 1.1-.9 2-2 2h-15.1c-1.1 0-2-.9-2-2v-25.6c0-1.8.9-3.5 2.3-4.5l20.1-14.5c.7-.5 1.6-.5 2.3 0l20.1 14.5c1.4 1 2.3 2.7 2.3 4.5v25.7c-.1 1-1 1.9-2.1 1.9zm-13.3-3.9h11.4v-23.7c0-.5-.3-1-.7-1.3l-18.9-13.7-18.9 13.6c-.4.3-.7.8-.7 1.3v23.7h11.4v-7.5c0-4.5 3.7-8.2 8.2-8.2s8.2 3.7 8.2 8.2z"></path>
    </svg>
  );
}
