import type { PropsWithChildren } from "react";
import type { FieldValues } from "react-hook-form";

import type { DynamicFormInputsGroup } from "../dynamic-form";
import DialogFormInput from "./DialogFormInput";

type Props<T extends FieldValues> = {
  group: DynamicFormInputsGroup<T>;
  inputsPerRow?: number;
};

export default function DialogFormInputGroup<T extends FieldValues>({
  group,
  inputsPerRow,
  children,
}: PropsWithChildren<Props<T>>) {
  if (group.customRenderer) {
    // it's important to remove customRenderer from the group object to avoid
    // recursion in case we want to use the DialogFormInputGroup component
    // and pass the entire group again directly to the customRenderer
    const { customRenderer, ...restGroup } = group;
    return customRenderer(restGroup);
  }

  return (
    <fieldset key={group.name} className="mb-8">
      <legend className="mb-4">{group.name}</legend>
      <div
        className="grid gap-4 md:grid-cols-[repeat(var(--inputs-per-row),minmax(0,1fr))]"
        style={{
          "--inputs-per-row": inputsPerRow || 3,
        }}
      >
        {group.inputs.map((input) => (
          <DialogFormInput<T> key={input.name} input={input} />
        ))}
      </div>
      {children}
    </fieldset>
  );
}
