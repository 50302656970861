import type { ComponentProps } from "react";

export default function CSVIcon(svgProps: ComponentProps<"svg">) {
  return (
    <svg
      height="16"
      width="16"
      viewBox="0 0 512 512"
      fill="currentColor"
      aria-label="csv"
      {...svgProps}
    >
      <g>
        <path d="m456 220h-25v-175c0-24.813-20.187-45-45-45h-210c-3.979 0-7.794 1.581-10.606 4.394l-120 120c-2.814 2.813-4.394 6.628-4.394 10.606v332c0 24.813 20.187 45 45 45h300c24.813 0 45-20.187 45-45v-17h25c8.284 0 15-6.716 15-15v-200c0-8.284-6.716-15-15-15zm-295-168.787v53.787c0 8.271-6.729 15-15 15h-53.787zm-90 98.787h75c24.813 0 45-20.187 45-45v-75h195c8.271 0 15 6.729 15 15v175h-330zm330 317c0 8.271-6.729 15-15 15h-300c-8.271 0-15-6.729-15-15v-17h330zm40-47h-370v-170h370z"></path>
        <path d="m166 387.5c11.641 0 22.106-3.841 30.266-11.107 1.597-1.422 3.114-2.987 4.512-4.653 4.436-5.29 3.744-13.174-1.546-17.61s-13.173-3.743-17.61 1.545c-.62.74-1.287 1.429-1.981 2.047-3.56 3.17-8.148 4.777-13.64 4.777-15.163 0-27.5-12.336-27.5-27.5s12.337-27.5 27.5-27.5c5.513 0 10.826 1.622 15.366 4.689 5.722 3.865 13.491 2.36 17.355-3.36 3.865-5.72 2.36-13.49-3.359-17.355-8.689-5.871-18.843-8.974-29.362-8.974-28.948 0-52.5 23.551-52.5 52.5s23.551 52.501 52.499 52.501z"></path>
        <path d="m260.033 387.5c18.046 0 33.216-11.608 36.071-27.602 2.166-12.134-3.215-27.939-24.814-35.906-9.359-3.452-18.103-7.203-21.236-8.569-.865-.839-.94-1.893-.827-2.682.282-1.965 1.951-3.546 4.58-4.338 9.359-2.821 16.465 1.809 17.888 2.846 3.696 4.579 10.238 6.037 15.614 3.159 6.088-3.255 8.384-10.83 5.128-16.917-4.312-8.065-23.854-19.646-45.841-13.025-11.969 3.605-20.443 13.078-22.115 24.723-1.577 10.986 3.188 21.557 12.436 27.586.553.36 1.133.676 1.734.944.465.208 11.531 5.133 23.988 9.728 4.668 1.722 9.446 4.743 8.854 8.057-.504 2.821-4.494 6.996-11.46 6.996-7.232 0-14.169-2.888-18.554-7.727-4.636-5.115-12.54-5.502-17.656-.867-5.115 4.636-5.504 12.541-.867 17.656 9.18 10.129 22.694 15.938 37.077 15.938z"></path>
        <path d="m344.689 377.984c2.026 5.703 7.435 9.516 13.479 9.516h.1c6.084-.042 11.487-3.941 13.473-9.783l26.121-78.629c2.177-6.552-1.37-13.627-7.922-15.803-6.55-2.178-13.626 1.369-15.803 7.921l-16.19 48.736-17.022-49.041c-2.264-6.522-9.39-9.97-15.907-7.71-6.522 2.264-9.974 9.386-7.71 15.908z"></path>
      </g>
    </svg>
  );
}
