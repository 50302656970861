import type { LiveDeliveryRequestLine } from "$/features/delivery-request-live-lines/types/delivery-request-live-lines.types";
import type { Driver } from "$/features/driver/types/models/driver.types";

import type { DeliveryJourney } from "../../../../types/models/journey.types";
import type { DriversPool } from "../../../drivers-pools/types/drivers-pool.types";

export const VehicleTrailerTypeEnum = {
  TRAILER: "TRAILER",
  COVERED_AERIAL: "COVERED_AERIAL",
  CLASSIC_AERIAL: "CLASSIC_AERIAL",
  REFRIGERATED_AERIAL: "REFRIGERATED_AERIAL",
  MEGA_AERIAL: "MEGA_AERIAL",
  CLASSIC_SEMI_TRAILER: "CLASSIC_SEMI_TRAILER",
  REFRIGERATED_SEMI_TRAILER: "REFRIGERATED_SEMI_TRAILER",
  MEGA_REFRIGERATED_SEMI_TRAILER: "MEGA_REFRIGERATED_SEMI_TRAILER",
} as const;

export type VehicleTrailerTypeEnum =
  (typeof VehicleTrailerTypeEnum)[keyof typeof VehicleTrailerTypeEnum];

export const VehiclePayloadTypeEnum = {
  PALLET: "PALLET",
  BULK: "BULK",
} as const;
export type VehiclePayloadTypeEnum =
  (typeof VehiclePayloadTypeEnum)[keyof typeof VehiclePayloadTypeEnum];

export const VehicleTypeEnum = {
  TRACTOR_GO: "TRACTOR_GO",
  TRACTOR_GAZ: "TRACTOR_GAZ",
  CAISSE_MOBILE_GO: "CAISSE_MOBILE_GO",
  CAISSE_MOBILE_GAZ: "CAISSE_MOBILE_GAZ",
} as const;
export type VehicleTypeEnum =
  (typeof VehicleTypeEnum)[keyof typeof VehicleTypeEnum];

export const FuelTypeEnum = {
  GASOLINE: "GASOLINE",
  ELECTRIC: "ELECTRIC",
  BIOGAZ: "BIOGAZ",
} as const;
export type FuelTypeEnum = (typeof FuelTypeEnum)[keyof typeof FuelTypeEnum];

export type Vehicle = {
  id: number;
  registrationNumber: string;
  brand: string;
  year: number;
  capacity: number;
  volume: number;
  mileage: number;
  fuelCapacity: number;
  emptyWeight: number;
  loadedWeight: number;
  owningAgency: string;
  fuelType: FuelTypeEnum;
  fuelConsumption: number;
  isAvailable: boolean;
  isDeleted: boolean;
  categoryCode?: string;
  myRentACarId?: number;

  deliveryJourneys: DeliveryJourney[];
  driversPools: DriversPool[];
  damages: VehicleDamage[];
  equipment: VehicleEquipment[];
  vehicleType: VehicleTypeEnum;
  liveLines: LiveDeliveryRequestLine[];
};

export type VehicleDamage = {
  id: number;
  createdAt: string;
  date: string;
  resolvedAt?: string;
  resolvedById?: number;
  cost: number;
  comment: string;
  pictures: string[];
  vehicle: Vehicle;
  vehicleId: number;
  label: VehicleDamageLabel;
  labelId: number;
  action?: VehicleDamageAction;
  actionId?: number;
  driver?: Driver;
  driverId?: number;
  journey?: DeliveryJourney;
  journeyId?: number;
  isEmailSent: boolean;
};

export type VehicleDamageAction = {
  id: number;
  name: string;
  vehicleDamages: VehicleDamage[];
};

export type VehicleDamageLabel = {
  id: number;
  name: string;
  disabled: boolean;
  vehicleDamages: VehicleDamage[];
};

export type VehicleEquipment = {
  id: number;
  label: string;
  vehicles: Vehicle[];
};

export type VehicleTrailer = {
  id: number;
  registrationNumber: string;
  trailerType: VehicleTrailerTypeEnum;
  deliveryJourneys: DeliveryJourney[];
};
