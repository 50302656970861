import type { DeliveryRequest } from "$/features/delivery-request/types/delivery-request.types";
import type { DriversPool } from "$/features/drivers-pools/types/drivers-pool.types";
import type { User } from "$/features/user/types/models/user.types";
import type {
  VehicleDamage,
  VehicleTypeEnum,
} from "$/features/vehicles/types/models/vehicle.types";
import type {
  DeliveryJourney,
  JourneyDelay,
  JourneyIncident,
  JourneyInfraction,
} from "$/types/models/journey.types";

export const WorkShiftEnum = {
  DAY: "DAY",
  NIGHT: "NIGHT",
  FULL: "FULL",
} as const;

export type WorkShiftEnum = (typeof WorkShiftEnum)[keyof typeof WorkShiftEnum];

export type DriverCertification = {
  id: number;
  label: string;
  drivers: Driver[];
  deliveryRequests: DeliveryRequest[];
};

export type Driver = {
  id: number;
  factorialId: number | null;
  rating: number;
  birthdate: string | null;
  vehiclesTypes: VehicleTypeEnum[];
  metrics: string | null;
  type: string | null;
  isDeactivated: boolean;
  shift: WorkShiftEnum;
  availableOnWeekends: boolean;
  isOnProbation: boolean;
  address: string | null;
  user: User;
  userId: number;
  leaves: DriverLeave[];
  journeys: DeliveryJourney[];
  pools: DriversPool[];
  vehicleDamages: VehicleDamage[];
  certifications: DriverCertification[];
  journeyInfractions: JourneyInfraction[];
  journeyIncidents: JourneyIncident[];
  journeyDelays: JourneyDelay[];
};

export type DriverLeave = {
  id: number;
  factorialId: number | null;
  approved: boolean;
  startDate: string;
  endDate: string;
  type: string | null;
  driver: Driver;
  driverId: number;
};
