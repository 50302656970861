import { zodResolver } from "@hookform/resolvers/zod";
import type {
  ComponentProps,
  FormEventHandler,
  PropsWithChildren,
} from "react";
import type {
  FieldValues,
  SubmitErrorHandler,
  UseFormHandleSubmit,
  UseFormProps,
} from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";

type FormSubmitHandler<
  TFieldValues extends FieldValues,
  TTransformedValues extends FieldValues,
> = Parameters<UseFormHandleSubmit<TFieldValues, TTransformedValues>>[0];

type FormOptions<TFieldValues extends FieldValues> = Omit<
  UseFormProps<TFieldValues>,
  "resolver"
> & {
  resetOnSubmit?: boolean;
};

type Props<
  TFieldValues extends FieldValues,
  TTransformedValues extends FieldValues,
> = Omit<ComponentProps<"form">, "onSubmit"> & {
  className?: string;
  resolverSchema?: Parameters<typeof zodResolver>[0];
  options?: FormOptions<TFieldValues>;
  onSubmit: FormSubmitHandler<TFieldValues, TTransformedValues>;
  onSubmitError?: SubmitErrorHandler<TFieldValues>;
};

export default function Form<
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown,
  TTransformedValues extends FieldValues = TFieldValues,
>({
  className,
  resolverSchema,
  options,
  onSubmit,
  onSubmitError,
  children,
  ...formProps
}: PropsWithChildren<Props<TFieldValues, TTransformedValues>>) {
  const methods = useForm<TFieldValues, TContext, TTransformedValues>({
    ...options,
    resolver: resolverSchema ? zodResolver(resolverSchema) : undefined,
  });

  const { handleSubmit, reset } = methods;

  const onSubmitHandler: FormEventHandler<HTMLFormElement> = (e) => {
    // @ts-expect-error FIXME: breaking type bug in react-hook-form
    const onSubmitWrapper: FormSubmitHandler<
      TFieldValues,
      TTransformedValues
    > = async (data, e) => {
      await onSubmit(data, e);
      if (options?.resetOnSubmit) {
        // push the reset to the end of the queue to notify the inputs that
        // need to reset their UI like the radix select
        // https://stackoverflow.com/questions/779379/why-is-settimeoutfn-0-sometimes-useful
        setTimeout(() => {
          reset();
        }, 0);
      }
    };

    handleSubmit(onSubmitWrapper, onSubmitError)(e);
  };

  return (
    <FormProvider {...methods}>
      <form className={className} onSubmit={onSubmitHandler} {...formProps}>
        {children}
      </form>
    </FormProvider>
  );
}
