import CheckIcon from "$/lib/components/icons/CheckIcon";

type Props = {
  label: string;
  isSelected: boolean;
  multi: boolean;
};

export default function ComboboxInputOption({
  label,
  isSelected,
  multi,
}: Props) {
  if (multi) {
    return (
      <label className="line-clamp-1 flex size-full h-10 max-w-full items-center gap-2 rounded-md p-1">
        <input
          className="pointer-events-none size-4 border-blue-light"
          type="checkbox"
          role="none"
          checked={isSelected}
          onChange={() => {}}
          tabIndex={-1}
        />
        <span className="line-clamp-1 w-10/12 text-ellipsis">{label}</span>
      </label>
    );
  }

  return (
    <button
      type="button"
      role="none"
      aria-selected={isSelected}
      className="pointer-events-none line-clamp-1 flex h-10 w-full items-center justify-between text-ellipsis rounded-md p-1 text-left aria-selected:bg-primary-100"
      tabIndex={-1}
    >
      <span className="line-clamp-1 w-11/12 text-ellipsis">{label}</span>{" "}
      {isSelected && <CheckIcon />}
    </button>
  );
}
