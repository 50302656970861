import type { ComponentProps } from "react";

export default function AbsenceIcon(svgProps: ComponentProps<"svg">) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      aria-label="Absence"
      {...svgProps}
    >
      <path d="M1.41539 7.34215C1.61227 7.42371 1.83938 7.37871 1.9902 7.22789C2.45391 6.76383 3.07055 6.50859 3.72656 6.50859C4.09957 6.50859 4.45992 6.59121 4.78652 6.74766C4.72465 3.93727 5.27414 1.69324 6.35871 0.328125C3.29027 1.13496 1.08984 3.40254 1.08984 6.85488C1.08984 7.06828 1.21816 7.26059 1.41539 7.34215Z" />
      <path d="M5.86633 7.3813C5.9951 7.37388 6.11724 7.32006 6.20892 7.22834C6.95448 6.48278 7.94575 6.07219 9.00012 6.07219C10.0545 6.07219 11.0458 6.48278 11.7913 7.22834C11.883 7.32009 12.0052 7.37392 12.1341 7.3813C12.3968 2.64016 10.8927 0 9.00012 0C7.10762 0 5.60357 2.64027 5.86633 7.3813Z" />
      <path d="M13.2138 6.74766C13.5404 6.59121 13.9004 6.50859 14.2734 6.50859C14.9294 6.50859 15.5461 6.76383 16.0098 7.22789C16.1606 7.37871 16.3877 7.42371 16.5846 7.34215C16.7818 7.26059 16.9101 7.06828 16.9101 6.85488C16.9101 3.40219 14.7093 1.13496 11.6416 0.328125C12.7258 1.69324 13.2753 3.93762 13.2138 6.74766Z" />
      <path d="M16.3828 16.9434H12.6384C12.3934 15.7414 11.328 14.8341 10.0547 14.8341H9.52734V7.17281C9.35508 7.14117 9.17895 7.125 9 7.125C8.82105 7.125 8.64492 7.14117 8.47266 7.17281V14.8341H7.94531C6.67199 14.8341 5.60665 15.7414 5.36164 16.9434H1.61719C1.32595 16.9434 1.08984 17.1795 1.08984 17.4708C1.08984 17.762 1.32595 17.9981 1.61719 17.9981H16.3828C16.674 17.9981 16.9102 17.762 16.9102 17.4708C16.9102 17.1795 16.674 16.9434 16.3828 16.9434Z" />
    </svg>
  );
}
