/* eslint-disable react-refresh/only-export-components */
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  type PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";

import { getMe } from "$/api/auth/get-me";
import type {
  User,
  UserRoleEnum,
} from "$/features/user/types/models/user.types";

type AuthContextType = {
  user: User | null;
  role: UserRoleEnum | null;
  isFetching: boolean;
  status: "pending" | "error" | "success";
  setUser: (user: User) => void;
  invalidateUser: (fetchingStateVisible?: boolean) => Promise<void>;
  clearUser: () => void;
};

const AuthContext = createContext<AuthContextType>({
  user: null,
  role: null,
  isFetching: true,
  status: "pending",
  setUser: () => {},
  invalidateUser: async () => {},
  clearUser: () => {},
});

export default function AuthProvider({ children }: PropsWithChildren) {
  const [showFetchingState, setShowFetchingState] = useState(true);

  const queryClient = useQueryClient();

  const {
    data: user,
    isFetching,
    status,
  } = useQuery({
    queryKey: ["user"],
    queryFn: getMe,
    retry: false,
  });

  const setUser = (data: User) => {
    queryClient.setQueryData(["user"], data);
  };

  const invalidateUser = async (fetchingStateVisible?: boolean) => {
    setShowFetchingState(!!fetchingStateVisible);
    await queryClient.invalidateQueries({
      queryKey: ["user"],
    });
    setShowFetchingState(true);
  };

  const clearUser = () => {
    queryClient.setQueryData(["user"], null);
  };

  return (
    <AuthContext.Provider
      value={{
        user: user ?? null,
        role: user?.role ?? null,
        isFetching: isFetching && showFetchingState,
        status,
        setUser,
        clearUser,
        invalidateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

export type VerifiedAuthContext = {
  user: User;
  role: UserRoleEnum;
  isFetching: boolean;
  status: "success";
  setUser: (user: User) => void;
  invalidateUser: (fetchingStateVisible?: boolean) => Promise<void>;
  clearUser: () => void;
};
