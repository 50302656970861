import { useState } from "react";

import ComboboxInput from "$/lib/components/ui/inputs/combobox-input/ComboboxInput";
import { cn } from "$/lib/utils/functions/misc.functions";
import type { Option } from "$/types/util.types";

import { useEnhancedTable } from "./EnhancedTableProvider";

type Props<T> = {
  name: string;
  options: Option<T>[];
  placeHolder: string;
  containerClassName?: string;
};

export default function EnhancedTableCategoryFilter<T>({
  name,
  options,
  placeHolder,
  containerClassName,
}: Props<T>) {
  const [searchText, setSearchText] = useState("");
  const { filters, handleSetFilters } = useEnhancedTable<
    unknown,
    unknown,
    {
      [name: string]: T[];
    }
  >();

  const visibleOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchText.toLowerCase()),
  );

  const handleSearch = (search: string) => {
    setSearchText(search);
  };

  const handleSelect = (option: T) => {
    if (filters[name]?.includes(option)) {
      handleSetFilters(
        name,
        filters[name].filter((o) => o !== option),
      );
      return;
    }

    handleSetFilters(name, [...(filters[name] || []), option]);
  };

  const handleClear = () => {
    handleSetFilters(name, []);
  };

  return (
    <ComboboxInput
      containerClassName={cn("w-fit", containerClassName)}
      overlayClassName="min-w-[calc(100vw-2rem)] md:min-w-56"
      buttonClassName="h-10 min-h-10"
      onSelect={handleSelect}
      placeholder={placeHolder}
      multi
      onSearch={handleSearch}
      onClear={handleClear}
      options={visibleOptions}
      selected={filters[name] || []}
    />
  );
}
