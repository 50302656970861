/* eslint-disable react-refresh/only-export-components */
import { useRouter } from "@tanstack/react-router";
import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

type InternalRouterContextType = {
  navigationHistory: string[];
  backHref: string;
  handleSetBackHref: (href: string) => void;
};

const InternalRouterContext = createContext<InternalRouterContextType | null>(
  null,
);

export default function InternalRouterProvider({
  children,
}: PropsWithChildren) {
  const [navigationHistory, setNavigationHistory] = useState<string[]>([]);
  const [backHref, setBackHref] = useState("/");

  const { subscribe } = useRouter();

  useEffect(() => {
    const unsubscribe = subscribe("onResolved", ({ toLocation }) => {
      setNavigationHistory((prev) => [...prev, toLocation.href].slice(-10));
    });

    return () => {
      unsubscribe();
    };
  }, [subscribe]);

  const handleSetBackHref = useCallback((href: string) => {
    setBackHref(href);
  }, []);

  return (
    <InternalRouterContext.Provider
      value={{
        navigationHistory,
        backHref,
        handleSetBackHref,
      }}
    >
      {children}
    </InternalRouterContext.Provider>
  );
}

export function useInternalRouter() {
  const internalRouter = useContext(InternalRouterContext);
  if (!internalRouter) {
    throw new Error(
      "useInternalRouter must be used within InternalRouterProvider",
    );
  }

  return internalRouter;
}
