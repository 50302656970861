import type { PropsWithChildren } from "react";

import { useMediaQuery } from "$/lib/hooks/useMediaQuery";

import BottomSheetPortal from "../bottom-sheet/BottomSheetPortal";
import ModalPortal from "../modal/ModalPortal";

export default function ResponsiveDialogPortal(props: PropsWithChildren) {
  const isMobile = useMediaQuery("(max-width: 767px)");

  if (isMobile) return <BottomSheetPortal {...props} />;

  return <ModalPortal {...props} />;
}
