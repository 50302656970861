import { deliveryRequestStatusEnumToLabel } from "$/lib/utils/constants/enum-labels.constants";
import { cn } from "$/lib/utils/functions/misc.functions";

import { DeliveryRequestStatusEnum } from "../types/delivery-request.types";

type Props = {
  value: DeliveryRequestStatusEnum;
};

export default function DeliveryRequestStatusTag({ value }: Props) {
  return (
    <div
      className={cn("whitespace-nowrap rounded-sm px-4 py-2 text-center", {
        "bg-grey-100 text-grey-400": value === DeliveryRequestStatusEnum.DRAFT,
        "bg-blue-100 text-blue":
          value === DeliveryRequestStatusEnum.SENT_TO_CLIENT ||
          value === DeliveryRequestStatusEnum.VALIDATED_BY_CLIENT,
        "bg-yellow-100 text-yellow-600":
          value === DeliveryRequestStatusEnum.WAITING_FOR_CONCEPTION ||
          value === DeliveryRequestStatusEnum.IN_PROGRESS,
        "bg-green-100 text-green":
          value === DeliveryRequestStatusEnum.COMPLETED,
      })}
    >
      {deliveryRequestStatusEnumToLabel[value]}
    </div>
  );
}
