import {
  type QueryClient,
  type UseMutationOptions,
  useMutation,
} from "@tanstack/react-query";
import { toast } from "sonner";

import type { ApiError } from "$/types/misc.types";

type UseApiMutationOptions<
  TData = unknown,
  TError extends ApiError = ApiError,
  TVariables = void,
  TContext = unknown,
> = UseMutationOptions<TData, TError, TVariables, TContext> & {
  hideErrorToast?: boolean;
};

export default function useApiMutation<
  TData = unknown,
  TError extends ApiError = ApiError,
  TVariables = void,
  TContext = unknown,
>(
  options: UseApiMutationOptions<TData, TError, TVariables, TContext>,
  queryClient?: QueryClient,
) {
  return useMutation<TData, TError, TVariables, TContext>(
    {
      ...options,
      onError: (...errorArgs) => {
        if (!options.hideErrorToast) {
          const errorMessage =
            errorArgs[0].json?.message ||
            "Un problème s'est produit, veuillez réessayer";

          toast.error(errorMessage);
        }
        return options.onError?.(...errorArgs);
      },
    },
    queryClient,
  );
}
