import {
  Navigate,
  Outlet,
  createFileRoute,
  useMatches,
} from "@tanstack/react-router";

import BackGroundLogo from "$/lib/components/icons/BackGroundLogo";
import PageLoader from "$/lib/components/loaders/page-loader";
import Header from "$/lib/components/ui/Header";
import NotFound from "$/lib/components/ui/NotFound";
import SideBar from "$/lib/components/ui/sidebar/Sidebar";
import { useAuth } from "$/lib/providers/AuthProvider";
import { protectedNavigation } from "$/lib/utils/constants/misc.constants";

export const Route = createFileRoute("/_user-layout")({
  component: LayoutComponent,
});

function LayoutComponent() {
  const { user, role, isFetching } = useAuth();

  // find the most detailed route match
  const routeMatch = useMatches({
    select(matches) {
      return matches.at(-1);
    },
  });
  if (isFetching) return <PageLoader />;

  if (!user || !role) return <Navigate to="/login" />;

  // remove empty segments and segments starting with "_" (layout segments)
  const fullRouteMatchPath = routeMatch?.routeId
    .split("/")
    .filter(
      (routeSegment) => routeSegment !== "" && !routeSegment.startsWith("_"),
    )
    .join("/");

  const protectedNavigationRoles = protectedNavigation[
    fullRouteMatchPath as keyof typeof protectedNavigation
  ] as string[] | undefined;

  if (!protectedNavigationRoles)
    throw new Error(
      "Route not found, please make sure it's defined in the protectedNavigation object",
    );

  const canAccess = protectedNavigationRoles.includes(role);

  if (!canAccess) return <NotFound />;

  return (
    <>
      <NavigationComponents />
      <main
        id="main"
        className="ml-auto min-h-full bg-snow pb-8 pt-20 md:w-[calc(100%-256px)]"
      >
        <Header />
        <Outlet />
      </main>
    </>
  );
}

function NavigationComponents() {
  return (
    <>
      <SideBar />
      <div className="pointer-events-none fixed -top-12 right-0 hidden overflow-hidden lg:block">
        <BackGroundLogo className="translate-x-52" />
      </div>
    </>
  );
}
