import type { ComponentProps } from "react";

export default function SearchIcon(svgProps: ComponentProps<"svg">) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 21 20"
      fill="currentColor"
      aria-label="Search"
      {...svgProps}
    >
      <path d="M8.99414 0C13.47 0 17.1113 3.64137 17.1113 8.11719C17.1113 10.1445 16.3642 12.0007 15.1309 13.4247L20.7054 18.9998C20.9342 19.2286 20.9341 19.5996 20.7053 19.8284C20.4765 20.0573 20.1055 20.0572 19.8767 19.8284L14.3023 14.2534C12.8781 15.4871 11.0218 16.2344 8.99414 16.2344C4.51832 16.2344 0.876953 12.593 0.876953 8.11719C0.876953 3.64137 4.51832 0 8.99414 0ZM8.99414 15.0625C12.8238 15.0625 15.9395 11.9468 15.9395 8.11719C15.9395 4.28754 12.8238 1.17188 8.99414 1.17188C5.16449 1.17188 2.04883 4.28754 2.04883 8.11719C2.04883 11.9468 5.16449 15.0625 8.99414 15.0625Z" />
    </svg>
  );
}
