import type { ComponentProps } from "react";

type Props = ComponentProps<"svg"> & {
  exclamationMarkFill?: string;
};

export default function IncidentIcon({
  exclamationMarkFill = "#1D1D1B",
  ...svgProps
}: Props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      aria-label="Incident"
      {...svgProps}
    >
      <path d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z" />
      <path
        d="M9.00026 10.6C8.71055 10.6 8.43208 10.4879 8.22319 10.2871C8.01429 10.0864 7.89118 9.81261 7.87964 9.52312L7.73182 5.79063C7.72589 5.63975 7.75048 5.48924 7.80413 5.3481C7.85777 5.20696 7.93936 5.0781 8.044 4.96926C8.14865 4.86041 8.27419 4.77382 8.41311 4.71466C8.55203 4.65551 8.70146 4.62501 8.85245 4.625H9.14807C9.29906 4.62501 9.44849 4.65551 9.58741 4.71466C9.72633 4.77382 9.85188 4.86041 9.95652 4.96926C10.0612 5.0781 10.1428 5.20696 10.1964 5.3481C10.25 5.48924 10.2746 5.63975 10.2687 5.79063L10.1209 9.52312C10.1093 9.81261 9.98623 10.0864 9.77734 10.2871C9.56844 10.4879 9.28997 10.6 9.00026 10.6Z"
        fill={exclamationMarkFill}
      />
      <path
        d="M10.1197 12.2525C10.1197 11.6341 9.61844 11.1328 9.00006 11.1328C8.38167 11.1328 7.88037 11.6341 7.88037 12.2525C7.88037 12.8709 8.38167 13.3722 9.00006 13.3722C9.61844 13.3722 10.1197 12.8709 10.1197 12.2525Z"
        fill={exclamationMarkFill}
      />
    </svg>
  );
}
