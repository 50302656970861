import { type ComponentProps, useId } from "react";

export default function CalendarIcon(svgProps: ComponentProps<"svg">) {
  const pathId = useId();

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      aria-label="Calendar"
      {...svgProps}
    >
      <g clipPath={`url(#${pathId})`}>
        <path d="M12.1851 7.23828C12.5087 7.23828 12.771 6.97595 12.771 6.65234C12.771 6.32874 12.5087 6.06641 12.1851 6.06641C11.8615 6.06641 11.5991 6.32874 11.5991 6.65234C11.5991 6.97595 11.8615 7.23828 12.1851 7.23828Z" />
        <path d="M13.5327 1.67188H12.771V1.08594C12.771 0.762324 12.5087 0.5 12.1851 0.5C11.8614 0.5 11.5991 0.762324 11.5991 1.08594V1.67188H8.93311V1.08594C8.93311 0.762324 8.67078 0.5 8.34717 0.5C8.02355 0.5 7.76123 0.762324 7.76123 1.08594V1.67188H5.12451V1.08594C5.12451 0.762324 4.86219 0.5 4.53857 0.5C4.21496 0.5 3.95264 0.762324 3.95264 1.08594V1.67188H3.22021C1.92787 1.67188 0.876465 2.72328 0.876465 4.01562V13.1562C0.876465 14.4486 1.92787 15.5 3.22021 15.5H7.70264C8.02625 15.5 8.28857 15.2377 8.28857 14.9141C8.28857 14.5904 8.02625 14.3281 7.70264 14.3281H3.22021C2.57404 14.3281 2.04834 13.8024 2.04834 13.1562V4.01562C2.04834 3.36945 2.57404 2.84375 3.22021 2.84375H3.95264V3.42969C3.95264 3.7533 4.21496 4.01562 4.53857 4.01562C4.86219 4.01562 5.12451 3.7533 5.12451 3.42969V2.84375H7.76123V3.42969C7.76123 3.7533 8.02355 4.01562 8.34717 4.01562C8.67078 4.01562 8.93311 3.7533 8.93311 3.42969V2.84375H11.5991V3.42969C11.5991 3.7533 11.8614 4.01562 12.1851 4.01562C12.5087 4.01562 12.771 3.7533 12.771 3.42969V2.84375H13.5327C14.1789 2.84375 14.7046 3.36945 14.7046 4.01562V7.35547C14.7046 7.67908 14.9669 7.94141 15.2905 7.94141C15.6141 7.94141 15.8765 7.67908 15.8765 7.35547V4.01562C15.8765 2.72328 14.8251 1.67188 13.5327 1.67188Z" />
        <path d="M12.3315 8.41016C10.3769 8.41016 8.78662 10.0004 8.78662 11.9551C8.78662 13.9098 10.3769 15.5 12.3315 15.5C14.2862 15.5 15.8765 13.9098 15.8765 11.9551C15.8765 10.0004 14.2862 8.41016 12.3315 8.41016ZM12.3315 14.3281C11.0231 14.3281 9.9585 13.2636 9.9585 11.9551C9.9585 10.6466 11.0231 9.58203 12.3315 9.58203C13.64 9.58203 14.7046 10.6466 14.7046 11.9551C14.7046 13.2636 13.64 14.3281 12.3315 14.3281Z" />
        <path d="M13.1812 11.3691H12.9175V10.7539C12.9175 10.4303 12.6552 10.168 12.3315 10.168C12.0079 10.168 11.7456 10.4303 11.7456 10.7539V11.9551C11.7456 12.2787 12.0079 12.541 12.3315 12.541H13.1812C13.5048 12.541 13.7671 12.2787 13.7671 11.9551C13.7671 11.6315 13.5048 11.3691 13.1812 11.3691Z" />
        <path d="M9.63623 7.23828C9.95983 7.23828 10.2222 6.97595 10.2222 6.65234C10.2222 6.32874 9.95983 6.06641 9.63623 6.06641C9.31263 6.06641 9.05029 6.32874 9.05029 6.65234C9.05029 6.97595 9.31263 7.23828 9.63623 7.23828Z" />
        <path d="M7.0874 9.78711C7.41101 9.78711 7.67334 9.52478 7.67334 9.20117C7.67334 8.87757 7.41101 8.61523 7.0874 8.61523C6.7638 8.61523 6.50146 8.87757 6.50146 9.20117C6.50146 9.52478 6.7638 9.78711 7.0874 9.78711Z" />
        <path d="M4.53857 7.23828C4.86218 7.23828 5.12451 6.97595 5.12451 6.65234C5.12451 6.32874 4.86218 6.06641 4.53857 6.06641C4.21497 6.06641 3.95264 6.32874 3.95264 6.65234C3.95264 6.97595 4.21497 7.23828 4.53857 7.23828Z" />
        <path d="M4.53857 9.78711C4.86218 9.78711 5.12451 9.52478 5.12451 9.20117C5.12451 8.87757 4.86218 8.61523 4.53857 8.61523C4.21497 8.61523 3.95264 8.87757 3.95264 9.20117C3.95264 9.52478 4.21497 9.78711 4.53857 9.78711Z" />
        <path d="M4.53857 12.3359C4.86218 12.3359 5.12451 12.0736 5.12451 11.75C5.12451 11.4264 4.86218 11.1641 4.53857 11.1641C4.21497 11.1641 3.95264 11.4264 3.95264 11.75C3.95264 12.0736 4.21497 12.3359 4.53857 12.3359Z" />
        <path d="M7.0874 12.3359C7.41101 12.3359 7.67334 12.0736 7.67334 11.75C7.67334 11.4264 7.41101 11.1641 7.0874 11.1641C6.7638 11.1641 6.50146 11.4264 6.50146 11.75C6.50146 12.0736 6.7638 12.3359 7.0874 12.3359Z" />
        <path d="M7.0874 7.23828C7.41101 7.23828 7.67334 6.97595 7.67334 6.65234C7.67334 6.32874 7.41101 6.06641 7.0874 6.06641C6.7638 6.06641 6.50146 6.32874 6.50146 6.65234C6.50146 6.97595 6.7638 7.23828 7.0874 7.23828Z" />
      </g>
    </svg>
  );
}
