import EditIcon from "$/lib/components/icons/EditIcon";

import type { GetDeliveryRequestEntity } from "../types/delivery-request-apis.types";
import DeleteDeliveryRequestDialog from "./DeleteDeliveryRequestDialog";
import MutateDeliveryRequestDialogForm from "./MutateDeliveryRequestDialogForm";

type Props = {
  requestId: number;
  defaultValues: GetDeliveryRequestEntity;
};

export default function DeliveryRequestActionButtons({
  requestId,
  defaultValues,
}: Props) {
  return (
    <div className="flex items-center gap-2 text-black">
      <MutateDeliveryRequestDialogForm
        defaultValues={defaultValues}
        id={requestId}
      >
        <EditIcon />
      </MutateDeliveryRequestDialogForm>
      <DeleteDeliveryRequestDialog requestId={requestId} />
    </div>
  );
}
