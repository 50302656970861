import { type ComponentProps, useId } from "react";

export default function ReplacementsIcon(svgProps: ComponentProps<"svg">) {
  const pathId = useId();
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      aria-label="Replacements"
      {...svgProps}
    >
      <g clipPath={`url(#${pathId})`}>
        <path d="M9.40798 7.15498V8.05998C9.40798 8.34741 9.46538 8.58856 9.57868 8.77643C9.73496 9.03571 9.99149 9.1843 10.2828 9.1843C10.5681 9.1843 10.8517 9.04532 11.1255 8.77148L14.1147 5.78238C14.771 5.12608 14.771 4.05821 14.1147 3.40192L11.1255 0.412811C10.8517 0.13884 10.5681 0 10.2828 0C9.84729 0 9.40798 0.347717 9.40798 1.12431V1.94623C5.16423 2.19685 1.65108 5.45361 1.12895 9.71754C1.0997 9.95567 1.23511 10.1836 1.45827 10.2719C1.52144 10.2969 1.58722 10.309 1.65218 10.309C1.8167 10.309 1.97627 10.232 2.0779 10.0931C3.47838 8.1796 5.72701 7.03716 8.09291 7.03716C8.53127 7.03716 8.97209 7.07671 9.40798 7.15498Z" />
        <path d="M16.5323 7.7246C16.3091 7.63616 16.0545 7.70977 15.9126 7.90354C14.5123 9.81695 12.2637 10.9594 9.89777 10.9594C9.45942 10.9594 9.01859 10.9198 8.58271 10.8416V9.93656C8.58271 9.15996 8.14326 8.81225 7.70792 8.81225C7.42255 8.81225 7.13897 8.95122 6.865 9.2252L3.87589 12.2142C3.21973 12.8705 3.21973 13.9383 3.87603 14.5948L6.865 17.5837C7.13897 17.8577 7.42255 17.9965 7.70792 17.9965C8.14326 17.9965 8.58271 17.6488 8.58271 16.8722V16.0503C12.8266 15.7997 16.3396 12.5429 16.8617 8.27913C16.891 8.04087 16.7556 7.8129 16.5323 7.7246Z" />
      </g>
      <defs>
        <clipPath id={pathId}>
          <rect width="18" height="18" />
        </clipPath>
      </defs>
    </svg>
  );
}
