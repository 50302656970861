import { type PropsWithChildren, useRef } from "react";
import { Drawer } from "vaul";

import { useRootDialog } from "$/lib/providers/DialogProvider";

export default function BottomSheetPortal({ children }: PropsWithChildren) {
  const rootDialog = useRootDialog();
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <Drawer.Portal>
      <Drawer.Overlay
        className="fixed inset-0 z-backdrop bg-black/60 backdrop-blur-sm"
        onClick={(e) => {
          if (contentRef.current) {
            const { top, right, bottom, left } =
              contentRef.current.getBoundingClientRect();
            const { clientX, clientY } = e;

            if (
              clientX < left ||
              clientX > right ||
              clientY < top ||
              clientY > bottom
            ) {
              rootDialog.handleSetOpen(false);
            }
          }
        }}
      />

      <Drawer.Content
        ref={contentRef}
        onClick={(e) => e.stopPropagation()}
        onEscapeKeyDown={() => rootDialog.handleSetOpen(false)}
        className="fixed inset-x-0 bottom-0 z-modal mt-24 max-h-[90vh]"
      >
        {children}
      </Drawer.Content>
    </Drawer.Portal>
  );
}
