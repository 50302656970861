import { type ComponentProps, useId } from "react";

type Props = ComponentProps<"svg"> & {
  variant?: "circle" | "square";
};
export default function EditIcon(svgProps: Props) {
  const pathId = useId();

  if (svgProps.variant === "square") {
    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="black"
        aria-label="Edit"
        {...svgProps}
      >
        <g clipPath={`url(${pathId})`}>
          <path d="M7.99928 8.90094C7.86475 9.03539 7.6985 9.13812 7.51833 9.19819L5.77814 9.77823C5.65255 9.82013 5.52158 9.8414 5.38907 9.8414C5.06037 9.8414 4.75136 9.7134 4.51896 9.48098C4.18726 9.14919 4.0734 8.66674 4.22174 8.22177L4.80181 6.48167C4.86183 6.30145 4.96464 6.13509 5.09904 6.0007L8.85757 2.24219H1.50391C0.674652 2.24219 0 2.91684 0 3.74609V12.4961C0 13.3253 0.674652 14 1.50391 14H10.2539C11.0832 14 11.7578 13.3253 11.7578 12.4961V5.14243L7.99928 8.90094Z" />
          <path d="M5.67969 6.58078C5.63466 6.62582 5.60075 6.6807 5.58063 6.7411L5.00056 8.48123C4.95142 8.62861 4.98978 8.79109 5.09962 8.90096C5.20949 9.01082 5.37197 9.04919 5.51935 9.00002L7.25951 8.41998C7.31994 8.39986 7.37479 8.36595 7.41982 8.32091L12.5436 3.19716L10.8035 1.45703L5.67969 6.58078Z" />
          <path d="M13.0263 0.200218C12.7593 -0.0667393 12.3265 -0.0667393 12.0595 0.200218L11.3828 0.876948L13.123 2.6171L13.7997 1.94037C14.0667 1.67342 14.0667 1.24059 13.7997 0.973636L13.0263 0.200218Z" />
        </g>
        <defs>
          <clipPath id={pathId}>
            <rect width="14" height="14" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width="22"
      height="25"
      viewBox="0 0 18 19"
      fill="black"
      aria-label="Edit"
      {...svgProps}
    >
      <g clipPath={`url(${pathId})`}>
        <path d="M8.9997 0.5C4.02936 0.5 0 4.52966 0 9.49982C0 14.47 4.02936 18.5 8.9997 18.5C13.97 18.5 17.9999 14.4702 17.9999 9.49982C17.9999 4.52942 13.97 0.5 8.9997 0.5ZM13.2781 6.9767L12.4232 7.83152L10.6832 6.09158L10.0231 6.7517L11.7631 8.49176L7.4952 12.7593L5.75526 11.0196L5.09514 11.6797L6.83508 13.4196L6.4098 13.8449L6.4017 13.8368C6.35454 13.9144 6.27786 13.9711 6.18678 13.9913L4.5642 14.3531C4.5399 14.3586 4.51518 14.3612 4.49076 14.3612C4.4022 14.3612 4.31616 14.3263 4.25238 14.2624C4.17054 14.1808 4.13646 14.0631 4.16166 13.9502L4.52328 12.3281C4.54368 12.2371 4.60044 12.1602 4.67796 12.1132L4.66974 12.105L11.5379 5.23646C11.6386 5.13596 11.8022 5.13596 11.9029 5.23676L13.2782 6.61178C13.3789 6.71246 13.3789 6.87602 13.2781 6.9767Z" />
      </g>
      <defs>
        <clipPath id={pathId}>
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
