import React, {
  type ComponentProps,
  type PropsWithChildren,
  useState,
} from "react";

import InfoIcon from "$/lib/components/icons/InfoIcon";
import WarningIcon from "$/lib/components/icons/WarningIcon";
import Button from "$/lib/components/ui/Button";

import ModalContent from "../modal/ModalContent";
import ModalPortal from "../modal/ModalPortal";
import ModalRoot from "../modal/ModalRoot";
import ModalTrigger from "../modal/ModalTrigger";

const warningVariantToDefaultProps = {
  warning: {
    color: "#E20613",
    icon: WarningIcon,
    title: "Supprimer",
    confirmationButtonLabel: "Supprimer",
  },
  info: {
    color: "#556AEB",
    icon: InfoIcon,
    title: "Information",
    confirmationButtonLabel: "Ok",
  },
};

type WarningDialogBaseProps = {
  disabled?: boolean;
  title?: string;
  variant?: keyof typeof warningVariantToDefaultProps;
  icon?: (props: ComponentProps<"svg">) => React.JSX.Element;
  color?: string;
  message: string;
  extraInformation?: string;
};

type WarningDialogConfirmProps<T> =
  | {
      hasConfirm?: true;
      entity: T;
      isConfirming: boolean;
      confirmationButtonLabel?: string;
      handleConfirm: (param: T, handleSetOpen: (open: boolean) => void) => void;
    }
  | {
      hasConfirm?: false;
      entity?: never;
      isConfirming?: never;
      confirmationButtonLabel?: never;
      handleConfirm?: never;
    };

type WarningDialogProps<T> = WarningDialogBaseProps &
  WarningDialogConfirmProps<T>;

export function WarningDialog<T>({
  message,
  extraInformation,
  entity,
  children,
  variant = "warning",
  icon,
  color,
  title,
  disabled,
  hasConfirm,
  isConfirming,
  confirmationButtonLabel,
  handleConfirm,
}: PropsWithChildren<WarningDialogProps<T>>) {
  const [open, setOpen] = useState(false);

  const handleSetOpen = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const finalColor = color ?? warningVariantToDefaultProps[variant].color;
  const finalTitle = title ?? warningVariantToDefaultProps[variant].title;
  const FinalIcon = icon ?? warningVariantToDefaultProps[variant].icon;
  const finalConfirmationButtonLabel =
    confirmationButtonLabel ??
    warningVariantToDefaultProps[variant].confirmationButtonLabel;

  return (
    <ModalRoot open={open} handleSetOpen={(newOpen) => setOpen(newOpen)}>
      <ModalTrigger
        disabled={disabled}
        className="disabled:cursor-not-allowed disabled:opacity-50"
      >
        {children}
      </ModalTrigger>
      <ModalPortal>
        <ModalContent
          title={finalTitle}
          style={{ "--color": finalColor }}
          className="w-[calc(100dvw-32px)] rounded-[30px] md:w-[500px] [&>div>h2]:text-[var(--color)] [&>h2]:text-[var(--color)]"
        >
          <div className="flex flex-col items-center gap-2">
            <FinalIcon width={72} height={72} fill={finalColor} />
            <div>
              <p className="text-center text-sm text-grey-600">{message}</p>
              {extraInformation && (
                <p className="mt-1 text-pretty text-center text-xs text-grey-400">
                  {extraInformation}
                </p>
              )}
            </div>
            {hasConfirm && (
              <div className="mt-6 flex w-full gap-2.5 px-[5%] lg:px-[20%]">
                <Button
                  className="flex-1"
                  disabled={disabled || isConfirming}
                  noFill
                  onClick={() => setOpen(false)}
                  style={{ color: finalColor, borderColor: finalColor }}
                >
                  Annuler
                </Button>
                <Button
                  className="flex-1"
                  isLoading={isConfirming}
                  onClick={() => handleConfirm(entity, handleSetOpen)}
                  style={{ backgroundColor: finalColor }}
                >
                  {finalConfirmationButtonLabel}
                </Button>
              </div>
            )}
          </div>
        </ModalContent>
      </ModalPortal>
    </ModalRoot>
  );
}
