import { fr } from "date-fns/locale/fr";
import DatePicker, {
  type ReactDatePickerProps,
  registerLocale,
  setDefaultLocale,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./react-datepicker.scss";

registerLocale("fr", fr);
setDefaultLocale("fr");

export default function DatePickerInput<
  WithRange extends boolean | undefined = undefined,
  WithMultiple extends boolean | undefined = undefined,
>(calendarProps: ReactDatePickerProps<WithRange, WithMultiple>) {
  return <DatePicker locale="fr" {...calendarProps} />;
}
