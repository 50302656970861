import { type ComponentProps, useId } from "react";

export default function ExclamationIcon(svgProps: ComponentProps<"svg">) {
  const pathId = useId();

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      aria-label="Exclamation"
      {...svgProps}
    >
      <g clipPath={`url(#${pathId})`}>
        <path d="M17.626 13.495L11.2675 1.80639C10.2459 0.0865123 7.7555 0.0842272 6.73252 1.80639L0.374304 13.495C-0.670047 15.2524 0.594347 17.4779 2.64125 17.4779H15.3585C17.4037 17.4779 18.6704 15.2542 17.626 13.495ZM9 15.3685C8.41858 15.3685 7.94531 14.8952 7.94531 14.3138C7.94531 13.7324 8.41858 13.2591 9 13.2591C9.58141 13.2591 10.0547 13.7324 10.0547 14.3138C10.0547 14.8952 9.58141 15.3685 9 15.3685ZM10.0547 11.1498C10.0547 11.7312 9.58141 12.2044 9 12.2044C8.41858 12.2044 7.94531 11.7312 7.94531 11.1498V5.87633C7.94531 5.29491 8.41858 4.82164 9 4.82164C9.58141 4.82164 10.0547 5.29491 10.0547 5.87633V11.1498Z" />
      </g>
      <defs>
        <clipPath id={pathId}>
          <rect width="18" height="18" />
        </clipPath>
      </defs>
    </svg>
  );
}
