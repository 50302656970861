import type { ComponentProps } from "react";

export default function AdministrationIcon(svgProps: ComponentProps<"svg">) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      aria-label="Administration"
      {...svgProps}
    >
      <path d="M9.00001 18C8.92493 18 8.92493 18 8.84985 18C8.77477 18 1.49219 16.2732 1.49219 8.23983V3.73514C1.49219 3.43483 1.64234 3.13452 1.94266 3.05944L8.6997 0.0563087C8.92493 -0.0187696 9.15016 -0.0187696 9.30032 0.0563087L16.0574 3.05944C16.3577 3.13452 16.5078 3.43483 16.5078 3.73514V8.23983C16.5078 16.2732 9.22524 18 9.15016 18C9.07509 18 9.07509 18 9.00001 18ZM9.00001 9.7414C6.82274 9.7414 5.2461 10.7174 5.2461 11.9937C5.2461 13.2701 6.82274 14.2461 9.00001 14.2461C11.1773 14.2461 12.7539 13.2701 12.7539 11.9937C12.7539 10.7174 11.1773 9.7414 9.00001 9.7414ZM9.00001 3.73514C7.72368 3.73514 6.74766 4.71116 6.74766 5.98749C6.74766 7.26382 7.72368 8.23983 9.00001 8.23983C10.2763 8.23983 11.2524 7.26382 11.2524 5.98749C11.2524 4.71116 10.2763 3.73514 9.00001 3.73514Z" />
    </svg>
  );
}
