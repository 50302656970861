import type { PropsWithChildren } from "react";

import { useMediaQuery } from "$/lib/hooks/useMediaQuery";

import BottomSheetRoot from "../bottom-sheet/BottomSheetRoot";
import ModalRoot from "../modal/ModalRoot";

type Props = {
  open: boolean;
  handleSetOpen: (open: boolean) => void;
};

export default function ResponsiveDialogRoot(props: PropsWithChildren<Props>) {
  const isMobile = useMediaQuery("(max-width: 767px)");

  if (isMobile) return <BottomSheetRoot {...props} />;

  return <ModalRoot {...props} />;
}
