import { Link, useLocation } from "@tanstack/react-router";
import { type ComponentProps, useEffect, useState } from "react";

import type { UserRoleEnum } from "$/features/user/types/models/user.types";
import { cn } from "$/lib/utils/functions/misc.functions";

import Expandable from "../../common/Expandable";
import SidebarArrowIcon from "../../icons/SidebarArrowIcon";
import type { SideBarLinkProps } from "./Sidebar";
import SidebarDeepLink from "./SidebarDeepLink";

type Props = {
  label: string;
  path: string;
  icon: (props: ComponentProps<"svg">) => JSX.Element;
  closeSidebar?: () => void;
  deepLinks: Omit<SideBarLinkProps, "deepLinks">[];
  role: UserRoleEnum;
};

export default function SidebarLink({
  path,
  icon: Icon,
  label,
  deepLinks,
  role,
  closeSidebar,
}: Props) {
  const { pathname } = useLocation();
  const isActive = pathname.includes(path);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (pathname.includes(path)) setIsExpanded(true);
  }, [pathname, path]);

  return (
    <div>
      <Link
        to={`${path}${deepLinks[0].path}`}
        className={cn(
          "relative flex w-full items-center justify-between px-4 py-2.5 text-xs font-semibold text-grey delay-100 duration-200 md:text-sm",
          isActive && "text-snow",
        )}
        onClick={() => {
          closeSidebar?.();
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            closeSidebar?.();
          }
          if (e.key === " ") {
            setIsExpanded((e) => !e);
          }
        }}
      >
        <div className="flex items-center justify-center gap-4">
          <Icon />
          <span>{label}</span>
        </div>
        <span
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsExpanded((e) => !e);
          }}
        >
          <SidebarArrowIcon
            className={cn("duration-150 ease-in", !isExpanded && "rotate-180")}
          />
        </span>
      </Link>
      <Expandable isExpanded={isExpanded}>
        <div
          {...(!isExpanded && {
            inert: "",
          })}
          className="flex flex-col items-center justify-center self-center pl-6"
        >
          {deepLinks.map(
            (deepLink) =>
              deepLink.allowedRoles.includes(role) && (
                <SidebarDeepLink
                  key={deepLink.path}
                  parentPath={path}
                  closeSidebar={closeSidebar}
                  {...deepLink}
                />
              ),
          )}
        </div>
      </Expandable>
    </div>
  );
}
