import TriangleIcon from "$/lib/components/icons/TriangleIcon";
import { valueOrNothing } from "$/lib/utils/functions/util.functions";

import { useEnhancedTable } from "./EnhancedTableProvider";
import type { EnhancedTableSorting } from "./types";

type Props<TSelectedData> = {
  selector: keyof TSelectedData;
  title: string;
};

export default function EnhancedTableSortableColumnHeader<TSelectedData>({
  selector,
  title,
}: Props<TSelectedData>) {
  const { sorting, handleSetSorting, clearSorting } = useEnhancedTable<
    unknown,
    TSelectedData
  >();

  const handleSort = () => {
    let { key, order } = sorting || {};

    if (key !== selector) {
      key = selector;
      order = "desc";
    } else {
      if (order === "asc") {
        clearSorting();
        return;
      }

      if (order === "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
    }
    handleSetSorting({ key, order });
  };

  return (
    <button
      className="flex items-center gap-2"
      type="button"
      onClick={handleSort}
    >
      {title}
      <SortingCarets sorting={sorting} selector={selector} />
    </button>
  );
}

function SortingCarets<T>({
  selector,
  sorting,
}: {
  sorting: EnhancedTableSorting<T> | undefined;
  selector: keyof T;
}) {
  const { key, order } = sorting || {};

  const isSortedCell = key === selector;

  return (
    <span>
      <TriangleIcon
        width="8"
        height="8"
        opacity={valueOrNothing(isSortedCell && order === "desc", 0.5)}
      />
      <TriangleIcon
        width="8"
        height="8"
        className="rotate-180"
        opacity={valueOrNothing(isSortedCell && order === "asc", 0.5)}
      />
    </span>
  );
}
