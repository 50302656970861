import { useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";

import useApiMutation from "$/lib/hooks/useApiMutation";

import type { CreateDeliveryRequestPayloadType } from "../types/delivery-request-apis.types";
import {
  createDeliveryRequest,
  editDeliveryRequest,
} from "../utils/delivery-request.apis";
import { deliveryRequestsQueryKeys } from "../utils/delivery-request.constants";

type Props = {
  id?: number;
};

export const useMutateDeliveryRequest = ({ id }: Props) => {
  const queryClient = useQueryClient();
  const isEditing = !!id;

  return useApiMutation({
    mutationFn: async (data: CreateDeliveryRequestPayloadType) => {
      if (isEditing) {
        return editDeliveryRequest(id, data);
      } else {
        return createDeliveryRequest(data);
      }
    },

    onSuccess: () => {
      if (isEditing) {
        toast.success("Cahier modifié avec succès");
      } else {
        toast.success("Cahier ajouté avec succès");
      }
      queryClient.invalidateQueries({
        queryKey: deliveryRequestsQueryKeys.getDeliveryRequests,
      });
    },
  });
};
