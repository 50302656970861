import { WarningDialog } from "$/lib/components/common/dialogs/warning-dialog/WarningDialog";
import TrashIcon from "$/lib/components/icons/TrashIcon";

import { useDeleteDeliveryRequest } from "../hooks/useDeleteDeliveryRequest";

type Props = {
  requestId: number;
  isDisabled?: boolean;
};

export default function DeleteDeliveryRequestDialog({
  requestId,
  isDisabled,
}: Props) {
  const { mutate, isPending } = useDeleteDeliveryRequest();

  return (
    <WarningDialog<number>
      hasConfirm
      entity={requestId}
      handleConfirm={(requestId, handleSetOpen) =>
        mutate(requestId, {
          onSuccess: () => {
            handleSetOpen(false);
          },
        })
      }
      title="Supprimer"
      message="Êtes-vous sûr de vouloir supprimer ce cahier ?"
      disabled={isDisabled}
      // TODO: fix this to use query state
      isConfirming={isPending}
    >
      <TrashIcon />
    </WarningDialog>
  );
}
