import * as Dialog from "@radix-ui/react-dialog";
import { type PropsWithChildren } from "react";

import { useRootDialog } from "$/lib/providers/DialogProvider";
import { cn } from "$/lib/utils/functions/misc.functions";

import XIcon from "../../../icons/XIcon";

type Props = {
  title?: string;
  description?: string;
  className?: string;
  style?: React.CSSProperties;
};

export default function ModalContent({
  title,
  description,
  className,
  style,
  children,
}: PropsWithChildren<Props>) {
  const rootDialog = useRootDialog();

  const hasTitleOnly = !!title && !description && !children;
  const hasChildrenOnly = !title && !description && !!children;

  return (
    <div
      className={cn(
        "relative w-[750px] max-w-[90vw] rounded-3xl bg-snow p-8 text-black md:min-w-96",
        hasChildrenOnly && "pt-12",
        className,
      )}
      style={style}
    >
      <Dialog.Close asChild>
        <button
          type="button"
          title="Close dialog"
          aria-label="Close dialog"
          onClick={() => rootDialog.handleSetOpen(false)}
          className={cn(
            "hover:bg-black-light/15 focus:shadow-black-light absolute right-8 top-8 inline-flex h-6 w-6 appearance-none items-center justify-center rounded-circle p-1.5 duration-200 focus:shadow-ring",
            hasTitleOnly && "top-4",
          )}
        >
          <XIcon />
        </button>
      </Dialog.Close>

      {!!title && (
        <Dialog.Title
          className={cn("text-lg font-semibold", !hasTitleOnly && "mb-8")}
        >
          {title}
        </Dialog.Title>
      )}
      {!!description && (
        <Dialog.Description
          className={cn("text-sm leading-normal", !!children && "mb-5")}
        >
          {description}
        </Dialog.Description>
      )}
      {children}
    </div>
  );
}
