import { z } from "zod";

export function preprocessedString<T extends z.ZodTypeAny>(input: T) {
  return z.preprocess((str) => {
    if (str === "" || str === null || str === undefined) {
      return undefined;
    }
    return str;
  }, input);
}

export function preprocessedNumericString<T extends z.ZodTypeAny>(input: T) {
  return z.preprocess((arg) => {
    if (arg === null || arg === undefined) return undefined;

    if (typeof arg === "string") {
      if (arg === "") {
        return undefined;
      }

      const formattedArg = arg.replace(",", ".");

      return Number(formattedArg);
    }

    if (typeof arg === "number") {
      return arg;
    }

    return undefined;
  }, input);
}

export function preprocessedDateString<T extends z.ZodTypeAny>(input: T) {
  return z.preprocess((arg) => {
    if (arg === null || arg === undefined) return undefined;

    if (typeof arg === "string" || typeof arg === "number") {
      if (arg === "") {
        return undefined;
      }
      return new Date(arg);
    }

    if (arg instanceof Date) {
      return arg;
    }

    return undefined;
  }, input);
}

export function validatePhoneNumberZodString(input: z.ZodString) {
  // there is no more phone number validation, but we can add it back if needed
  return input.refine(() => true);
  // return input.refine((val) => isValidPhoneNumber(val), {
  //   message: "Le numéro de téléphone n'est pas valide",
  // });
}
