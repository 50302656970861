import { formatDate, isValid as isValidDate } from "date-fns";
import { useState } from "react";

import CSVIcon from "$/lib/components/icons/CSVIcon";
import DownloadIcon from "$/lib/components/icons/DownloadIcon";
import RedCancelIcon from "$/lib/components/icons/RedCancelIcon";
import TableButton from "$/lib/components/ui/table/TableButton";
import { formatDateWithHours } from "$/lib/utils/functions/date.functions";
import type { DeepKeys, KeyValueObject } from "$/types/util.types";

import { useEnhancedTable } from "./EnhancedTableProvider";
import { filterEnhancedTableSelectedDataFields } from "./utils";

type Props<TSelectedData> = {
  fileName: string;
  headersMapping?: { [key in DeepKeys<TSelectedData>]?: string };
  // maybe we should add a DeepValues type to avoid using any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valuesMapping?: { [key in DeepKeys<TSelectedData>]?: (value: any) => string };
} & (
  | { excludedFields?: DeepKeys<TSelectedData>[]; includedFields?: never }
  | { includedFields?: DeepKeys<TSelectedData>[]; excludedFields?: never }
);

export default function EnhancedTableExportToCSV<
  TSelectedData extends KeyValueObject,
>({
  excludedFields,
  includedFields,
  fileName,
  headersMapping,
  valuesMapping,
}: Props<TSelectedData>) {
  const { selectedRows, getSelectedRows } = useEnhancedTable<
    unknown,
    TSelectedData,
    KeyValueObject
  >();
  const [csvUrl, setCsvUrl] = useState<string>("");

  const prepareCsv = (): void => {
    const data = getSelectedRows();

    if (!data.length) {
      return;
    }

    const filterType = excludedFields
      ? "exclude"
      : includedFields
        ? "include"
        : undefined;
    const filteredFields = excludedFields || includedFields || [];

    const filteredData = filterEnhancedTableSelectedDataFields<TSelectedData>(
      data,
      filterType,
      filteredFields,
    );

    // avoid unnecessary processing
    const firstRow = filteredData.at(0);
    const keys = firstRow ? Object.keys(firstRow) : [];
    const translatedKeys = keys.map((key) => {
      return headersMapping?.[key as DeepKeys<TSelectedData>] || key;
    });

    //replace `.` with `-` in header titles
    const formattedKeys = translatedKeys.map((key) => {
      return key.includes(".") ? key.replace(/\./g, "-") : key;
    });

    const csvHeader = formattedKeys.join(",");
    const csvContent = filteredData
      .map((row: KeyValueObject) => {
        return keys
          .map((key: string) => {
            if (
              valuesMapping &&
              valuesMapping[key as DeepKeys<TSelectedData>]
            ) {
              return (
                valuesMapping[key as DeepKeys<TSelectedData>]?.(row[key]) || ""
              );
            }

            const value =
              row[key] !== undefined
                ? String(row[key]).replace(/"/g, '""')
                : "";
            if (
              value.includes(",") ||
              value.includes("\n") ||
              value.includes('"')
            ) {
              return `"${value}"`;
            }

            if (isValidDate(new Date(value)))
              return formatDate(value, "dd/MM/yyyy");

            return value;
          })
          .join(",");
      })
      .join("\n");

    const csvData = `${csvHeader}\n${csvContent}`;
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    setCsvUrl(url);
  };

  if (csvUrl) {
    return (
      <div className="relative">
        <a
          className="flex min-h-10 items-center justify-center gap-2 rounded-md bg-blue-light px-4 py-2 text-xs transition-all duration-200 hover:opacity-75 hover:shadow-sm active:opacity-50 disabled:opacity-50"
          href={csvUrl}
          download={`${fileName}-${formatDateWithHours(new Date(), true)}.csv`}
          onClick={() => {
            setCsvUrl("");
          }}
        >
          <DownloadIcon />
          Télécharger le CSV
        </a>
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            setCsvUrl("");
          }}
          className="absolute -right-1 -top-1 p-0 hover:cursor-pointer"
        >
          <RedCancelIcon width={16} height={16} />
        </button>
      </div>
    );
  }

  return (
    <div className="relative">
      <TableButton
        onClick={prepareCsv}
        disabled={!Object.keys(selectedRows).length}
      >
        <CSVIcon />
        Générer CSV
      </TableButton>
    </div>
  );
}
