import { type ComponentProps, useId } from "react";

const CNRIcon = (svgProps: ComponentProps<"svg">) => {
  const pathId = useId();
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      aria-label="CNR"
      {...svgProps}
    >
      <g clipPath={`url(#${pathId})`}>
        <path d="M5.86264 0.8965L5.53637 0.640625C5.06302 1.05207 0.127234 4.72467 0 8.93838C3.51961e-05 11.9914 2.48347 14.4751 5.53637 14.4751C8.58928 14.4751 11.0731 11.9914 11.0731 8.93845C11.0731 5.03098 6.07523 1.06333 5.86264 0.8965ZM5.10839 7.36694C5.33963 7.54855 5.76268 7.71116 6.14562 7.84666C7.61241 8.31819 7.48289 10.4318 6.03792 10.8936V11.5891H4.98204V10.9495C4.58158 10.9237 4.04829 10.5576 3.67204 10.3041L4.25031 9.42028C4.674 9.68664 4.95036 9.97441 5.50646 9.92109C6.09029 9.92858 6.45538 9.09292 5.79404 8.84236C5.30654 8.6699 4.80746 8.4728 4.45691 8.19792C3.62646 7.56714 4.03055 6.12051 4.98204 5.80846V5.14818H6.03792V5.73279C6.60264 5.84873 6.85366 6.04399 7.27259 6.25968L6.77176 7.1892C6.35919 7.00414 5.9961 6.62687 5.35511 6.79638C5.1167 6.89577 4.97542 7.18751 5.10839 7.36694Z" />
        <path d="M14.7428 9.34181L14.4169 9.08594C14.003 9.46261 10.9703 11.6042 10.8335 14.4097C10.8336 16.3856 12.441 17.993 14.4169 17.993C16.3928 17.993 18.0002 16.3856 18.0002 14.4097C18.0002 11.927 14.8758 9.44635 14.7428 9.34181Z" />
        <path d="M13.3413 5.60645C14.5939 5.60645 15.6132 4.58717 15.6132 3.33454C15.468 1.50509 13.8549 0.462581 13.3413 0.0078125C12.8318 0.458111 11.212 1.50914 11.0693 3.33451C11.0694 4.58717 12.0887 5.60645 13.3413 5.60645Z" />
      </g>
      <defs>
        <clipPath id={pathId}>
          <rect width="18" height="18" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CNRIcon;
