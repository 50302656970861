import type { ComponentProps } from "react";

export default function ArrowLeftIcon(svgProps: ComponentProps<"svg">) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 14"
      fill="currentColor"
      aria-label="Arrow Left"
      {...svgProps}
    >
      <path d="M18.3338 6.16682H3.67881L8.08964 1.75598C8.16923 1.67911 8.23272 1.58716 8.27639 1.48549C8.32007 1.38382 8.34305 1.27447 8.34402 1.16382C8.34498 1.05317 8.32389 0.943434 8.28199 0.841021C8.24009 0.738607 8.17821 0.645564 8.09997 0.56732C8.02173 0.489076 7.92868 0.427198 7.82627 0.385297C7.72385 0.343397 7.61412 0.322312 7.50347 0.323274C7.39282 0.324235 7.28347 0.347224 7.1818 0.390898C7.08013 0.434572 6.98818 0.498057 6.91131 0.577649L1.07797 6.41098C0.921747 6.56726 0.833984 6.77918 0.833984 7.00015C0.833984 7.22112 0.921747 7.43304 1.07797 7.58932L6.91131 13.4227C7.06848 13.5744 7.27898 13.6584 7.49747 13.6565C7.71597 13.6546 7.92498 13.567 8.07949 13.4125C8.234 13.258 8.32164 13.049 8.32353 12.8305C8.32543 12.612 8.24144 12.4015 8.08964 12.2443L3.67881 7.83348H18.3338C18.5548 7.83348 18.7668 7.74569 18.9231 7.5894C19.0793 7.43312 19.1671 7.22116 19.1671 7.00015C19.1671 6.77914 19.0793 6.56717 18.9231 6.41089C18.7668 6.25461 18.5548 6.16682 18.3338 6.16682Z" />
    </svg>
  );
}
