import {
  CostSimulationTypeEnum,
  DayOfWeekEnum,
  DeliveryRequestStatusEnum,
} from "$/features/delivery-request/types/delivery-request.types";
import { WorkShiftEnum } from "$/features/driver/types/models/driver.types";
import {
  ModificationTargetEntityType,
  ModificationTypeEnum,
} from "$/features/modifications/types/modification.types";
import { UserRoleEnum } from "$/features/user/types/models/user.types";
import {
  FuelTypeEnum,
  VehiclePayloadTypeEnum,
  VehicleTrailerTypeEnum,
  VehicleTypeEnum,
} from "$/features/vehicles/types/models/vehicle.types";
import type { ColorLabels } from "$/types/misc.types";
import { CNRIndexTypeEnum } from "$/types/models/CNR-index";
import type { JourneyStatusEnum } from "$/types/models/journey.types";

export const fuelTypeEnumToLabel: Record<FuelTypeEnum, string> = {
  [FuelTypeEnum.ELECTRIC]: "Electrique",
  [FuelTypeEnum.GASOLINE]: "Gasoil",
  [FuelTypeEnum.BIOGAZ]: "Biogaz",
};

export const deliveryRequestStatusEnumToLabel: Record<
  DeliveryRequestStatusEnum,
  string
> = {
  [DeliveryRequestStatusEnum.DRAFT]: "Brouillon",
  [DeliveryRequestStatusEnum.IN_PROGRESS]: "En cours",
  [DeliveryRequestStatusEnum.COMPLETED]: "Terminée",
  [DeliveryRequestStatusEnum.SENT_TO_CLIENT]: "Envoyée au client",
  [DeliveryRequestStatusEnum.VALIDATED_BY_CLIENT]: "Validée par le client",
  [DeliveryRequestStatusEnum.WAITING_FOR_CONCEPTION]: "En conception",
};

export const vehiclePayloadTypeEnumToLabel: Record<
  VehiclePayloadTypeEnum,
  string
> = {
  [VehiclePayloadTypeEnum.PALLET]: "Palette",
  [VehiclePayloadTypeEnum.BULK]: "En vrac",
};

export const vehicleTrailerTypeEnumToLabel: Record<
  VehicleTrailerTypeEnum,
  string
> = {
  [VehicleTrailerTypeEnum.TRAILER]: "Remorque",
  [VehicleTrailerTypeEnum.COVERED_AERIAL]: "Aérienne Bachée",
  [VehicleTrailerTypeEnum.CLASSIC_AERIAL]: "Aérienne Classique",
  [VehicleTrailerTypeEnum.REFRIGERATED_AERIAL]: "Aérienne Frigo",
  [VehicleTrailerTypeEnum.MEGA_AERIAL]: "Aérienne Méga",
  [VehicleTrailerTypeEnum.CLASSIC_SEMI_TRAILER]: "Semi-remorque Classique",
  [VehicleTrailerTypeEnum.REFRIGERATED_SEMI_TRAILER]: "Semi-Remorque Frigo",
  [VehicleTrailerTypeEnum.MEGA_REFRIGERATED_SEMI_TRAILER]:
    "Semi-Remorque Méga & Frigo",
};

export const vehicleTypeEnumToLabel: Record<VehicleTypeEnum, string> = {
  [VehicleTypeEnum.TRACTOR_GO]: "Tracteur GO",
  [VehicleTypeEnum.TRACTOR_GAZ]: "Tracteur GAZ",
  [VehicleTypeEnum.CAISSE_MOBILE_GO]: "Caisse Mobile GO",
  [VehicleTypeEnum.CAISSE_MOBILE_GAZ]: "Caisse Mobile GAZ",
};

export const dayOfWeekEnumToLabel: Record<DayOfWeekEnum, string> = {
  [DayOfWeekEnum.MONDAY]: "Lundi",
  [DayOfWeekEnum.TUESDAY]: "Mardi",
  [DayOfWeekEnum.WEDNESDAY]: "Mercredi",
  [DayOfWeekEnum.THURSDAY]: "Jeudi",
  [DayOfWeekEnum.FRIDAY]: "Vendredi",
  [DayOfWeekEnum.SATURDAY]: "Samedi",
  [DayOfWeekEnum.SUNDAY]: "Dimanche",
};

export const modificationTargetEntityTypeToLabel: Record<
  ModificationTargetEntityType,
  string
> = {
  [ModificationTargetEntityType.LINE]: "Trajet",
  [ModificationTargetEntityType.POOL]: "Tournée",
};

export const modificationTypeToChangeLabel: Record<
  ModificationTypeEnum,
  string
> = {
  [ModificationTypeEnum.DRIVER_ON_POOL]: "Le chauffeur a changé",
  [ModificationTypeEnum.VEHICLE_ON_POOL]: "Le véhicule a changé",
  [ModificationTypeEnum.DRIVER_ON_LIVE_LINE]: "Le chauffeur a changé",
  [ModificationTypeEnum.VEHICLE_ON_LIVE_LINE]: "Le véhicule a changé",
  [ModificationTypeEnum.POOL_ON_LIVE_LINE]: "Tournée a changé",
};

export const DeliveryJourneyStatusEnumToLabel: Record<
  JourneyStatusEnum,
  string
> = {
  REJECTED_BY_DRIVER: "Rejeté par",
  STAND_BY: "En attente",
  ONGOING: "En cours",
  COMPLETED: "Terminé",
  CANCELLED: "Annulé",
};

export const driverShiftEnumToLabel: Record<WorkShiftEnum, string> = {
  [WorkShiftEnum.DAY]: "Jour",
  [WorkShiftEnum.NIGHT]: "Nuit",
  [WorkShiftEnum.FULL]: "Jour / Nuit",
};

export const colorShades: Record<
  ColorLabels,
  { text: string; backGround: string }
> = {
  yellow: { text: "text-yellow", backGround: "bg-yellow-100" },
  grey: { text: "text-grey", backGround: "bg-grey-100" },
  green: { text: "text-green", backGround: "bg-green-100" },
  red: { text: "text-primary", backGround: "bg-primary-100" },
};
export const CNRTypeEnumToLabel: Record<CNRIndexTypeEnum, string> = {
  [CNRIndexTypeEnum.GAS]: "Gaz",
  [CNRIndexTypeEnum.ELECTRIC]: "Electrique",
  [CNRIndexTypeEnum.GAZOLINE_AVERAGE_MONTHLY_TANK]:
    "Diesel - Réservoir mensuel moyen",
  [CNRIndexTypeEnum.GAZOLINE_END_OF_MONTH_TANK]:
    "Diesel - Réservoir de fin de mois",
  [CNRIndexTypeEnum.GAZOLINE_AVERAGE_MONTHLY_PUMP]:
    "Diesel - Pompe mensuelle moyenne",
  [CNRIndexTypeEnum.GAZOLINE_END_OF_MONTH_PUMP]:
    "Diesel - Pompe de fin de mois",
};

export const userRoleEnumToLabel: Record<UserRoleEnum, string> = {
  [UserRoleEnum.ADMIN]: "Admin",
  [UserRoleEnum.CONCEPTOR]: "Conceptor",
  [UserRoleEnum.DRIVER]: "Chauffeur",
  [UserRoleEnum.OPERATOR]: "Operator",
  [UserRoleEnum.SALES]: "Commercial",
};

export const costSimulationTypeEnumToLabel: Record<
  CostSimulationTypeEnum,
  string
> = {
  [CostSimulationTypeEnum.MATERIAL]: "Matériel",
  [CostSimulationTypeEnum.STAFFING]: "Personnel",
  [CostSimulationTypeEnum.OTHER]: "Autre",
};
