import type { ComponentProps } from "react";

import FormComboboxInput from "$/lib/components/common/form/FormComboboxInput";
import type { Primitive } from "$/types/util.types";

import AutocompleteProvider, { useAutoComplete } from "./AutocompleteProvider";

type Props<TValue extends Primitive, TQueryData> = ComponentProps<
  typeof AutocompleteProvider<TValue, TQueryData>
> & {
  name: string;
  label: string;
  disabled?: boolean;
  hideLabel?: boolean;
  clearable?: boolean;
};

export default function ManagedFormCombobox<
  TValue extends Primitive,
  TQueryData,
>(props: Props<TValue, TQueryData>) {
  return (
    <AutocompleteProvider {...props}>
      <ManagedFormComboboxInner
        disabled={props.disabled}
        clearable={props.clearable}
        name={props.name}
        label={props.label}
        hideLabel={props.hideLabel}
      />
    </AutocompleteProvider>
  );
}

function ManagedFormComboboxInner({
  name,
  label,
  disabled,
  hideLabel,
  clearable,
}: Pick<
  Props<Primitive, unknown>,
  "name" | "label" | "hideLabel" | "clearable" | "disabled"
>) {
  const { options, multi, selected, handleSearch } = useAutoComplete();

  const selectionProps = multi
    ? {
        multi: true as const,
        selected: selected as Primitive[],
      }
    : {
        multi: false as const,
        selected: selected as Primitive,
      };

  return (
    <FormComboboxInput
      {...selectionProps}
      disabled={disabled}
      clearable={clearable}
      name={name}
      label={label}
      hideLabel={hideLabel}
      options={options}
      onSearch={handleSearch}
    />
  );
}
