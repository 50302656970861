import type { FieldValues, PathValue } from "react-hook-form";
import { z } from "zod";

import FormColorInput from "$/lib/components/common/form/FormColorInput";
import FormDateInput from "$/lib/components/common/form/FormDateInput";
import FormDatetimeInput from "$/lib/components/common/form/FormDateTimeInput";
import FormNumberInput from "$/lib/components/common/form/FormNumberInput";
import FormTextAreaInput from "$/lib/components/common/form/FormTextAreaInput";
import FormTextInput from "$/lib/components/common/form/FormTextInput";
import FormTimeInput from "$/lib/components/common/form/FormTimeInput";
import { valueOrNothing } from "$/lib/utils/functions/util.functions";
import {
  preprocessedDateString,
  preprocessedNumericString,
  preprocessedString,
} from "$/lib/utils/zod.utils";
import type { WithReadonly } from "$/types/util.types";

import {
  type DynamicFormInput,
  DynamicFormInputType,
  type InferDynamicFormGroupsInputs,
  type InferDynamicFormInputsZodSchema,
} from "./types";

export function generateDynamicFormSchema<
  const T extends WithReadonly<DynamicFormInput>[],
>(inputs: WithReadonly<DynamicFormInput>[] & T) {
  const zodSchemaMap = new Map<string, z.ZodTypeAny>();

  for (const input of inputs) {
    zodSchemaMap.set(input.name, generateDynamicFormInputValidator(input));
  }

  const zodSchema = z.object(
    Object.fromEntries(
      zodSchemaMap.entries(),
    ) as InferDynamicFormInputsZodSchema<T>,
  );

  return zodSchema;
}

export function generateDynamicFormInputValidator(input: DynamicFormInput) {
  const isRequired = !input.optional;
  const requiredError = valueOrNothing(
    isRequired,
    input.requiredMessage || "Veuillez remplir ce champ",
  );

  switch (input.type) {
    case DynamicFormInputType.SELECT: {
      let selectValidation: z.ZodTypeAny = z.enum(
        input.options.map((o) => o.value) as [string, ...string[]],
        {
          required_error: requiredError,
        },
      );
      if (input.extraParsing) {
        selectValidation = input.extraParsing(
          selectValidation as z.ZodEnum<[string, ...string[]]>,
        );
      }
      if (!isRequired) {
        selectValidation = z.optional(selectValidation);
      }

      return preprocessedString(selectValidation);
    }

    case DynamicFormInputType.COMBOBOX: {
      let comboboxValidation: z.ZodTypeAny =
        input.optionsType === "string"
          ? z.string({ required_error: requiredError })
          : z.number({ required_error: requiredError });

      if (input.multi) {
        comboboxValidation = z.array(comboboxValidation);
        if (isRequired) {
          comboboxValidation = (comboboxValidation as z.ZodArray<z.ZodTypeAny>)
            .min(1, requiredError)
            .default([]);
        }
      }

      if (input.extraParsing) {
        if (input.multi) {
          comboboxValidation = input.extraParsing(
            comboboxValidation as z.ZodArray<z.ZodString | z.ZodNumber>,
          );
        } else {
          comboboxValidation = input.extraParsing(
            comboboxValidation as z.ZodString | z.ZodNumber,
          );
        }
      }
      if (!isRequired) {
        comboboxValidation = z.optional(comboboxValidation);
      }

      return comboboxValidation;
    }

    case DynamicFormInputType.NUMBER: {
      let numberValidation: z.ZodTypeAny = z.number({
        required_error: requiredError,
      });
      if (input.extraParsing) {
        numberValidation = input.extraParsing(numberValidation as z.ZodNumber);
      }
      if (!isRequired) {
        numberValidation = z.optional(numberValidation);
      }

      return preprocessedNumericString(numberValidation);
    }

    case DynamicFormInputType.DATE:
    case DynamicFormInputType.DATETIME: {
      let dateValidation: z.ZodTypeAny = z.date({
        required_error: requiredError,
      });
      if (input.extraParsing) {
        dateValidation = input.extraParsing(dateValidation as z.ZodDate);
      }
      if (!isRequired) {
        dateValidation = z.optional(dateValidation);
      }

      return preprocessedDateString(dateValidation).transform((val) => {
        if (!(val instanceof Date)) {
          return val;
        }

        if (input.type === DynamicFormInputType.DATE) {
          return val.toISOString().split("T")[0];
        }

        return val.toISOString().slice(0, -8);
      });
    }
  }

  let stringValidation: z.ZodTypeAny = z
    .string({ required_error: requiredError })
    .trim();
  if (isRequired) {
    stringValidation = (stringValidation as z.ZodString).min(1, requiredError);
  }

  switch (input.type) {
    case DynamicFormInputType.COLOR:
      stringValidation = (stringValidation as z.ZodString).regex(
        /^#[0-9A-F]{6}$/i,
        {
          message: "Invalid color format",
        },
      );
      break;

    case DynamicFormInputType.TIME:
      stringValidation = (stringValidation as z.ZodString).regex(
        /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
        {
          message: "Invalid time format",
        },
      );
      break;
  }

  if (input.extraParsing) {
    stringValidation = input.extraParsing(stringValidation as z.ZodString);
  }

  if (!isRequired) {
    return preprocessedString(stringValidation.optional());
  }

  return preprocessedString(stringValidation);
}

export function flattenDynamicFormGroupsInputs<
  const T extends WithReadonly<
    {
      inputs: WithReadonly<DynamicFormInput[]>;
    }[]
  >,
>(groups: T) {
  const inputs = [];
  for (const group of groups) {
    for (const input of group.inputs) {
      inputs.push(input);
    }
  }
  return inputs as InferDynamicFormGroupsInputs<T>;
}

export function getDynamicFormInputComponent(input: DynamicFormInput) {
  switch (input.type) {
    case DynamicFormInputType.TEXT:
      return FormTextInput;
    case DynamicFormInputType.NUMBER:
      return FormNumberInput;
    case DynamicFormInputType.SELECT:
      return FormTextInput; // for type consistency we will keep this here and handle it in the rendering
    case DynamicFormInputType.COMBOBOX:
      return FormTextInput; // for type consistency we will keep this here and handle it in the rendering
    case DynamicFormInputType.DATE:
      return FormDateInput;
    case DynamicFormInputType.DATETIME:
      return FormDatetimeInput;
    case DynamicFormInputType.TIME:
      return FormTimeInput;
    case DynamicFormInputType.LONG_TEXT:
      return FormTextAreaInput;
    case DynamicFormInputType.COLOR:
      return FormColorInput;
  }
}

export function getDynamicInputComponentProps<
  T extends FieldValues = FieldValues,
>(input: DynamicFormInput<T>) {
  if (input.type === "select") {
    const { parseValue, optional, componentProps, ...inputRest } = input;

    const parsedParseValue = valueOrNothing(
      !!parseValue,
      (v: string) => parseValue?.(v) as PathValue<T, typeof input.name>,
    );

    return {
      placeholder: input.label,
      required: !optional,
      parseValue: parsedParseValue,
      ...componentProps,
      ...inputRest,
    };
  }

  if (input.type === "combobox") {
    return {
      placeholder: input.label,
      required: !input.optional,
      multi: input.multi,
      ...input.componentProps,
      ...input,
    };
  }

  return {
    placeholder: input.label,
    required: !input.optional,
    ...input.componentProps,
    ...input,
  };
}
