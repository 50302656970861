import type { Company } from "$/features/company/types/company.types";
import type { DeliveryRequestLine } from "$/features/delivery-request-details/types/delivery-request-details.types";
import type { DriverCertification } from "$/features/driver/types/models/driver.types";
import type {
  VehicleEquipment,
  VehicleTypeEnum,
} from "$/features/vehicles/types/models/vehicle.types";
import type { CNRIndex } from "$/types/models/CNR-index";

export const DayOfWeekEnum = {
  MONDAY: "MONDAY",
  TUESDAY: "TUESDAY",
  WEDNESDAY: "WEDNESDAY",
  THURSDAY: "THURSDAY",
  FRIDAY: "FRIDAY",
  SATURDAY: "SATURDAY",
  SUNDAY: "SUNDAY",
} as const;
export type DayOfWeekEnum = (typeof DayOfWeekEnum)[keyof typeof DayOfWeekEnum];

export const DeliveryRequestStatusEnum = {
  DRAFT: "DRAFT",
  SENT_TO_CLIENT: "SENT_TO_CLIENT",
  VALIDATED_BY_CLIENT: "VALIDATED_BY_CLIENT",
  WAITING_FOR_CONCEPTION: "WAITING_FOR_CONCEPTION",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
} as const;
export type DeliveryRequestStatusEnum =
  (typeof DeliveryRequestStatusEnum)[keyof typeof DeliveryRequestStatusEnum];

export const CostSimulationTypeEnum = {
  MATERIAL: "MATERIAL",
  STAFFING: "STAFFING",
  OTHER: "OTHER",
} as const;
export type CostSimulationTypeEnum =
  (typeof CostSimulationTypeEnum)[keyof typeof CostSimulationTypeEnum];

export type DeliveryRequest = {
  id: number;
  status: DeliveryRequestStatusEnum;
  name: string;
  reference: string;
  vehicleType: VehicleTypeEnum;
  startDate: string;
  endDate: string;
  fuelShare: number;
  price: number;

  client: Company;
  clientId: number;
  cnrIndex: CNRIndex;
  cnrIndexId: number;
  costSimulations: DeliveryRequestCostSimulation[];
  requiredCertifications: DriverCertification[];
  requiredEquipment: VehicleEquipment[];
  lines: DeliveryRequestLine[];
};

export type DeliveryRequestCostSimulation = {
  id: number;
  type: CostSimulationTypeEnum;
  name: string;
  rawCost: number;
  unitBasis: number;
  applicableRate: number;
};
