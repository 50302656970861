import { type ComponentProps, useEffect, useState } from "react";
import {
  type FieldValues,
  type Path,
  type PathValue,
  useFormContext,
} from "react-hook-form";

import { getDeepFormError } from "$/lib/utils/functions/misc.functions";
import type { FormInput } from "$/types/misc.types";

import SelectInput from "../../ui/inputs/SelectInput";

type Props<T extends FieldValues, TPath extends Path<T>> = FormInput<
  T,
  TPath
> & {
  placeholder?: string;
  options: ComponentProps<typeof SelectInput>["options"];
  className?: string;
  optionsClassName?: string;
  parseValue?: (value: string) => PathValue<T, TPath>;
};

export default function FormSelectInput<
  T extends FieldValues,
  TPath extends Path<T> = Path<T>,
>({
  name,
  label,
  placeholder,
  options,
  className,
  optionsClassName,
  parseValue,
  ...registerOptions
}: Props<T, TPath>) {
  const [key, setKey] = useState<number>(+new Date());
  const {
    register,
    setValue,
    clearErrors,
    formState: { errors, isSubmitted, isDirty, defaultValues },
  } = useFormContext<T>();

  useEffect(() => {
    if (!isSubmitted && !isDirty) {
      setKey(+new Date());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  const handleValueChange = (value: string) => {
    if (value) {
      clearErrors(name);
    }

    if (parseValue !== undefined) {
      setValue(name, parseValue(value), { shouldDirty: true });
      return;
    }

    setValue(name, value as PathValue<T, TPath>, { shouldDirty: true });
  };

  const error = getDeepFormError(errors, name.split("."));

  return (
    <SelectInput
      {...register(name, registerOptions)}
      defaultValue={defaultValues?.[name]}
      key={`select-${name}-${key}`}
      onValueChange={handleValueChange}
      error={error?.message?.toString()}
      label={label}
      placeholder={placeholder}
      options={options}
      className={className}
      optionsClassName={optionsClassName}
    />
  );
}
