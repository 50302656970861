import {
  type ForwardedRef,
  type ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";

import SearchIcon from "$/lib/components/icons/SearchIcon";
import TriangleIcon from "$/lib/components/icons/TriangleIcon";
import useAccessibleDropdown from "$/lib/hooks/useAccessibleDropdown";
import { cn } from "$/lib/utils/functions/misc.functions";
import type { Option } from "$/types/util.types";

import InputError from "../InputError";
import ComboboxInputOptionsList from "./ComboboxInputOptionsList";

export type ComboboxOption<T> = Option<T> & {
  render?: (props: {
    label: string;
    value: T;
    isSelected: boolean;
    multi: boolean;
  }) => ReactNode;
};

export type MultiProps<T> =
  | {
      multi: true;
      selected?: T[];
    }
  | {
      multi?: false;
      selected?: T;
    };

type Props<T> = {
  label?: string;
  hideLabel?: boolean;
  placeholder: string;
  options: ComboboxOption<T>[];
  isSearching?: boolean;
  error?: string;
  disabled?: boolean;
  containerClassName?: string;
  buttonClassName?: string;
  overlayClassName?: string;
  onSelect: (option: T, index: number) => void;
  onSearch?: (search: string) => void;
  onClear?: () => void;
} & MultiProps<T>;

function ComboboxInputInner<T>(
  {
    label,
    hideLabel,
    placeholder,
    options,
    isSearching,
    selected,
    multi,
    error,
    disabled,
    containerClassName,
    buttonClassName,
    overlayClassName,
    onSelect,
    onSearch,
    onClear,
  }: Props<T>,
  forwardedTriggerRef: ForwardedRef<HTMLButtonElement>,
) {
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(-1);
  const [selectionLabel, setSelectionLabel] = useState("");

  const listId = useId();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLButtonElement | null>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const optionsListRef = useRef<HTMLUListElement | null>(null);

  const { isExpanded, overlayProps, overlayPlacement, close, toggleExpanded } =
    useAccessibleDropdown({
      containerRef,
      triggerRef,
      overlayRef,
    });

  // make focused option visible in the list by scrolling to it
  const handleScrollToOption = useCallback((focusedIndex: number) => {
    if (focusedIndex === -1) return;

    const list = optionsListRef.current;
    if (!list) return;

    const focusedOption = list.childNodes[focusedIndex] as
      | HTMLElement
      | undefined;

    if (!focusedOption) return;

    const listRect = list.getBoundingClientRect();
    const optionRect = focusedOption.getBoundingClientRect();

    const PADDING = 10;

    if (optionRect.bottom > listRect.bottom) {
      list.scrollTo({
        top: list.scrollTop + optionRect.bottom - listRect.bottom + PADDING,
      });
    }

    if (optionRect.top < listRect.top) {
      list.scrollTo({
        top: list.scrollTop - (listRect.top - optionRect.top) - PADDING,
      });
    }
  }, []);

  const handleSelect = useCallback(
    (option: T, index: number) => {
      onSelect(option, index);
      if (!multi) {
        close();
        optionsListRef.current?.scrollTo({ top: 0 });
      }
    },
    [multi, onSelect, close],
  );

  useEffect(() => {
    if (!multi) {
      const selectedOption = options.find(
        (option) => option.value === selected,
      );

      if (selectedOption) {
        setSelectionLabel(selectedOption.label);
      }
      if (!selected) {
        setSelectionLabel("");
      }
    }
  }, [multi, selected, options]);

  useEffect(() => {
    if (isExpanded) {
      // focus the search input when the dropdown is expanded
      searchInputRef.current?.focus({
        preventScroll: true,
      });
      return;
    }

    // clear the search input when the dropdown is closed
    const searchInput = searchInputRef.current;
    if (searchInput) {
      searchInput.value = "";
      onSearch?.("");
    }

    // scroll the list to the top when the dropdown is closed
    optionsListRef.current?.scrollTo({ top: 0 });

    setFocusedOptionIndex(-1);
  }, [isExpanded, onSearch]);

  // add keyboard navigation for the dropdown
  useEffect(() => {
    if (!isExpanded) return;

    const handleArrowsMovement = (e: KeyboardEvent) => {
      if (e.key === "ArrowDown") {
        setFocusedOptionIndex((p) => {
          if (p === -1) return 0;
          const newFocusedOptionIndex = Math.min(p + 1, options.length - 1);
          handleScrollToOption(newFocusedOptionIndex);
          return newFocusedOptionIndex;
        });
      }

      if (e.key === "ArrowUp") {
        setFocusedOptionIndex((p) => {
          const newFocusedOptionIndex = Math.max(p - 1, 0);
          handleScrollToOption(newFocusedOptionIndex);
          return newFocusedOptionIndex;
        });
      }
    };

    const handleBlockInputArrowsMovement = (e: KeyboardEvent) => {
      if (e.key === "ArrowDown" || e.key === "ArrowUp") e.preventDefault();
    };

    const overlay = overlayRef.current;
    const searchInput = searchInputRef.current;

    overlay?.addEventListener("keydown", handleArrowsMovement);
    searchInput?.addEventListener("keydown", handleBlockInputArrowsMovement);

    return () => {
      overlay?.removeEventListener("keydown", handleArrowsMovement);
      searchInput?.removeEventListener(
        "keydown",
        handleBlockInputArrowsMovement,
      );
    };
  }, [isExpanded, options.length, handleScrollToOption]);

  // add keyboard selection for the dropdown
  useEffect(() => {
    if (!isExpanded) return;

    const handleKeyboardSelection = (e: KeyboardEvent) => {
      if (e.key === "Enter" && focusedOptionIndex !== -1) {
        e.preventDefault();
        e.stopPropagation();
        const focusedOption = options.at(focusedOptionIndex);
        if (focusedOption) {
          handleSelect(focusedOption.value, focusedOptionIndex);
          if (!multi) close();
        }
      }
    };

    const overlay = overlayRef.current;

    overlay?.addEventListener("keydown", handleKeyboardSelection);

    return () => {
      overlay?.removeEventListener("keydown", handleKeyboardSelection);
    };
  }, [focusedOptionIndex, isExpanded, multi, options, handleSelect, close]);

  const placeholderText = multi ? placeholder : selectionLabel || placeholder;

  const setTriggerRef = (el: HTMLButtonElement) => {
    triggerRef.current = el;
    if (forwardedTriggerRef) {
      if (typeof forwardedTriggerRef === "function") {
        forwardedTriggerRef(el);
      } else {
        forwardedTriggerRef.current = el;
      }
    }
  };

  return (
    <div
      ref={containerRef}
      className={cn("group relative w-full", containerClassName)}
    >
      {!!label && !hideLabel && (
        <label className="mb-1 ml-2 block text-xs text-black">{label}</label>
      )}
      <button
        ref={setTriggerRef}
        type="button"
        role="combobox"
        disabled={disabled}
        aria-autocomplete="none"
        aria-expanded={isExpanded}
        aria-haspopup="listbox"
        aria-invalid={!!error}
        aria-controls={listId}
        onClick={toggleExpanded}
        className={cn(
          "inline-flex h-12 min-h-12 w-full items-center justify-between gap-4 rounded-lg border-2 border-blue-light bg-blue-light px-2 text-sm leading-none shadow-sm outline-none duration-150 disabled:cursor-not-allowed disabled:opacity-50 group-focus-within:border-primary/30 data-[state='open']:border-primary/30 data-[placeholder]:text-grey-200 aria-invalid:border-error aria-invalid:group-focus-within:border-primary/30",
          buttonClassName,
        )}
      >
        <span
          className="inline-flex w-full items-center gap-2"
          title={placeholderText}
        >
          {/* size is (full width - count circle size - gap) */}
          <span className="line-clamp-1 text-ellipsis text-left leading-5">
            {placeholderText}
          </span>
          {multi && (
            <span
              className={cn(
                "size-5 rounded-circle bg-primary p-0.5 text-xs text-snow duration-100",
                !selected?.length && "opacity-0",
              )}
            >
              {selected?.length || 1}
            </span>
          )}
        </span>
        <TriangleIcon fill="black" className="size-2.5 rotate-180" />
      </button>
      <div
        tabIndex={-1}
        aria-expanded={isExpanded}
        data-placement={overlayPlacement}
        className={cn(
          "pointer-events-none absolute w-full min-w-40 -translate-y-2 overflow-hidden rounded-md bg-snow p-4 pb-0 opacity-0 shadow-2xl duration-150 aria-expanded:pointer-events-auto aria-expanded:translate-y-0 aria-expanded:opacity-100 data-[placement='top']:translate-y-2 data-[placement='top']:aria-expanded:translate-y-0",
          overlayClassName,
        )}
        ref={overlayRef}
        {...overlayProps}
      >
        <div className="relative mb-4">
          <input
            ref={searchInputRef}
            placeholder="Chercher"
            className="h-9 w-full rounded-pill bg-blue-light pl-8"
            onChange={(e) => onSearch?.(e.target.value)}
          />

          <SearchIcon className="absolute left-3 top-1/2 size-3 -translate-y-1/2" />
        </div>

        {isSearching ? (
          <ul className="h-56 space-y-1 overflow-scroll pb-1">
            {Array.from({ length: 6 }).map((_, i) => {
              return (
                <li
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  role="option"
                  className="h-10 w-full animate-pulse rounded-md bg-grey-100"
                />
              );
            })}
          </ul>
        ) : (
          <ComboboxInputOptionsList
            id={listId}
            ref={optionsListRef}
            options={options}
            focusedOptionIndex={focusedOptionIndex}
            handleSetFocusedOptionIndex={(i) => setFocusedOptionIndex(i)}
            handleSelect={handleSelect}
            multi={multi ?? false}
            selected={selected}
          />
        )}

        {onClear !== undefined && (
          <div className="grid place-items-center border-t border-grey-300 p-2">
            <button
              type="button"
              onClick={onClear}
              className="text-sm text-primary"
            >
              Effacer
            </button>
          </div>
        )}
      </div>

      {!!error && <InputError errorMessage={error} />}
    </div>
  );
}

const ComboboxInput = forwardRef(ComboboxInputInner) as {
  <T>(props: Props<T> & { ref?: ForwardedRef<HTMLButtonElement> }): ReactNode;
  displayName: string;
};
ComboboxInput.displayName = "ComboboxInput";

export default ComboboxInput;
