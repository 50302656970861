import {
  type ComponentPropsWithoutRef,
  type PropsWithChildren,
  forwardRef,
} from "react";

import { cn } from "$/lib/utils/functions/misc.functions";

type Props = ComponentPropsWithoutRef<"div"> & {
  isExpanded?: boolean;
  direction?: "x" | "y" | "xy";
  expandedClassName?: string;
};

const Expandable = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (
    { isExpanded, direction = "y", className, expandedClassName, children },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={cn(
          "duration-200",
          className,
          "grid",
          direction.includes("x") && "grid-cols-[0fr]",
          direction.includes("y") && "grid-rows-[0fr]",
          isExpanded && `${expandedClassName} grid-cols-[1fr] grid-rows-[1fr]`,
        )}
      >
        <div className="w-full overflow-hidden">{children}</div>
      </div>
    );
  },
);

Expandable.displayName = "Expandable";

export default Expandable;
