import { type RefObject, useEffect } from "react";

export default function useOutsideClick(
  ref: RefObject<HTMLElement | null> | Array<RefObject<HTMLElement | null>>,
  onOutsideClick: () => void,
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (Array.isArray(ref)) {
        let isOutside = true;
        for (let i = 0; i < ref.length; i++) {
          if (
            ref[i].current &&
            ref[i].current?.contains(event.target as Node)
          ) {
            isOutside = false;
            break;
          }
        }

        if (isOutside) {
          onOutsideClick();
        }
      } else {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          onOutsideClick();
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onOutsideClick]);
}
