export const ModificationTypeEnum = {
  DRIVER_ON_POOL: "DRIVER_ON_POOL",
  VEHICLE_ON_POOL: "VEHICLE_ON_POOL",
  DRIVER_ON_LIVE_LINE: "DRIVER_ON_LIVE_LINE",
  VEHICLE_ON_LIVE_LINE: "VEHICLE_ON_LIVE_LINE",
  POOL_ON_LIVE_LINE: "POOL_ON_LIVE_LINE",
} as const;
export type ModificationTypeEnum =
  (typeof ModificationTypeEnum)[keyof typeof ModificationTypeEnum];

export const ModificationTargetEntityType = {
  POOL: "POOL",
  LINE: "LINE",
} as const;
export type ModificationTargetEntityType =
  (typeof ModificationTargetEntityType)[keyof typeof ModificationTargetEntityType];

export type Modification = {
  id: number;
  createdAt: string;
  updatedAt: string;
  initiatedById: number;
  type: ModificationTypeEnum;
  targetEntityId: number;
  oldRelatedEntityId: number;
  newRelatedEntityId: number;
  effectiveStartDate: string;
  effectiveEndDate: string | null;
  appliedAt: string | null;

  targetEntityType: ModificationTargetEntityType;
  targetEntityName: string;
  oldRelatedEntityName: string;
  newRelatedEntityName: string;
};
