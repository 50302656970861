import { getCNRs } from "$/features/CNR-index/utils/CNR-index.apis";
import {
  type DynamicFormInputsGroup,
  flattenDynamicFormGroupsInputs,
  generateDynamicFormSchema,
} from "$/features/common/dynamic-form";
import type { GetCompaniesListResponse } from "$/features/company/types/company-apis.types";
import { getCompaniesList } from "$/features/company/utils/company.apis";
import { VehicleTypeEnum } from "$/features/vehicles/types/models/vehicle.types";
import { vehicleTypeEnumToLabel } from "$/lib/utils/constants/enum-labels.constants";
import type { CNRIndex } from "$/types/models/CNR-index";

export const deliveryRequestFormInputFieldsGroups = [
  {
    name: "Nom et référence",
    inputs: [
      {
        name: "name",
        label: "Nom de cahier",
        placeholder: "Nom de cahier",
        requiredMessage: "Le nom de la demande est obligatoire",
        type: "text",
      },
      {
        name: "reference",
        label: "Référence",
        placeholder: "Référence",
        requiredMessage: "Une référence est requise",
        type: "text",
      },
    ],
  },
  {
    name: "Connexions",
    inputs: [
      {
        name: "clientId",
        type: "combobox",
        optionsType: "number",
        label: "Client",
        multi: false,
        componentProps: {
          queryOptions: {
            queryFn: async (search: string) =>
              getCompaniesList({ offset: 0, limit: 20 }, { search }),
            queryKey: ["search-clients-list"],
          },
          optionsResolver: (data: GetCompaniesListResponse) => {
            return data.map((item) => ({
              label: item.name,
              value: item.id,
            }));
          },
        },
      },
      {
        name: "cnrIndexId",
        type: "combobox",
        optionsType: "number",
        label: "Index CNR",
        multi: false,
        componentProps: {
          queryOptions: {
            queryFn: async (search: string) => getCNRs({ search, types: [] }),
            queryKey: ["CNRs"],
          },
          optionsResolver: (data: CNRIndex[]) => {
            return data.map((item) => ({
              label: `${item.type} - ${item.index}, ${item.pricePerLitre} €/L`,
              value: item.id,
            }));
          },
        },
      },
      {
        name: "vehicleType",
        type: "select",
        label: "Type de véhicule",
        options: Object.values(VehicleTypeEnum).map((value) => ({
          label: vehicleTypeEnumToLabel[value],
          value,
        })),
      },
    ],
  },
  {
    name: "Dates",
    inputs: [
      {
        name: "startDate",
        label: "Date de démarrage",
        placeholder: "Date de démarrage",
        requiredMessage: "La date de démarrage est requise",
        type: "date",
      },
      {
        name: "endDate",
        label: "Date de fin",
        requiredMessage: "La date de fin est requise",
        placeholder: "Date de fin",
        type: "date",
      },
    ],
  },
] as const satisfies DynamicFormInputsGroup[];

export const deliveryRequestFormInputFieldsSchema = generateDynamicFormSchema(
  flattenDynamicFormGroupsInputs(deliveryRequestFormInputFieldsGroups),
).refine(
  (data) => {
    return data.startDate < data.endDate;
  },
  {
    path: ["startDate"],
    message: "La date de fin doit être après la date de début",
  },
);

export const deliveryRequestsQueryKeys = {
  getDeliveryRequests: ["delivery-requests"],
  details: ["delivery-request-details"],
  liveLines: ["delivery-request-live-lines"],
  simulationItems: ["get-delivery-request-simulation-items"],
};
