import { type ComponentProps, type PropsWithChildren, forwardRef } from "react";

import { cn } from "$/lib/utils/functions/misc.functions";

import BricksLoader from "../../loaders/BricksLoader";

type Props = ComponentProps<"button"> & {
  isLoading?: boolean;
};

const TableButton = forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
  ({ className, isLoading, disabled, children, ...buttonProps }, ref) => {
    return (
      <button
        ref={ref}
        type="button"
        aria-busy={isLoading}
        className={cn(
          "flex min-h-10 items-center justify-center gap-2 rounded-md bg-blue-light px-4 py-2 text-sm transition-all duration-200 hover:opacity-75 hover:shadow-sm active:opacity-50 disabled:cursor-not-allowed disabled:opacity-50 aria-busy:cursor-wait",
          className,
        )}
        disabled={disabled || isLoading}
        {...buttonProps}
      >
        {children} {isLoading && <BricksLoader />}
      </button>
    );
  },
);

TableButton.displayName = "TableButton";

export default TableButton;
