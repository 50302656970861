import type { ComponentProps } from "react";
import { Toaster } from "sonner";

import { useMediaQuery } from "$/lib/hooks/useMediaQuery";

export default function Toast() {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const toasterProps: ComponentProps<typeof Toaster> = {
    position: isMobile ? "top-center" : "bottom-right",
    closeButton: !isMobile,
    richColors: true,
  };

  return <Toaster {...toasterProps} />;
}
