import type { Primitive } from "react-hook-form";

import type { Option } from "$/types/util.types";

type AutocompleteContextMulti<T> =
  | {
      multi: false;
      selected: T | null;
    }
  | {
      multi: true;
      selected: T[] | null;
    };

export type AutocompleteContextType<TValues extends Primitive> = {
  search: string;
  options: Option<TValues>[];
  isAutocompleteLoading: boolean;
  handleSelection: (option: TValues, index: number) => void;
  handleSearch: (value: string) => void;
  handleClear: () => void;
} & AutocompleteContextMulti<TValues>;

export const AutocompleteSearchDelayMode = {
  NONE: "none",
  DEBOUNCED: "debounced",
  THROTTLED: "throttled",
} as const;
export type AutocompleteSearchDelayMode =
  (typeof AutocompleteSearchDelayMode)[keyof typeof AutocompleteSearchDelayMode];
