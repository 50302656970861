import type { ComponentProps } from "react";

import Table from "$/lib/components/ui/table/Table";
import type { KeyValueObject } from "$/types/util.types";

import { useEnhancedTable } from "./EnhancedTableProvider";

type Props<T> = Pick<
  ComponentProps<typeof Table<T>>,
  | "columns"
  | "selectable"
  | "paginatable"
  | "noDataComponent"
  | "errorComponent"
  | "onRowClick"
  | "headerClassName"
  | "tableClassName"
>;

export default function EnhancedTable<TSelectedData>(
  tableProps: Props<TSelectedData>,
) {
  const {
    queryResult: { data, isPending, isError },
    selectedRows,
    paginationHandlers,
    dataSelector,
    onRowSelectionChange,
    // we don't care about the type of the data here as we are only concerned
    // with the selected data which will be passed to the Table component
  } = useEnhancedTable<unknown, TSelectedData, KeyValueObject>();

  return (
    // @ts-expect-error typescript generics are not compatible with data passed
    // from both props and context. If you want to debug or change the props in
    // the future you can use the commented code below which is type-safe
    <Table<TSelectedData>
      {...tableProps}
      data={data ? dataSelector(data) : undefined}
      isPendingData={isPending}
      isErroredData={isError}
      selectedRows={selectedRows}
      onRowSelectionChange={onRowSelectionChange}
      pagination={paginationHandlers}
    />
  );
}

// * reference code for debugging
// const dataProps = {
//   data,
//   isPendingData: isPending,
//   isErroredData: isError,
// } as TableDataProps<TData>;

// const selectionsProps = tableProps.selectable
//   ? {
//       selectable: true as const,
//       selectedRows,
//       onRowSelectionChange,
//     }
//   : {
//       selectable: false as const,
//     };

// const paginationProps = tableProps.paginatable
//   ? {
//       paginatable: true as const,
//       pagination: paginationHandlers,
//     }
//   : {
//       paginatable: false as const,
//       pagination: undefined,
//     };

// <Table<TData>
//   {...tableProps}
//   {...dataProps}
//   {...selectionsProps}
//   {...paginationProps}
// />;
