import type { ComponentProps } from "react";

export default function ChevronLineIcon(svgProps: ComponentProps<"svg">) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      aria-label="ChevronLine"
      {...svgProps}
    >
      <path
        d="M6.1875 15.1875C6.04364 15.1875 5.89964 15.1325 5.78981 15.0227C5.57002 14.8029 5.57002 14.447 5.78981 14.2273L11.0171 9.00001L5.78981 3.7727C5.57002 3.5529 5.57002 3.19698 5.78981 2.97733C6.00961 2.75767 6.36553 2.75753 6.58519 2.97733L12.2102 8.60233C12.43 8.82212 12.43 9.17804 12.2102 9.3977L6.58519 15.0227C6.47536 15.1325 6.33136 15.1875 6.1875 15.1875Z"
        fill="black"
      />
      <rect x="14" y="3" width="1.5" height="12" rx="0.75" fill="black" />
    </svg>
  );
}
