import type { ComponentProps } from "react";

export default function RedCancelIcon(svgProps: ComponentProps<"svg">) {
  return (
    <svg
      height="14px"
      width="14px"
      version="1.1"
      id="Layer_1"
      viewBox="0 0 496 496"
      aria-label="Red Cancel"
      {...svgProps}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          fill="#EE2E11"
          d="M496,248c0,136.8-111.2,248-248,248S0,384.8,0,248S111.2,0,248,0S496,111.2,496,248z"
        ></path>{" "}
        <path
          fill="#EE2E11"
          d="M248,0c136.8,0,248,111.2,248,248S384.8,496,248,496"
        ></path>{" "}
        <path
          fill="#EE2E11"
          d="M72.8,72.8c96.8-96.8,253.6-96.8,350.4,0s96.8,253.6,0,350.4"
        ></path>{" "}
        <g>
          {" "}
          <path
            fill="white"
            d="M321.6,333.6c-3.2,0-5.6-0.8-8-3.2l-148-148c-4.8-4.8-4.8-12,0-16.8s12-4.8,16.8,0l148,148 c4.8,4.8,4.8,12,0,16.8C328,332.8,324.8,333.6,321.6,333.6z"
          ></path>{" "}
          <path
            fill="white"
            d="M174.4,333.6c-3.2,0-5.6-0.8-8-3.2c-4.8-4.8-4.8-12,0-16.8l148-148c4.8-4.8,12-4.8,16.8,0 s4.8,12,0,16.8l-148.8,148C180,332.8,176.8,333.6,174.4,333.6z"
          ></path>{" "}
        </g>{" "}
      </g>
    </svg>
  );
}
