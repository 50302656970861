import { Outlet, createRootRoute } from "@tanstack/react-router";

import Toast from "$/lib/components/ui/Toast";
import AuthProvider from "$/lib/providers/AuthProvider";
import { HeaderProvider } from "$/lib/providers/HeaderProvider";
import InternalRouterProvider from "$/lib/providers/InternalRouterProvider";
import QueryClientProvider from "$/lib/providers/QueryClientProvider";

export const Route = createRootRoute({
  component: () => (
    <InternalRouterProvider>
      <QueryClientProvider>
        <HeaderProvider>
          <AuthProvider>
            <Outlet />
            <Toast />
          </AuthProvider>
        </HeaderProvider>
      </QueryClientProvider>
    </InternalRouterProvider>
  ),
});
