import * as Dialog from "@radix-ui/react-dialog";
import { type PropsWithChildren, useRef } from "react";

import { useRootDialog } from "$/lib/providers/DialogProvider";

export default function ModalPortal({ children }: PropsWithChildren) {
  const rootDialog = useRootDialog();
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <Dialog.Portal>
      <Dialog.Overlay className="fixed inset-0 z-backdrop bg-black/60 backdrop-blur-sm data-[state=closed]:animate-fade-out data-[state=open]:animate-fade-in" />

      {/* second overlay to add scroll ability to overflow content without being related to first overlay style and animations */}
      <Dialog.Overlay
        onClick={(e) => {
          if (contentRef.current) {
            const { top, right, bottom, left } =
              contentRef.current.getBoundingClientRect();
            const { clientX, clientY } = e;

            if (
              clientX < left ||
              clientX > right ||
              clientY < top ||
              clientY > bottom
            ) {
              rootDialog.handleSetOpen(false);
            }
          }
        }}
        // the closing animation class is here only to let radix know to not unmount the component, otherwise we would lose the hide content animation
        className="fixed inset-0 z-backdrop grid place-items-center overflow-y-auto data-[state=closed]:animate-fade-out"
      >
        <Dialog.Content
          ref={contentRef}
          onEscapeKeyDown={() => rootDialog.handleSetOpen(false)}
          onClick={(e) => e.stopPropagation()}
          className="z-modal my-16 max-w-[90vh] data-[state=closed]:animate-hide-modal-content data-[state=open]:animate-show-modal-content md:px-4"
        >
          {children}
        </Dialog.Content>
      </Dialog.Overlay>
    </Dialog.Portal>
  );
}
