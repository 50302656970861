import authBg from "$/assets/auth-bg.svg";

import styles from "./page-loader.module.scss";

export default function PageLoader() {
  return (
    <div
      className="flex h-full items-center justify-center bg-snow bg-cover"
      style={{
        backgroundImage: `url(${authBg})`,
      }}
    >
      <div className={styles.loader} />
    </div>
  );
}
