import { type ComponentProps, useId } from "react";

export default function VehicleIcon(svgProps: ComponentProps<"svg">) {
  const pathId = useId();
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      aria-label="Vehicle"
      {...svgProps}
    >
      <g clipPath={`url(#${pathId})`}>
        <path d="M17.8973 6.56696C17.8093 6.45958 17.6779 6.39689 17.5401 6.39689H15.7882C15.3559 5.25663 14.7927 4.1394 14.1525 3.71768C11.9379 2.26077 6.06368 2.26077 3.84906 3.71768C3.20841 4.13986 2.64749 5.25847 2.21286 6.39689H0.460979C0.321788 6.39689 0.190892 6.45958 0.103782 6.56696C0.0162106 6.67389 -0.0188178 6.81539 0.00975802 6.95136L0.269245 8.20869C0.313491 8.42301 0.50246 8.57603 0.720466 8.57603H1.23944C0.738441 9.154 0.495547 9.88176 0.492321 10.61C0.489094 11.5097 0.830621 12.3227 1.45514 12.8979C1.46205 12.9039 1.46897 12.908 1.47542 12.914V14.6733C1.47542 15.0544 1.78515 15.3646 2.16677 15.3646H3.78269C4.16431 15.3646 4.47404 15.0544 4.47404 14.6733V13.9663H13.5266V14.6733C13.5266 15.0544 13.8363 15.3646 14.2179 15.3646H15.8338C16.2146 15.3646 16.5252 15.0544 16.5252 14.6733V12.9486C17.188 12.3416 17.5046 11.5064 17.5088 10.6727C17.5115 9.91955 17.2576 9.16506 16.7271 8.57511H17.2806C17.4995 8.57511 17.6885 8.42209 17.7318 8.20731L17.9918 6.95043C18.0185 6.81539 17.9849 6.67482 17.8973 6.56696ZM4.60816 4.87223C6.37341 3.7103 11.6272 3.7103 13.3915 4.87223C13.7344 5.09715 14.1755 6.00651 14.5797 7.12005H3.42088C3.82417 6.00697 4.26525 5.09808 4.60816 4.87223ZM2.54056 10.7833C2.54056 10.032 3.14987 9.42362 3.90068 9.42362C4.65241 9.42362 5.26079 10.032 5.26079 10.7833C5.26079 11.5345 4.65241 12.1434 3.90068 12.1434C3.14987 12.1434 2.54056 11.5345 2.54056 10.7833ZM14.1179 12.1434C13.3671 12.1434 12.7578 11.5345 12.7578 10.7833C12.7578 10.032 13.3671 9.42362 14.1179 9.42362C14.8696 9.42362 15.478 10.032 15.478 10.7833C15.478 11.5345 14.8687 12.1434 14.1179 12.1434Z" />
      </g>
      <defs>
        h
        <clipPath id={pathId}>
          <rect width="18.0005" height="18" />
        </clipPath>
      </defs>
    </svg>
  );
}
