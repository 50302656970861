import type { KeyValueObject } from "$/types/util.types";

export function valueOrNothing<T>(condition: boolean, value: T) {
  return condition ? value : undefined;
}

export function isPrimitive(
  value: unknown,
): value is number | string | boolean {
  return value !== Object(value);
}

export function encryptString(input: string, secretKey: string) {
  let encryptedMessage = "";
  for (let i = 0; i < input.length; i++) {
    encryptedMessage += String.fromCharCode(
      input.charCodeAt(i) ^ secretKey.charCodeAt(i % secretKey.length),
    );
  }

  return btoa(encryptedMessage)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

export function decryptString(input: string, secretKey: string) {
  let decodedMessage = input.replace(/-/g, "+").replace(/_/g, "/");
  while (decodedMessage.length % 4) {
    decodedMessage += "=";
  }
  decodedMessage = atob(decodedMessage);

  let decryptedMessage = "";
  for (let i = 0; i < decodedMessage.length; i++) {
    decryptedMessage += String.fromCharCode(
      decodedMessage.charCodeAt(i) ^ secretKey.charCodeAt(i % secretKey.length),
    );
  }
  return decryptedMessage;
}

export function isKeyValObject(value: unknown): value is KeyValueObject {
  return Object.prototype.toString.call(value) === "[object Object]";
}

export function isDate(value: unknown): value is Date {
  return Object.prototype.toString.call(value) === "[object Date]";
}

export function typedObjectKeys<T extends object>(obj: T) {
  return Object.keys(obj) as Array<keyof T>;
}

export function typedObjectEntries<T extends object>(
  obj: T,
): Array<[keyof T, T[keyof T]]> {
  return Object.entries(obj) as Array<[keyof T, T[keyof T]]>;
}

export function throttle<T extends (...params: unknown[]) => void>(
  callback: T,
  delay = 250,
) {
  let shouldWait = false;
  let waitingArgs: Parameters<T> | null = null;
  const timeoutFunc = () => {
    if (waitingArgs == null) {
      shouldWait = false;
    } else {
      callback(...waitingArgs);
      waitingArgs = null;
      setTimeout(timeoutFunc, delay);
    }
  };

  return (...args: Parameters<T>) => {
    if (shouldWait) {
      waitingArgs = args;
      return;
    }

    callback(...args);
    shouldWait = true;
    setTimeout(timeoutFunc, delay);
  };
}

export function slugify(str: string) {
  return String(str)
    .normalize("NFKD")
    .replace(/[\u0300-\u036f]/g, "")
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");
}
