import { useOverlayPosition } from "@react-aria/overlays";
import { type RefObject, useEffect, useMemo, useState } from "react";

import { throttle, valueOrNothing } from "../utils/functions/util.functions";
import useFocusTrap from "./useFoucsTrap";
import useOutsideClick from "./useOutsideClick";

type UseAccessibleDropdownOptions = {
  containerRef: RefObject<HTMLElement>;
  triggerRef: RefObject<HTMLElement>;
  overlayRef: RefObject<HTMLElement>;
};

export default function useAccessibleDropdown({
  containerRef,
  triggerRef,
  overlayRef,
}: UseAccessibleDropdownOptions) {
  const [isExpanded, setIsExpanded] = useState(false);

  const overlayPosition = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef,
    offset: 8,
  });

  useEffect(() => {
    const container = containerRef.current;
    if (!isExpanded || !container) return;

    // re-update position
    overlayPosition.updatePosition();

    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setIsExpanded(false);
        e.preventDefault();
        e.stopPropagation();
        triggerRef.current?.focus();
      }
    };

    container.addEventListener("keydown", handleEscape);

    return () => {
      container.removeEventListener("keydown", handleEscape);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerRef, containerRef, isExpanded, overlayPosition.updatePosition]);

  useEffect(() => {
    if (!isExpanded) return;

    const handleUpdatePosition = throttle(overlayPosition.updatePosition, 75);

    document.addEventListener("scroll", handleUpdatePosition);

    return () => {
      document.removeEventListener("scroll", handleUpdatePosition);
    };
  }, [isExpanded, overlayPosition.updatePosition]);

  useOutsideClick(containerRef, () => {
    if (isExpanded) {
      setIsExpanded(false);
    }
  });

  useFocusTrap(overlayRef, { disabled: !isExpanded });

  const overlayProps = {
    ...overlayPosition.overlayProps,
    inert: valueOrNothing(!isExpanded, ""),
    style: {
      ...overlayPosition.overlayProps.style,
      pointerEvents: valueOrNothing(isExpanded, "auto" as const),
    },
  };

  const expansionCallbacks = useMemo(() => {
    const expand = () => {
      setIsExpanded(true);
    };

    const close = () => {
      setIsExpanded(false);
      triggerRef.current?.focus();
    };

    const toggleExpanded = () => {
      setIsExpanded((prev) => {
        if (prev) {
          triggerRef.current?.focus();
        }

        return !prev;
      });
    };

    return { expand, close, toggleExpanded };
  }, [triggerRef]);

  return {
    triggerRef,
    overlayRef,
    isExpanded,
    overlayProps,
    overlayPlacement: overlayPosition.placement,
    ...expansionCallbacks,
  };
}
