import { format } from "date-fns";

import { DayOfWeekEnum } from "$/features/delivery-request/types/delivery-request.types";
import type { DateInput } from "$/types/util.types";

import { dayOfWeekEnumToLabel } from "../constants/enum-labels.constants";
import { typedObjectKeys } from "./util.functions";

export const TIME_REGEX = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

export function formatDateWithHours(
  date: string | Date,
  hasSeconds = false,
  hasTime = true,
) {
  const dateString = typeof date === "string" ? date : date.toISOString();
  const [datePart, timePart] = dateString.split("T");
  const [year, month, day] = datePart.split("-");

  let formattedDate = `${day}/${month}/${year}`;

  if (hasTime && timePart) {
    const [time] = timePart.split("Z");
    const [hour, minute] = time.split(":");
    formattedDate += ` ${hour}:${minute}`;

    if (hasSeconds) {
      const second = time.split(":")[2] || "00";
      formattedDate += `:${second}`;
    }
  }

  return formattedDate;
}

export function abbreviateDays(days: DayOfWeekEnum[]) {
  return days.map((day) => dayOfWeekEnumToLabel[day].slice(0, 3)).join(", ");
}

// TODO: use deep keys
export function formatObjectDateFields<T extends Record<string, unknown>>(
  dataObject: T,
  fields: {
    date?: (keyof T)[];
    dateTime?: (keyof T)[];
    time?: (keyof T)[];
  },
) {
  const formattedObject = { ...dataObject };
  if (fields.date) {
    for (const date of fields.date) {
      formattedObject[date as keyof T] = format(
        dataObject[date as keyof T] as unknown as Date,
        "yyyy-MM-dd",
      ) as T[keyof T];
    }
  }

  if (fields.dateTime) {
    for (const dateTime of fields.dateTime) {
      formattedObject[dateTime as keyof T] = format(
        dataObject[dateTime as keyof T] as unknown as Date,
        "yyyy-MM-dd'T'HH:mm",
      ) as T[keyof T];
    }
  }

  if (fields.time) {
    for (const time of fields.time) {
      formattedObject[time as keyof T] = format(
        dataObject[time as keyof T] as unknown as Date,
        "HH:mm",
      ) as T[keyof T];
    }
  }

  return formattedObject;
}

export async function getWeekDays(filters?: { search?: string }) {
  return typedObjectKeys(DayOfWeekEnum)
    .filter((type) => {
      if (filters?.search) {
        return dayOfWeekEnumToLabel[type]
          .toLowerCase()
          .includes(filters.search.toLowerCase());
      }
      return true;
    })
    .map((type) => ({
      value: type,
      label: dayOfWeekEnumToLabel[type],
    }));
}

export function toISOStringWithoutTimezoneOffset(dateInput: DateInput) {
  const date = new Date(dateInput);
  return new Date(
    date.getTime() - date.getTimezoneOffset() * 60000,
  ).toISOString();
}

export function fromISOStringWithoutTimezoneOffset(dateInput: DateInput) {
  const date = new Date(dateInput);
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}

export function getUTCDateWithoutTimezone(input: DateInput): string {
  const date = new Date(input);

  if (isNaN(date.getTime())) {
    throw new Error("Invalid date");
  }

  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function getDateWithoutTimezoneOffset(
  date: DateInput,
  behind?: boolean,
) {
  const parsedDate = new Date(date);
  const sign = behind ? -1 : 1;
  return new Date(
    parsedDate.getTime() + parsedDate.getTimezoneOffset() * sign * 60000,
  );
}

export function convertDateToString(inputDate: Date, withHours?: boolean) {
  if (withHours) return inputDate.toLocaleString();

  return inputDate.toDateString();
}

export function getTimeFromDate(date: Date | string) {
  if (typeof date === "string") {
    const [_, timePart] = date.split("T");
    const time = timePart.split("Z")[0];
    const [hours, minutes] = time.split(":");

    return `${hours}:${minutes}`;
  }

  return date.toTimeString().slice(0, 5);
}

export function formatUTCToDateTime(dateString: string) {
  return dateString.replace(/.\d{2,3}Z$/, "");
}

export function joinDateAndTime(date: string, time: string) {
  return `${date}T${time}:00Z`;
}

export function timeStringToDate(time: string) {
  if (!TIME_REGEX.test(time)) {
    throw new Error("Invalide time format");
  }

  return `1970-01-01T${time}:00.000Z`;
}
